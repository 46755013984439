import { observable, action, computed, toJS } from "mobx";
import uuid from "uuid/v4";

import User from "../models/User";

class Users {
  @observable rawUsers = [];
  @observable rawUser = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawUser = new User({}, rootStore);
  }

  @computed
  get users() {
    return this.rawUsers;
  }

  @computed
  get user() {
    return this.rawUser;
  }

  @computed
  get requestData() {
    const usersToCreate = this.rawUsers.filter(({ isNew }) => isNew);
    const usersToUpdate = this.rawUsers.filter(({ isUpdated }) => isUpdated);

    return {
      toCreate: {
        users: toJS(usersToCreate.map((u) => u.createData)),
      },
      toUpdate: {
        users: toJS(usersToUpdate.map((u) => u.updateData)),
      },
    };
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawUsers = [];
    this.rawUser = new User({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @action.bound resetUser() {
    this.rawUser = new User({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @action.bound async findAll() {
    const { method, url } = this.rootStore.urls.users.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}?companyId=${this.rootStore.companiesStore.company.id}`,
    });

    if (response) {
      this.rawUsers = response.data.map((u) => new User(u, this.rootStore));
    }

    return response;
  }

  @action findById = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.users.getById;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
    });

    if (response) {
      this.rawUser = new User(response.data, this.rootStore);
    }

    return response;
  };

  @action save = (isEdit) => async () => {
    const body = isEdit
      ? {
          firstName: this.rawUser.firstName,
          lastName: this.rawUser.lastName,
        }
      : { ...this.rawUser };

    if (!isEdit) {
      const errors = await this.rootStore.validator.validateUsers(
        body,
        false,
        this.rawUsers
      );
      if (this.rootStore.hasValidationErrors(errors)) return;

      return this.rawUsers.push(
        new User({ ...body, id: uuid(), isNew: true }, this.rootStore)
      );
    }

    const errors = await this.rootStore.validator.validateUsers(
      body,
      isEdit,
      this.rawUsers
    );
    if (this.rootStore.hasValidationErrors(errors)) return;

    return (this.rawUsers = this.rawUsers.map((u) =>
      u.id === this.rawUser.id
        ? new User({ ...u, ...body, isUpdated: true }, this.rootStore)
        : u
    ));
  };

  @action deleteById = (id) => async () => {
    const deleteUserFromUsers = () => this.rawUsers.filter((u) => u.id !== id);
    const user = this.rawUsers.find((u) => u.id === id);

    if (user.isNew) {
      this.rawUsers = deleteUserFromUsers();
      return true;
    }

    const { method, url } = this.rootStore.urls.users.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
    });

    if (response) {
      this.rawUsers = deleteUserFromUsers();
    }

    return response;
  };
}

export default Users;
