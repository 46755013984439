import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { useModal, useModalWithValidation } from "../hooks";
import Modal from "../Modal";
import Div from "../Div";
import P from "../P";
import { Switch } from "../inputs";
import AppContextForm from "../forms/AppContext";

const AppContextSelect = ({ companiesStore, authStore, themeStore }) => {
  const { findAll } = companiesStore;
  const { hasContext, rootStore } = authStore;
  const [
    isActivateContextShown,
    setActivateContextShown,
    onActivateContextOkAction,
    onActivateContextCancelAction
  ] = useModalWithValidation(rootStore);
  const [
    isDeactivateContextShown,
    setDeactivateContextShown,
    onDeactivateContextOkAction,
    onDeactivateContextCancelAction
  ] = useModal();
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const onSwitchClick = async () => {
    if (hasContext) {
      return setDeactivateContextShown()();
    }

    await findAll();
    setActivateContextShown()();
  };

  return (
    <Div left={25}>
      <Switch
        label="Company Administrator"
        checked={hasContext}
        onClick={onSwitchClick}
        labelSize={16}
      />
      <Modal
        title="Switch to Company Administrator?"
        showModal={!hasContext && isActivateContextShown}
        onCancel={onActivateContextCancelAction()}
        onConfirm={onActivateContextOkAction(
          authStore.switchContext(selectedCompanyId)
        )}
        confirmButtonTitle="Switch to Company Admin"
      >
        <Div width="100%" direction="column" align="center">
          <P bottom={10} size={16}>
            Please, choose a Company from the list below.
          </P>
          <AppContextForm
            selectedCompanyId={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompanyId}
          />
        </Div>
      </Modal>
      <Modal
        title="Switch to Super Administrator?"
        showModal={hasContext && isDeactivateContextShown}
        onCancel={onDeactivateContextOkAction()}
        onConfirm={onDeactivateContextCancelAction(authStore.switchContext())}
        confirmButtonTitle="Switch to Super Admin"
      >
        <Div
          height="70px"
          paddingLeft="15px"
          paddingRight="15px"
          direction="column"
          justify="center"
        >
          <P textAlign="center" color={themeStore.theme.accentColor} size={16} weight="600">
            By switching Company Admin view off you will return<br />
            to the Super Admin mode.
          </P>
        </Div>
      </Modal>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    authStore: rootStore.authStore,
    themeStore: rootStore.themeStore
  })),
  observer
)(AppContextSelect);
