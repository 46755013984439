import { useState, useEffect } from "react";
import { get } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

// todo: add preloader
export default (data = []) => {
  const [sortedData, setSortedData] = useState(data);
  const [key, setKey] = useState("");
  const [sortCondition, setSortCondition] = useState("");

  useDeepCompareEffect(() => {
    _onSort(data, key, sortCondition);
  }, [data]);

  useEffect(() => {
    _onSort(data, key, sortCondition);
  }, [sortCondition, key]);

  const onSort = (dataKey, condition) => {
    setKey(dataKey);
    setSortCondition(condition);
  };

  const _onSort = (dataToSort, dataKey, condition) => {
    if (!dataKey || !condition) {
      return setSortedData([...dataToSort]);
    }

    let result;

    const sort = (data, cb) => data.slice().sort(cb);

    switch (condition) {
      case "newest": {
        result = sort(
          data,
          (a, b) => new Date(get(b, dataKey)) - new Date(get(a, dataKey))
        );
        break;
      }
      case "oldest": {
        result = sort(
          data,
          (a, b) => new Date(get(a, dataKey)) - new Date(get(b, dataKey))
        );
        break;
      }
      case "highest": {
        result = sort(data, (a, b) => get(b, dataKey) - get(a, dataKey));
        break;
      }
      case "lowest": {
        result = sort(data, (a, b) => get(a, dataKey) - get(b, dataKey));
        break;
      }
      case "asc": {
        result = sort(data, (a, b) => {
          const aValue = get(a, dataKey);
          const bValue = get(b, dataKey);

          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        });
        break;
      }
      case "desc": {
        result = sort(data, (a, b) => {
          const aValue = get(a, dataKey);
          const bValue = get(b, dataKey);

          if (aValue > bValue) return -1;
          if (aValue < bValue) return 1;
          return 0;
        });
        break;
      }
      case "selected": {
        result = sort(data, (a, b) => get(b, dataKey) - get(a, dataKey));
        break;
      }
      case "unselected": {
        result = data;
        break;
      }
      default:
        result = sortedData;
    }

    setSortedData([...result]);
  };

  return [sortedData, onSort];
};
