import styled from "styled-components";
import { Icon } from "antd";

export const StyledIcon = styled(Icon)`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 24px !important;
  background: ${({ icon }) => `transparent url(${icon}) 50% no-repeat`};
  background-size: contain;
`;
