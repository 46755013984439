import styled from "styled-components";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  padding: 25px 25px 20px 0;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.accentColor};
`;

export const StyledHeaderTitle = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.mainDarkColor};
`;

export const StyledInnerContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
