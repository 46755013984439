import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import Icon from "@ant-design/icons-react";
import { RightCircleOutline, LeftCircleOutline } from "@ant-design/icons";

import { compose } from "utils/hoc";
import geo from "domain/services/geo";
import Div from "components/Div";
import P from "components/Div";
import { Button } from "components/actions";
import { SlidingTable, IconEffect } from "./Styled";
import { contextContinents, contextCountries } from "./settings";

const EditLotDiversionZone = ({
  lot: { interceptDiversion, configDiversion: config },
  width, height,
  themeStore: { theme }
}) => {
  const renderContinentRow = (_, item) => (
    <Div justify="space-between">
      <P>{item.name}</P>
      <Button
        onClick={() => viewCountries(item.code)}
        title="Drill-down to countries."
      >
        <IconEffect style={{ marginRight: "7px" }}>
          <Icon type={RightCircleOutline} />
        </IconEffect>
      </Button>
    </Div>
  );

  const renderCountriesTitle = (name) => (
    <Div justify="space-between" width="215px">
      <P>{name}</P>
      <Button
        onClick={() => viewContinents()}
        title="Return to continents."
      >
        <IconEffect>
          <Icon type={LeftCircleOutline} />
        </IconEffect>
      </Button>
    </Div>
  );

  const [context, setContext] = useState(contextContinents(renderContinentRow));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const tableRef = useRef(null);

  const viewAnimate = (dir) => {
    const { current: { props: { className } } } = tableRef;
    const node = document.getElementsByClassName(className)[0];
    node.classList.remove("slideRight", "slideLeft");
    node.classList.add(dir === "left" ? "slideLeft" : "slideRight");
  }

  const viewContinents = () => {
    setContext(contextContinents(renderContinentRow));
    viewAnimate("right");
  }

  const viewCountries = (continent) => {
    const name = geo.continentsIndex[continent];
    const title = () => renderCountriesTitle(name);
    setContext(contextCountries(title, continent));
    viewAnimate("left");
  }

  useEffect(() => {
    const refresh = () => {
      const selections = context.selections(context.data, config.zones);
      setSelectedRowKeys(selections);
    }

    refresh();
  }, [context, config.zones]);

  const update = zones => {
    config.zones = zones;
  }

  const countriesToContinent = () => {
    const filtered = config.zones.filter(
      ({ continentCode }) => continentCode !== context.zone.continentCode
    );
    update([...filtered, { ...context.zone }]);
  }

  const continentToCountries = (selections) => {
    const filtered = config.zones.filter(
      ({ continentCode }) => continentCode !== context.zone.continentCode
    );
    const zones = selections.map(
      ({ code }) => ({ ...context.zone, [context.scope]: code })
    );
    update([...filtered, ...zones]);
  }

  const onSelect = (item, selected, selections) => {
    const { code } = item;
    if (selected) {
      if (
        context.scope === "countryCode" &&
        selections.length === context.data.length
      ) {
        countriesToContinent();
      } else {
        update([...config.zones, { ...context.zone, [context.scope]: code }]);
      }
    } else {
      if (
        context.scope === "countryCode" &&
        selections.length + 1 === context.data.length
      ) {
        continentToCountries(selections);
      } else {
        update(context.filtered(config.zones, code));
      }
    }
  }

  const onSelectAll = (allSelected, _, newSelections) => {
    if (context.scope === "countryCode") {
      if (allSelected) {
        countriesToContinent();
      } else {
        update(config.zones.filter(
          ({ continentCode }) => continentCode !== context.zone.continentCode
        ));
      }
    } else {
      if (allSelected) {
        const zones = newSelections.map(
          ({ code }) => ({ ...context.zone, [context.scope]: code })
        );
        update([...config.zones, ...zones]);
      } else {
        update([]);
      }
    }
  }

  const getCheckboxProps = () => ({ disabled: !interceptDiversion });

  return (
    <Div styles={{
      width, height,
      borderRadius: theme.borderRadius,
      border: `1px solid #e8e8e8`,
      overflow: "hidden",
      background: "#fafafa80"
    }}>
      <SlidingTable
        ref={tableRef}
        style={{ width: width - 2, height: height - 2 }}  // -2 will inset container within border
        scroll={{ y: height - 46 }}                       // -46 for header
        bordered={false}
        size="middle"
        columns={context.columns}
        dataSource={context.data}
        rowKey={context.rowKey}
        rowSelection={{ onSelect, onSelectAll, getCheckboxProps, selectedRowKeys }}
        pagination={false}
        theme={theme}
      />
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(EditLotDiversionZone);
