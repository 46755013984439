import styled from "styled-components";

export const StyledIframeWrapper = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  border: 1px solid ${({ theme }) => theme.secondaryLightColor};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const StyledIframe = styled.iframe`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
