import React from "react";

import P from "../../P";
import Icon from "../../Icon";
import { StyledAccordionHeader } from "./Styled";

const AccordionHeader = ({ look, children }) => (
  <StyledAccordionHeader look={look}>
    <Icon icon="circleStep" width="30px" height="30px" />
    <P size={16} weight="600" left={26}>
      {children}
    </P>
  </StyledAccordionHeader>
);

export default AccordionHeader;
