import * as localForage from "localforage";

class LocalStorage {
  async save(key, value) {
    return await localForage.setItem(key, value);
  }

  async get(key, defaultValue = null) {
    const value = await localForage.getItem(key);
    return value === null ? defaultValue : value;
  }

  async remove(key) {
    return await localForage.removeItem(key);
  }
}

export default new LocalStorage();
