import styled from "styled-components";
import { Checkbox } from "antd";

export const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  display: flex !important;
  padding: 5px 0 12px !important;
  align-items: center !important;
  font-size: 14px !important;
  white-space: nowrap;
  color: ${({ theme }) => theme.mainDarkColor} !important;

  .ant-checkbox-inner {
    width: ${({ large }) => (large ? "27px" : "18px")} !important;
    height: ${({ large }) => (large ? "27px" : "18px")} !important;
    border: 1px solid ${({ theme }) => theme.accentColor} !important;
    border-radius: 5px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: transparent !important;

    &::after {
      top: 48% !important;
      left: ${({ large }) => (large ? "36%" : "24%")} !important;
      border-color: ${({ theme }) => theme.accentColor} !important;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: none !important;
    }
  }

  .ant-checkbox + span {
    position: relative;
    top: -1px;
    display: flex;
    width: 100%;
    padding-right: 0 !important;
    align-items: center;
    font-size: ${({ large }) => (large ? "14px" : "12px")} !important;
  }
`;
