import React from "react";

import Div from "components/Div";
import P from "components/P";

const Summary = ({ rows = [], leftColumnWidth = "105px" }) => (
  <Div direction="column">
    {rows.map(r => (
      <Div key={r.title} bottom={3}>
        <P right={10} width={leftColumnWidth}>
          {r.title}
        </P>
        <P weight="600" width="100%">{r.value}</P>
      </Div>
    ))}
  </Div>
);

export default Summary;
