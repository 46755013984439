import React from "react";

import { StyledDiv } from "./Styled";

const Div = ({
  display,
  width,
  height,
  justify,
  align,
  alignSelf,
  grow,
  direction,
  top,
  right,
  bottom,
  left,
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  wrap,
  gap,
  styles,
  children,
}) => (
  <StyledDiv
    display={display}
    width={width}
    height={height}
    direction={direction}
    justify={justify}
    align={align}
    alignSelf={alignSelf}
    grow={grow}
    top={top}
    right={right}
    bottom={bottom}
    left={left}
    padding={padding}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    paddingRight={paddingRight}
    paddingLeft={paddingLeft}
    gap={gap}
    wrap={wrap}
    style={styles}
  >
    {children}
  </StyledDiv>
);

export default Div;
