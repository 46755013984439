import React from "react";

import { ColumnTitle } from "components/TableVirtualized";

export const tableColumns = [
  {
    title: (onSort) => (
      <ColumnTitle
        title="Lot"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "lot.name",
            condition: "asc",
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "lot.name",
            condition: "desc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "name",
    dataIndex: "name",
    width: 2,
  },
  {
    title: <ColumnTitle title="Description" />,
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 4,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        title="Tags"
        sortingOptions={[
          {
            key: "desc",
            title: "From highest",
            dataKey: "lot.tags",
            condition: "desc",
          },
          {
            key: "asc",
            title: "From lowest",
            dataKey: "lot.tags",
            condition: "asc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tags",
    dataIndex: "tags",
    align: "center",
    width: 1,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        sortingOptions={[
          {
            key: "selected",
            title: "Selected",
            dataKey: "selected",
            condition: "selected",
          },
          {
            key: "unselected",
            title: "Unselected",
            dataKey: "selected",
            condition: "unselected",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "selected",
    dataIndex: "selected",
    width: 0.6,
  },
];

export const searchOptions = [
  { key: "name", title: "Lot name", iconByType: "iconDefaultSearch" },
  {
    key: "description",
    title: "Lot description",
    iconByType: "iconDescriptionSearch",
  },
];
