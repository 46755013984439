import styled from "styled-components";

export const StyledImg = styled.img`
  position: ${({ position }) => (position ? position : "inherit")};
  margin: ${({ margin }) => (margin ? margin : "0 0 0 0")};
  right: ${({ right }) => (right ? right : 0)};
  transform: ${({ transform }) => (transform ? transform : "none")};
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
`;
