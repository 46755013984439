import React from "react";

import { ColumnTitle } from "components/TableVirtualized";

export const tableColumns = [
  {
    title: (onSort) => (
      <ColumnTitle
        title="Roll"
        sortingOptions={[
          {
            key: "desc",
            title: "The newest",
            dataKey: "roll.createdAt",
            condition: "desc",
          },
          {
            key: "asc",
            title: "The oldest",
            dataKey: "roll.createdAt",
            condition: "asc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "id",
    dataIndex: "id",
    width: 4,
  },
  {
    title: <ColumnTitle title="Description" />,
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 5,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        title="Type"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "roll.tagType",
            condition: "asc",
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "roll.tagType",
            condition: "desc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tagType",
    dataIndex: "tagType",
    align: "center",
    width: 2,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        title="Tags"
        sortingOptions={[
          {
            key: "desc",
            title: "From highest",
            dataKey: "roll.tags",
            condition: "desc",
          },
          {
            key: "asc",
            title: "From lowest",
            dataKey: "roll.tags",
            condition: "asc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tags",
    dataIndex: "tags",
    align: "center",
    width: 2,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        sortingOptions={[
          {
            key: "selected",
            title: "Selected",
            dataKey: "selected",
            condition: "selected",
          },
          {
            key: "unselected",
            title: "Unselected",
            dataKey: "selected",
            condition: "unselected",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "selected",
    dataIndex: "selected",
    width: 1,
  },
];

export const useFilterOptions = {
  dataKey: "tagType",
  items: [
    { key: "nfc", title: "NFC", isSelected: true },
    { key: "qrc", title: "QRC", isSelected: true },
  ],
};

export const searchOptions = [
  { key: "id", title: "Roll id", iconByType: "iconDefaultSearch" },
  {
    key: "description",
    title: "Roll description",
    iconByType: "iconDescriptionSearch",
  },
];
