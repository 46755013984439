import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore, RouterStore } from "mobx-react-router";

import RootStore from "domain/stores/Root";
import App from "application/App";

import "antd/dist/antd.css";
import "assets/styles/index.css";

const routingStore = new RouterStore();
const history = syncHistoryWithStore(createBrowserHistory(), routingStore);
export const rootStore = new RootStore(routingStore);

rootStore
  .initializer()
  .then(() => {
    ReactDOM.render(
      <Provider rootStore={rootStore}>
        <App history={history} />
      </Provider>,
      document.getElementById("root")
    );
  })
  .catch(err => {
    console.error("Application initialization error => ", err);
  });
