import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledIframeWrapper, StyledIframe } from "./Styled";

const IFrame = ({ src, width = 375, height = 810, themeStore }) => {
  const iFrameRef = React.createRef();
  // it's necessary to fix the template's preview display
  const onIFrameLoad = () =>
    iFrameRef.current.contentWindow.postMessage("layout", "*");

  return (
    <StyledIframeWrapper width={width} height={height} theme={themeStore.theme}>
      <StyledIframe
        ref={iFrameRef}
        src={src}
        width={width}
        height={height}
        onLoad={onIFrameLoad}
        theme={themeStore.theme}
      />
    </StyledIframeWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer,
)(IFrame);
