import { observable, action, computed } from "mobx";
import uuid from "uuid/v4";
import { findIndex, sortBy } from "lodash";

import { filesToFormData } from "utils/format";
import Asset from "../models/Asset";
class AssetBundles {
  @observable maxAssetSizeMB = "5";
  @observable maxAssetSizeBytes = 5242880;
  @observable maxAssetQty = 12;
  @observable rawAssetBundleAssets = {
    lot: [],
    tag: [],
    roll: [],
    company: [],
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get assetBundleLot() {
    return this.rawAssetBundleAssets.lot;
  }

  @computed
  get assetBundleTag() {
    return this.rawAssetBundleAssets.tag;
  }

  @computed
  get assetBundleRoll() {
    return this.rawAssetBundleAssets.roll;
  }

  @computed
  get assetBundleCompany() {
    return this.rawAssetBundleAssets.company;
  }

  @action.bound reset(relationEntity) {
    this.rootStore.abortRequest();
    if (relationEntity) {
      this.rawAssetBundleAssets[relationEntity] = []
    }
    this.rootStore.resetValidationErrors();
  }

  @action findAssetsByBundleId = ({ id, relationEntity }) => async () => {
    const { method, url } = this.rootStore.urls.assets.getByBundleId;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {
        assetBundleId: id
      }
    });

    const data = response && response.data;
    if (data) {
      this.rawAssetBundleAssets[relationEntity] = sortBy(data, ["createdAt"]).map(
          r => new Asset({ ...r, status: "done" }, this.rootStore)
      );
    }

    return this.rawAssetBundleAssets[relationEntity];
  };

  @action createBundle = data => async () => {
    const { method, url } = this.rootStore.urls.assetBundles.create;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: data
    });

    return response && response.data;
  };

  async upload(target, name, bundleId) {
    const file = target.files[0];
    const fileName = name || file.name
    const { method, url } = this.rootStore.urls.assets.create;
    const formData = filesToFormData(target, ["file"]);
    formData.append("assetBundleId", bundleId);
    formData.append("name", fileName);
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: formData
    });

    return response && response.data;
  }

  async deleteById(id) {
    const { method, url } = this.rootStore.urls.assets.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    return response;
  }

  @action addAsset = ({ target, relation, name, bundleId, dimension, onBundleCreated }) => async () => {
    const file = target.files[0];
    const errors = await this.rootStore.validator.validateAsset({
      name,
      asset: file,
      dimension,
      maxAssetSize: this.maxAssetSizeBytes,
      qty: this.rawAssetBundleAssets[relation.relationEntity].length,
      maxQty: this.maxAssetQty
    });

    if (this.rootStore.hasValidationErrors(errors)) return {
      validationError: true
    };

    if (!bundleId) {
      const resp = await this.createBundle(relation)();
      bundleId = resp.id;
      if (onBundleCreated && typeof onBundleCreated === 'function') {
        onBundleCreated(relation, bundleId)
      }
    }


    let newFile = new Asset(
      {
        id: uuid(),
        name: name || file.name,
        status: "uploading",
        access: this.rootStore.authStore.isSuperAdmin
          ? "super_admin"
          : "company_admin"
      },
      this.rootStore
    );

    this.rawAssetBundleAssets[relation.relationEntity].push(newFile);

    const response = await this.upload(target, name, bundleId);

    if (response) {
      newFile.assign("id", response.id);
      newFile.assign("status", "done");
      newFile.assign("file", response.file);
      newFile.assign("createdAt", response.createdAt);
      newFile.assign("access", response.access);
    } else {
      newFile.assign("status", "error");
    }

    const index = findIndex(this.rawAssetBundleAssets[relation.relationEntity], asset => {
      return asset.id === newFile.id;
    });

    this.rawAssetBundleAssets[relation.relationEntity].splice(index, 1, newFile);
    return response
  };

  @action deleteAssetById = ({ id, relationEntity }) => async () => {
    const response = await this.deleteById(id);

    if (response) {
      this.rawAssetBundleAssets[relationEntity] = (this.rawAssetBundleAssets[relationEntity] || []).filter(
        asset => asset.id !== id
      );
    }
  };
}

export default AssetBundles;
