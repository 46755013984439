import styled, { css } from "styled-components";

import checkbox from "assets/images/Checkbox.svg";

const selectionCellCss = css`
  box-shadow: 0 2px 5px #0000001a;

  &:after {
    display: block;
    width: 11px;
    height: 16px;
    background: transparent url(${checkbox}) 50% no-repeat;
    content: "";
  }
`;

const commonLabelCss = css`
  display: block;
  font-size: 12px;
  font-weight: 600;
`;

const firstTagCss = css`
  ${selectionCellCss}

  &:before {
    content: "First tag";
    ${commonLabelCss}
  }
`;

const lastTagCss = css`
  ${selectionCellCss}

  &:before {
    content: "Last tag";
    ${commonLabelCss}
  }
`;

export const StyledSelectionCell = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.mainDarkColor};

  ${({ isSelected, isFirstSelected, isLastSelected, theme }) => {
    let style;

    if (isSelected) {
      style = selectionCellCss;
    }

    if (isFirstSelected) {
      style = firstTagCss;
    }

    if (isLastSelected) {
      style = lastTagCss;
    }

    return style;
  }};
`;

export const StyledExperienceTypeCell = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-transform: capitalize;
`;
