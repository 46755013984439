import styled from "styled-components";

export const StyledUploadLabel = styled.label`
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: ${({ labelColor, theme }) => labelColor || theme.accentColor};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const StyledUploadInput = styled.input`
  display: none;
`;
