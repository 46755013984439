import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  width: 100% !important;

  .ant-table table {
    table-layout: fixed;
    border-collapse: collapse;
  }

  .ant-table-thead th {
    background: transparent !important;
    padding: 12px 8px !important;
    border-top: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
  }

  .ant-table-thead th .ant-table-header-column {
    font-weight: bold;
    color: ${({ theme }) => theme.mainDarkColor};
    text-transform: uppercase;
  }

  .ant-table-thead
    .ant-table-selection-column
    .ant-checkbox-indeterminate
    .ant-checkbox-inner::after {
    top: 49% !important;
    left: 46% !important;
    background-color: ${({ theme }) => theme.accentColor};
  }

  .ant-table-tbody tr:hover td {
    background: transparent !important;
  }

  .ant-table-tbody tr:nth-child(2n) {
    background-color: ${({ theme }) => theme.mainLightColor} !important;
  }

  .ant-table-tbody td {
    position: relative;
    color: ${({ theme }) => theme.mainDarkColor};
    padding: 14px 8px !important;
    border-top: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1;

    &:last-child {
      overflow: visible;
      white-space: normal;
    }
  }

  .ant-pagination-item {
    &:hover {
      border-color: ${({ theme }) => theme.accentColor};
    }

    &:hover a {
      color: ${({ theme }) => theme.accentColor};
    }
  }

  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.mainDarkColor} !important;
    border-color: ${({ theme }) => theme.mainDarkColor} !important;

    &:hover {
      border-color: ${({ theme }) => theme.mainDarkColor} !important;
    }

    &:hover a,
    & a {
      color: #fff !important;
    }
  }

  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: ${({ theme }) => theme.accentColor} !important;
    color: ${({ theme }) => theme.accentColor} !important;
  }

  .ant-pagination {
    font-size: 13px !important;
  }

  .ant-table-placeholder {
    border-bottom: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
  }
`;
