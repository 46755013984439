import React from "react";
import { inject, observer } from "mobx-react";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import { Text, Select } from "components/inputs";
import P from "components/P";
import Div from "components/Div";
import Form from "components/Form";
import EditLotDiversionZone from "../EditLotDiversionZone";
import GeoMap from "../../miscellany/GeoMap";

const EditLotDiversion = ({ lot, rootStore: { validationErrors } }) => (
  <Form direction="column" justify="space-between">
    <Div bottom={25}>
      <Select
        label="Lot diversion"
        list={[
          { name: "Enabled", value: "true", icon: "active" },
          { name: "Disabled", value: "false", icon: "inactive" }
        ]}
        inputWidth={"205px"}
        valueKey="value"
        iconKey="icon"
        selectedValue={String(lot.interceptDiversion)}
        onChange={v => (lot.interceptDiversion = v === "true")}
      />
    </Div>
    <Div direction="row" bottom={25}>
      <Div right={40}>
        <Select
          label={
            <>
              <strong>Alerts</strong>
              <br />
              Receive email alert of warnings?
            </>
          }
          list={[
            { name: "Email", value: "true" },
            { name: "Silent", value: "false" }
          ]}
          inputWidth={"205px"}
          valueKey="value"
          selectedValue={String(lot.configDiversion.notifyEmail)}
          disabled={!lot.interceptDiversion}
          onChange={v => (lot.configDiversion.notifyEmail = v === "true")}
        />
      </Div>
      <Div width="40ch">
        <Text
          label={
            <>
              <br />
              Email address of recipient.
            </>
          }
          value={lot.configDiversion.notifyEmailAddress}
          placeholder="username@email.com"
          name="configDiversion.notifyEmailAddress"
          disabled={!lot.interceptDiversion || !lot.configDiversion.notifyEmail}
          error={validationErrors.email}
          errorMessage="Email not valid or not unique"
          onChange={lot.change}
          required
        />
      </Div>
    </Div>
    <Div direction="column" bottom={25}>
      <Select
        label={
          <>
            <strong>Strategy</strong>
            <br />
            Diversion may be designated as <em>Inclusion</em> to define where a
            tag may be activated;
            <br />
            or <em>Exclusion</em> to define where a tag may not be activated.
          </>
        }
        list={[
          { name: "Inclusion", value: "inclusion" },
          { name: "Exclusion", value: "exclusion" }
        ]}
        inputWidth={"205px"}
        valueKey="value"
        name="configDiversion.strategy"
        selectedValue={lot.configDiversion.strategy}
        disabled={!lot.interceptDiversion}
        onChange={v => (lot.configDiversion.strategy = v)}
      />
    </Div>
    <P bottom={12}>
      <strong>Zones</strong>
      <br />
      Diversion zones specify the geographic bounds where tags of this lot may
      (or may not) be activated.
    </P>
    <Div bottom={25} direction="row">
      <Div right={12}>
        <EditLotDiversionZone width={300} height={414} lot={lot} />
      </Div>
      <Div>
        <GeoMap
          width={isMobile ? 710 : 900}
          height={414}
          config={lot.configDiversion}
          mapFeaturesOff={[]}
        />
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(EditLotDiversion);
