import React from "react";
import { observer } from "mobx-react";
import { isEmpty } from "lodash";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import { Text } from "components/inputs";
import { Button } from "components/actions";
import Form from "components/Form";
import { SelectImage } from "components/inputs";
import ErrorMessage from "components/ErrorMessage";

const MenuExperienceStep = ({
  buttonName,
  errors,
  experience,
  index,
  url,
  stepId,
  buttonIcon,
  onChangeButtonName,
  onChangeUrl,
  onIconChange
}) => {
  const hasIconOrNameError =
    (!!errors &&
      errors.length === experience.steps.length &&
      errors[index].buttonName) ||
    (!!errors && errors.length && errors[index].buttonIcon);

  const stepIcons = isEmpty(experience.template)
    ? [{ src: "", name: "none" }]
    : [{ src: "", name: "none" }, ...experience.template.config.button.icons];

  return (
    <Form align="middle" bottom={25}>
      <Div direction="column" right={15} width="10vw">
        {index === 0 && (
          <P uppercase weight="bold" bottom={10}>
            Icon
          </P>
        )}
        <Div direction="column" justify="center">
          <SelectImage
            list={stepIcons}
            valueKey="src"
            optionName="name"
            selectedValue={buttonIcon}
            showSearch
            lowercase
            onChange={onIconChange}
          />
        </Div>
      </Div>
      <Div direction="column" right={15} grow={1}>
        {index === 0 && (
          <P uppercase weight="bold" bottom={10}>
            Label
          </P>
        )}
        <Text value={buttonName} onChange={onChangeButtonName} />
      </Div>
      {hasIconOrNameError && (
        <ErrorMessage text="Name or icon must be specified. Name must be more than 1 and less than 50 symbols." />
      )}
      <Div direction="column" right={15} grow={3}>
        {index === 0 && (
          <P uppercase weight="bold" bottom={10}>
            URL
          </P>
        )}
        <Div align="center">
          <Div right={15} width="100%">
            <Text
              value={url}
              error={
                !!errors &&
                errors.length === experience.steps.length &&
                errors[index].url
              }
              errorMessage="Must be a valid URL"
              onChange={onChangeUrl}
            />
          </Div>
          <Button type="bin" onClick={experience.deleteStep(stepId)} />
        </Div>
      </Div>
    </Form>
  );
};

export default compose(observer)(MenuExperienceStep);
