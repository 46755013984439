import React from "react";

import { dateFormatter } from "utils/format";
import Icon from "components/Icon";
import LotList from "features/lots/LotList";
import TagType from "features/tags/TagType";

export const rollListTableColumns = [
  {
    title: "Company",
    key: "company",
    dataIndex: "company.name"
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center"
  },
  {
    title: "Assigned lots",
    key: "assigned-lots",
    align: "center",
    render: (_, row) => <LotList lots={row.lots} />
  },
  {
    title: "Coded",
    key: "coded",
    align: "center",
    width: 80,
    render: (_, row) => <Icon icon={row?.tags ? "active" : "inactive"} />
  },
  {
    title: "Tags",
    key: "total-tags",
    align: "center",
    width: 100,
    render: (_, row) => {
      const count = row.tags;
      if (count) {
        return count;
      } else if (!count && row.metadata?.tagsCount) {
        return row.metadata?.tagsCount;
      } else {
        return "N/A";
      }
    }
  },
  {
    title: "Type",
    key: "tag-type",
    align: "center",
    render: (_, row) => (
      <TagType tagModel={row.tagModel} tagType={row.tagType} />
    ),
    width: 50
  },
  {
    title: "Created",
    key: "created-at",
    dataIndex: "createdAt",
    align: "center",
    render: (_, row) => dateFormatter(row.createdAt),
    width: "22ch"
  }
];

export const rollInfoOptions = [
  {
    key: "id",
    title: "Roll ID:"
  },
  {
    key: "tags",
    title: "Total tags:"
  },
  {
    key: "tagType",
    title: "Tag type:"
  },
  {
    key: "tagModel",
    title: "Tag model:"
  },
  {
    key: "orderId",
    title: "Order ID:"
  },
  {
    key: "createdAt",
    title: "Created:"
  }
];

export const useFilterOptions = {
  dataKey: "tags",
  items: [
    {
      key: "withTags",
      title: "Rolls with encoded tags",
      isSelected: true,
      icon: "active"
    },
    {
      key: "withoutTags",
      title: "Rolls with non-encoded tags",
      isSelected: true,
      icon: "inactive"
    }
  ]
};
