import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import Div from "components/Div";
import Modal from "components/Modal";
import { Button } from "components/actions";
import { useModal, useFilter, useSorting } from "components/hooks";
import { Tabs, Tab } from "components/Tabs";
import P from "components/P";
import { PreviewSwitch } from "components/inputs";
import Tip from "components/Tip";
import ExperiencesTable, { useRowSelect } from "components/TableVirtualized";
import withCancelModal from "components/withCancelModal";
import { Header } from "components/Content";
import Constraint from "components/Constraint";
import QRCode from "features/miscellany/previews/QRCode";
import Thumbnail from "features/miscellany/previews/Thumbnail";
import IFrame from "features/miscellany/previews/IFrame";
import EditLotForm from "features/lots/forms/EditLot";
import SetExperienceForm from "features/lots/forms/SetExperience";
import EditLotDiversionForm from "features/lots/forms/EditLotDiversion";
import EditLotCounterfeitForm from "features/lots/forms/EditLotCounterfeit";
import EditLotNotificationsForm from "features/lots/forms/EditLotNotifications";
import AssetBundle from "features/miscellany/AssetBundle";
import Metadata from "features/miscellany/Metadata";
import EditLotTags from "features/lots/EditLotTags";
import LotAdvanced from "features/lots/LotAdvanced";
import {
  experienceTableColumns,
  useFilterOptions,
  searchOptions,
  lotStatuses
} from "./settings";

const Edit = ({
  rootStore: { lotsStore, experiencesStore },
  match,
  constraintGetter,
  onLeavePage
}) => {
  const { experiences, findAll: findAllExperiences } = experiencesStore;
  const { save, lot } = lotsStore;
  const { lotId } = match.params;
  const isEditRoute = !!lotId;
  const innerTitle = isEditRoute ? "Edit Lot" : "Create Lot";

  const [previewMode, setPreviewMode] = useState("iframe");
  const [preview, setPreview] = useState("");
  const [qrcLink, setQrcLink] = useState("");
  const [type, setType] = useState("");
  const [activeTab, setActiveTab] = useState("#details");

  const {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedItem,
    setSelected,
    resetAllSelected
  } = useRowSelect(experiences, "single");

  const [filteredData, filterOptions, setFilterOptions] = useFilter({
    data: dataWithSelectedRows,
    options: useFilterOptions.items,
    dataKey: useFilterOptions.dataKey
  });

  const [sortedData, onSort] = useSorting(filteredData);

  const [isModalShown, setModalShown, onModalOk, onModalCancel] = useModal();

  const [
    isWarningModalShown,
    setWarningModalShown,
    onWarningModalOk,
    onWarningModalCancel
  ] = useModal();

  useEffect(() => {
    const onMount = async () => {
      if (lotId) await lotsStore.findById({ id: lotId })();
      await findAllExperiences();
    };

    onMount();
    return lotsStore.reset;
  }, [lotId, lotsStore, findAllExperiences]);

  useEffect(() => {
    const onMount = async () => {
      const { experience } = lot;

      if (isModalShown && experience.type) {
        setSelected([experience.id]);
        setType(experience.type.name);
        await loadPreview(experience);
      }
    };

    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalShown]);

  const loadPreview = async ({ id, selected, type }) => {
    const currentType = !selected ? type.name : "";
    const currentExperience = experiencesStore.experiences.find(
      e => e.id === id
    );

    switch (currentType) {
      case "menu": {
        const url = `${process.env.REACT_APP_MENU_PREVIEW_HOST}/${currentExperience.id}`;
        setPreview(url);
        setQrcLink(url);
        break;
      }
      case "sequence": {
        const { image, url } = await currentExperience.steps[0].showPreview();
        setPreview(image);
        setQrcLink(url);
        break;
      }
      default: {
        setPreview("");
        setQrcLink("");
      }
    }
  };

  const onSelect = (experience, setIndex) => async () => {
    onRowSelect(experience, setIndex)();
    setType(experience.type.name);
    await loadPreview(experience);
  };

  const onModalClose = () => {
    resetAllSelected();
    setPreview("");
    setQrcLink("");
    onModalCancel()();
  };

  const onAssignExperience = () => {
    lot.assignExperience(getSelectedItem());
    onModalCancel()();
  };

  const handleTabChange = key => {
    if (key !== "details" && !lot.id) {
      return setWarningModalShown()();
    }

    setActiveTab(key);
  };

  const numRows = isMobile ? 7 : 10;
  const previewMaxHeight = isMobile ? "472px" : "735px";

  return (
    <Constraint
      constraintGetter={constraintGetter}
      capacityGetter="lots.capacity"
      amount={lotsStore.lots.length}
    >
      <Header
        title={innerTitle}
        backButton
        backButtonFn={onLeavePage(lot.isDirty)}
        backButtonLink="/lots"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button type="regularFlat" onClick={onLeavePage(lot.isDirty)}>
              Cancel
            </Button>
            <Button type="regular" onClick={save(isEditRoute)}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tab tab="Details" key="#details">
          <Div bottom={25}>
            <EditLotForm
              onLeavePage={onLeavePage}
              lot={lot}
              lotStatuses={lotStatuses}
              showExperienceModal={setModalShown()}
              deleteExperience={lot.removeExperience}
            />
          </Div>
          <Modal
            title="Save lot?"
            showModal={isWarningModalShown}
            confirmButtonTitle="Ok"
            onCancel={onWarningModalCancel()}
            onConfirm={onWarningModalOk()}
          >
            <P size={18}>Please save details before editing further.</P>
          </Modal>
          <Modal
            title="Set Experience"
            showModal={isModalShown}
            onCancel={onModalClose}
            onConfirm={onModalOk(onAssignExperience)}
            confirmButtonTitle="Save"
            confirmButtonProps={{ disabled: !hasSelected() }}
            width="1200px"
          >
            <Div justify="space-between" width="100%">
              <Div width="60%" direction="column">
                <Div justify="space-between">
                  <SetExperienceForm
                    data={sortedData}
                    filterOptions={filterOptions}
                    searchPopupOptions={searchOptions}
                    onFilterChange={setFilterOptions}
                    onSearchSelect={onSelect}
                  />
                  <PreviewSwitch
                    types={["iframe", "qrc"]}
                    currentType={previewMode}
                    disabled={!hasSelected()}
                    onClick={setPreviewMode}
                  />
                </Div>
                <Div>
                  <ExperiencesTable
                    columns={experienceTableColumns}
                    data={sortedData}
                    onSort={onSort}
                    rowCount={numRows}
                    selectedIndex={selectedIndex}
                    onRowSelect={onSelect}
                  />
                </Div>
              </Div>
              <Div
                width="40%"
                align="center"
                direction="column"
                styles={{
                  height: previewMaxHeight,
                  overflowY: "hidden"
                }}
              >
                {!hasSelected() && (
                  <Div
                    height="100%"
                    direction="column"
                    justify="center"
                    align="center"
                  >
                    <P size={16}>Choose experience to preview</P>
                  </Div>
                )}
                {previewMode === "qrc" && hasSelected() && (
                  <>
                    <QRCode src={qrcLink} />
                    <Tip
                      text="Scan QRC with your camera to preview experience"
                      top={20}
                    />
                  </>
                )}
                {previewMode === "iframe" && hasSelected() && (
                  <Div align="center">
                    {(type === "sequence" || !type) && (
                      <Thumbnail src={preview} width={338} height={730} />
                    )}
                    {type === "menu" && (
                      <IFrame src={preview} width={338} height={730} />
                    )}
                  </Div>
                )}
              </Div>
            </Div>
          </Modal>

          <Div direction="column" bottom={25}>
            <Div bottom={25}>
              <AssetBundle
                disabled={!lot.id}
                companyId={lot.company && lot.company.id}
                assetBundles={lot.assetBundles}
                relationEntity="lot"
                relationId={lot.id}
                parentText="lot"
              />
            </Div>
            <Div>
              <Metadata parentStore={lot} parentName="lot" />
            </Div>
          </Div>
        </Tab>
        <Tab tab="Tags" key="#tags" constraintGetter="lots.editTags">
          <EditLotTags lot={lot} isEditRoute={isEditRoute} />
        </Tab>
        <Tab tab="Advanced" key="#advanced" constraintGetter="lots.advanced">
          <LotAdvanced lot={lot} isEditRoute={isEditRoute} />
        </Tab>
        <Tab tab="Diversion" key="#diversion" constraintGetter="lots.diversion">
          <EditLotDiversionForm lot={lot} />
        </Tab>
        <Tab tab="Anti-counterfeiting" key="#counterfeit">
          <EditLotCounterfeitForm lot={lot} />
        </Tab>
        {!isFeatureEnabled("hideLotNotifiers") && (
          <Tab tab="Agents" key="#agents">
            <EditLotNotificationsForm lot={lot} />
          </Tab>
        )}
      </Tabs>
    </Constraint>
  );
};

export default compose(
  withCancelModal("/lots"),
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Edit);
