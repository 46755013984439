import React from "react";

import Div from "components/Div";
import Form from "components/Form";
import { SearchWithSelect } from "components/inputs";
import Filter from "components/Filter";

const SetExperience = ({
  data,
  filterOptions,
  searchPopupOptions,
  onFilterChange,
  onSearchSelect,
}) => (
  <Form width="55%" align="center" left={5} bottom={28}>
    <Div left={8} right={24}>
      <Filter options={filterOptions} onChange={onFilterChange} />
    </Div>
    <SearchWithSelect
      data={data}
      dataKeys={searchPopupOptions.map(({ key }) => key)}
      resultsPopupOptions={searchPopupOptions}
      placeholder="Search by name"
      onSelect={onSearchSelect}
    />
  </Form>
);

export default SetExperience;
