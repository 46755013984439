import React from "react";
import { Icon } from "antd";

import Div from "components/Div";

const AssetName = ({ status, name, color, errorColor = "#FF5858" }) => {
  const isUploaded = status === "done";
  const isError = status === "error";

  return (
    <Div styles={{ color: isError && errorColor }}>
      <Div right={6} height="16px" top={4}>
        <Icon
          type={isUploaded || isError ? "paper-clip" : "loading"}
          style={{ color: isError ? errorColor : color }}
        />
      </Div>
      {name}
    </Div>
  );
};

export default AssetName;
