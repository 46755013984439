import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: inherit;
  background-color: ${({ theme }) => theme.mainLightColor};
`;

export const StyledContent = styled.div`
  display: flex;
  min-width: ${({ isMobile }) => (isMobile ? "auto" : "1300px")};
  margin: 0 16px 20px;
  flex-direction: column;
  background: #ffffff;
`;

export const StyledInnerContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
