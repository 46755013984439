export const widthChainLink = 160;
export const heightChainLink = 168;

export const timelineFill = (light, mid, dark) =>
  `linear-gradient(to right, ${light} 0%, ${mid} 30%, ${dark} 50%, ${mid} 70%, ${light} 100%)`;

export const segmentFill = (i, n, light, mid, dark) => {
  if (n === 1) return `linear-gradient(90deg, ${light} 0%, ${mid} 50%, ${light} 100%)`; 
  switch (i) {
    case 0:
      return `linear-gradient(90deg, ${light} 15%, ${mid} 50%, ${dark} 100%)`;
    case n - 1:
      return `linear-gradient(90deg, ${dark} 0%, ${mid} 50%, ${light} 85%)`;
    default:
      return dark;
  }
};
