import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledTabs } from "./Styled";

const RawTabs = ({ activeKey, children, themeStore, onChange }) => (
  <StyledTabs
    activeKey={activeKey}
    animated={false}
    theme={themeStore.theme}
    onChange={onChange}
  >
    {children}
  </StyledTabs>
);

export const Tabs = compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(RawTabs);

export { default as Tab } from "./Tab";
