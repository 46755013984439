import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Popover from "components/Popover";
import { Button, Link } from "components/actions";
import Icon from "components/Icon";
import Modal from "components/Modal";
import { useModal } from "components/hooks";
import Div from "components/Div";
import CompanyLogo from "./CompanyLogo";
import LogoWithTagline from "features/miscellany/LogoWithTagline";

const AboutModal = ({
  rootStore: {
    authStore,
    themeStore: { theme }
  }
}) => {
  const [isAboutModalShown, setAboutModalShown, onAboutModalOk] = useModal();

  return (
    <>
      <Modal showModal={isAboutModalShown} onCross={onAboutModalOk()}>
        <Div direction="column" align="center" justify="space-between">
          <Div top={60} direction="column" align="center">
            <LogoWithTagline
              logoTheme="dark"
              poweredByTop={10}
              poweredByBottom={20}
              poweredByLogoWidth={124}
              poweredByLogoHeight={22}
              poweredByTextSize={12}
              poweredByLineHeight={15}
              sloganTop={10}
              sloganBottom={40}
              sloganTextSize={19}
              productLogoWidth={350}
            />
          </Div>
          {theme.aboutModalContent.length ? (
            <Div direction="column" justify="center" top={40} bottom={52}>
              {theme.aboutModalContent.map(item => (
                <Div bottom={8} key={item.text} justify="center">
                  <Link to={item.url} type="blackFlat" external>
                    {item.text}
                  </Link>
                </Div>
              ))}
            </Div>
          ) : null}
          <Div>
            <CompanyLogo />
          </Div>
        </Div>
      </Modal>
      <Popover
        button={
          <Button>
            <Icon icon="profile" width={24} height={24} />
          </Button>
        }
        placement="bottom"
      >
        <Button type="popoverRegular" onClick={setAboutModalShown()}>
          About
        </Button>
        <Link type="popoverRegular" to="/profile">
          Profile
        </Link>
        <Button type="popoverRegular" onClick={authStore.logout}>
          Logout
        </Button>
      </Popover>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(AboutModal);
