import React, {useState, memo, useEffect} from "react";
import { inject, observer } from "mobx-react";
import Icon from "@ant-design/icons-react";
import { ReloadOutline, CompassOutline } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Div from "components/Div";
import Table from "components/Table";
import CustomIcon from "components/Icon";
import Popover from "components/Popover";
import { StyledTable } from "components/TableFormList/Styled";
import Timeline from "components/Timeline";
import GeoMap from "features/miscellany/GeoMap";
import { defaultView } from "./settings";
import {useModal} from "../../../components/hooks";
import Modal from "../../../components/Modal";
import Metadata from "../../miscellany/Metadata";
import AssetBundle from "../../miscellany/AssetBundle";
import Tag from "../../../domain/models/Tag";
import P from "../../../components/P";

const tagColumns = [
  {
    title: "Key",
    key: "key",
    dataIndex: "key",
    width: 120
  },
  {
    title: "Value",
    key: "value",
    dataIndex: "value",
    width: 120
  },
  {
    title: "Result",
    key: "result",
    dataIndex: "result",
    width: 140,
    render: (val) => {
      if (val === 'success') {
        return <CustomIcon icon="active" />
      }
      if (val === 'fail') {
        return <CustomIcon icon="inactive" />
      }
      if (val === 'rule disabled') {
        return (
          <Div align="center">
            rule disabled
            <Popover.InfoPopover placement="right">
              <P>
                Rule disabled in settings
              </P>
            </Popover.InfoPopover>
          </Div>

        )
      }
      if (val === 'rule skipped') {
        return (
          <Div align="center">
            rule skipped
            <Popover.InfoPopover placement="right">
              <P>
                Rule was not checked, as tag was by other rule
              </P>
            </Popover.InfoPopover>
          </Div>

        )
      }
      if (val === 'disabled') {
        return (
          <Div align="center">
            N/A
            <Popover.InfoPopover placement="right">
              <P>
                Tag does not have this parameter
              </P>
            </Popover.InfoPopover>
          </Div>

        )
      }
    }
  },
]

const Dashboard = ({
  mapConfig = {
    strategy: '',
    zones: []
  },
  detailCard,
  detailListView = "table",
  columns,
  items,
  onRefreshData,
  pagination,
  rootStore
}) => {
  const [activeActivation, setActiveActivation] = useState(null);
  const [config, setConfig] = useState({...mapConfig});
  const [scrollToColumn, setScrollToColumn] = useState(0);
  const [view, setView] = useState(defaultView);
  const { save, findById } = rootStore.tagsStore;
  const [tag, setTag] = useState({});
  const [isMetadataModalShown, setMetadataModalShown, onMetadataModalOk, onMetadataModalCancel] = useModal();
  const [isAttachmentsModalShown, setAttachmentsModalShown, onAttachmentsModalOk] = useModal();

  useEffect(() => {
    setConfig(mapConfig)
  }, [mapConfig]);
 const onSetCurrentTag = t => () => setTag(t);
  const centerMap = () => setView({ ...defaultView });
  const itemShow = item => {
    if (item?.event?.configDiversion?.zones) {
      setConfig({...item?.event?.configDiversion})
    }
    setView({ center: [item.lat, item.lon], id: item.id, zoom: 14 })
  };
  const onScroll = async ({scrollLeft, clientWidth, scrollWidth}) => {
    if ((scrollLeft + clientWidth === scrollWidth) && items.length < pagination.total && !rootStore.pending) {
      setScrollToColumn(pagination.current * pagination.pageSize)
      await pagination.onChange(pagination.current + 1)
    }
  }

  const tableRenderActions = item => (
    <>
      <Button type="popoverRegular" onClick={() => itemShow(item)}>
        Locate on Map
      </Button>
      <Button
        type="popoverRegular"
        onClick={async () => {
          const tag = await findById({
            id: item.tagId
          })
          setMetadataModalShown(onSetCurrentTag(new Tag({ ...tag, metadata: tag.metadata || {} }, rootStore)))()
        }}
      >
          Properties
      </Button>
      <Button
        type="popoverRegular"
        onClick={async () => {
          const tag = await findById({
            id: item.tagId
          })
          setAttachmentsModalShown(onSetCurrentTag(new Tag({ ...tag, metadata: tag.metadata || {} }, rootStore)))()
        }}
      >
        Attachments
      </Button>
    </>
  );

  const timelineRenderActions = item => {
    const info = item?.event?.currentInformation || {}
    const configCounterfeit = item?.event?.configCounterfeit || {}
    const configDiversion = item?.event?.configDiversion || {}
    const counterfeit = item?.event?.counterfeit
    const diversion = item?.event?.diversion
    const title = 'Tag information'
    const counterfeitField = counterfeit?.details.field;
    const counterfeitMapping = [
      {
        title: 'Authentic',
        field: 'authorization',
        rule: 'matchTagAuthorization',
        current: 'authentic'
      },
      {
        title: 'Manufacture ID',
        field: 'manufacturerId',
        rule: 'matchTagManufacturerID',
        current: 'manufacturerId'
      },
      {
        title: 'Counter',
        field: 'counter',
        rule: 'matchTagCounter',
        current: 'counter'
      },
      {
        title: 'State',
        field: 'state',
        rule: 'matchTagTampered',
        current: 'state'
      }
    ]
    const diversionMapping = [
      {
        title: 'Diversion',
        field: '-',
        rule: 'strategy',
        current: '-'
      },
    ]
    const counterfeitTableData = []
    const diversionTableData = []

   counterfeitMapping.forEach(({title, field, rule, current}) => {
      let result = 'success'
      let value = Object.keys(info).includes(current) ? info[current].toString() : 'N/A';
      const isRuleBreached = counterfeitTableData.find((item) => item.result === 'fail')
      if (!Object.keys(info).includes(current)) {
        result = 'disabled'
      }
      if (counterfeitField === field) {
        result = 'fail'
      }
      if (!configCounterfeit[rule] || !configCounterfeit.enabled) {
        result = 'rule disabled'
      }
      if (isRuleBreached) {
        result = 'rule skipped'
      }

      if (!item?.event) {
        result = 'disabled'
      }

     counterfeitTableData.push({
        key: title,
        value,
        result,
      })
    })

    const diversionPositiveStrategyMapping = {
      inclusion: 'in-included',
      exclusion: 'in-excluded'
    }

    const diversionNegativeStrategyMapping = {
      inclusion: 'in-included',
      exclusion: 'not-in-excluded'
    }

    diversionMapping.forEach(({title, field, rule, current}) => {
      let result = 'success'
      let value = 'N/A'

      if (configDiversion && configDiversion[rule] && configDiversion.enabled) {
        value = diversionPositiveStrategyMapping[configDiversion[rule]]
      }
      if (diversion) {
        value = diversionNegativeStrategyMapping[configDiversion[rule]] || diversion.details.why
        result = 'fail'
      }
      if (!configDiversion.enabled) {
        result = 'rule disabled'
      }

      if (!item?.event) {
        result = 'disabled'
      }

      diversionTableData.push({
        key: title,
        value,
        result,
      })
    })


    return (
      <>
        {counterfeitTableData.length ? <Div direction="column" bottom={12}>
          <Div align="center" bottom={12} styles={{justifyContent: 'center'}}>{title}</Div>
          <StyledTable
            rowClassName={record => !record.enabled && "disabled-row"}
            columns={tagColumns}
            dataSource={counterfeitTableData}
            showHeader={false}
            size="small"
            pagination={false}
          >
          </StyledTable>
        </Div> : <></>}
        {diversionTableData.length ? <Div direction="column" bottom={12} width='100%'>
          <StyledTable
            style={{width: '100%'}}
            rowClassName={record => !record.enabled && "disabled-row"}
            columns={tagColumns}
            dataSource={diversionTableData}
            showHeader={false}
            size="small"
            pagination={false}
          >
          </StyledTable>
        </Div> : <></>}
        <Button type="regular" onClick={() => itemShow(item)}>
          Locate on Map
        </Button>
      </>
    )
  };

  const DetailList = memo(() => {
    switch (detailListView) {
      case "table":
        return <Table columns={columns} data={items} renderActions={tableRenderActions}  paginationConfig={pagination}/>;
      case "timeline":
        return <Timeline data={items} scrollToColumn={scrollToColumn} activeActivation={activeActivation} renderActions={timelineRenderActions} onScroll={onScroll} />;
      default:
        throw new Error("invalid list view");
    }
  });

  return (
    <Div direction="column" width="100%">
      <Modal
        title="Tag properties"
        showModal={isMetadataModalShown}
        onCancel={onMetadataModalCancel()}
        confirmButtonTitle="Save"
        onConfirm={onMetadataModalOk(
          async () => {
            await save(tag)
          }
        )}
        width="auto"
      >
        <Div direction="column" align="center">
          <Metadata parentStore={tag} parentName="tag" />
        </Div>
      </Modal>
      <Modal
        title="Tag attachments"
        showModal={isAttachmentsModalShown}
        onCancel={onAttachmentsModalOk()}
        width="auto"
      >
        <Div direction="column" align="center">
          <AssetBundle
            companyId={tag.company && tag.company.id}
            assetBundles={tag.assetBundles}
            relationEntity="tag"
            relationId={tag.id}
            parentText="tag"
          />
        </Div>
      </Modal>
      <Div width="100%" justify="center" gap={20} bottom={25}>
        <Div direction="column" justify="flex-start" gap={20} top={10}>
          <Button
            title="Refresh data"
            type="regularRound"
            onClick={onRefreshData}
          >
            <Icon type={ReloadOutline} />
          </Button>
          <Button
            title="Center map"
            type="regularRound"
            onClick={() => centerMap()}
          >
            <Icon type={CompassOutline} />
          </Button>
        </Div>
        <Div>
          <GeoMap
            width={isMobile ? 650 : 900}
            setActiveActivation={setActiveActivation}
            height={350}
            config={config}
            mapFeaturesOff={[]}
            points={items}
            zoomControl={false}
            view={view}
          />
        </Div>
        <Div>{detailCard}</Div>
      </Div>
      <Div bottom={25}>
        <DetailList />
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Dashboard);
