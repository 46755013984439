import React from "react";
import { Steps } from "antd";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "components/P";
import { StyledStep, StyledDot } from "./Styled";
import Step from "./Step";
import Actions from "./Actions";

const SequenceSteps = ({ experience, menuExperiences, errors, themeStore }) => {
  return (
    <>
      {experience.steps.length === 0 && (
        <P bottom={25}>
          Press &quot;<strong>+ URL</strong>&quot; or &quot;<strong>+ Menu</strong>&quot; below to begin building your experience.
        </P>
      )}
      <Steps direction="vertical" style={{ position: "relative" }}>
        {experience.steps.map((step, i) => (
          <StyledStep
            key={step.id}
            icon={<StyledDot theme={themeStore.theme} />}
            theme={themeStore.theme}
            description={
              <Step
                step={step}
                error={
                  !!errors &&
                  errors.length === experience.steps.length &&
                  errors[i]
                }
                menuExperiences={menuExperiences}
                onStepDelete={experience.deleteStep(step.id)}
              />
            }
          />
        ))}
        <StyledStep
          theme={themeStore.theme}
          icon={<StyledDot theme={themeStore.theme} />}
          description={<Actions experience={experience} errors={errors} />}
        />
      </Steps>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(SequenceSteps);
