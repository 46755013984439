import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Icon from "../../Icon";
import Div from "../../Div";
import { StyledUploadLabel, StyledUploadInput } from "./Styled";

const Upload = ({
  label,
  labelColor,
  darkIcon,
  name,
  themeStore,
  onUpload,
  disabled,
}) => {
  return (
    <Div align="center">
      {!darkIcon ? (
        <Icon icon="uploadIconLight" width="20px" height="14px" />
      ) : null}
      {darkIcon ? (
        <Icon icon="uploadIconDark" width="20px" height="14px" />
      ) : null}
      <StyledUploadLabel
        id="file-upload"
        labelColor={labelColor}
        theme={themeStore.theme}
        disabled={disabled}
      >
        <StyledUploadInput
          htmlFor="file-upload"
          type="file"
          name={name}
          onChange={onUpload}
        />
        {label}
      </StyledUploadLabel>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer,
)(Upload);
