import React from "react";
import { observer } from "mobx-react";

import { Select, Text } from "components/inputs";
import Form from "components/Form";

const SequenceExperienceStep = ({ step, menuExperiences, error }) => (
  <Form grow={1} right={10}>
    {!step.isMenuLink ? (
      <Text
        value={step.url}
        error={error}
        errorMessage="Must be a valid URL"
        onChange={step.changeUrl}
      />
    ) : (
      <Select
        list={menuExperiences}
        selectedValue={step.experienceId}
        showSearch
        error={error}
        errorMessage="Select experience"
        onChange={step.changeExperience}
      />
    )}
  </Form>
);

export default observer(SequenceExperienceStep);
