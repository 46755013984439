import { observable, action, computed } from "mobx";

import Template from "../models/Template";

class Templates {
  @observable rawTemplates = [];
  @observable rawTemplate = {};
  @observable cloneName = "";
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawTemplate = new Template({}, rootStore);
  }

  @computed
  get templates() {
    return this.rawTemplates;
  }

  @computed
  get default() {
    return this.rawTemplates.find(({ isDefault }) => isDefault) || {};
  }

  @computed
  get template() {
    return this.rawTemplate;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawTemplates = [];
    this.rawTemplate = new Template({}, this.rootStore);
    this.cloneName = "";
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value,
    };
  }

  @action.bound changeCloneName(event) {
    this.cloneName = event.target.value;
  }

  @action.bound async findAll() {
    const { method, url } = this.rootStore.urls.templates.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: this.params,
    });

    if (response) {
      this.rawTemplates = response.data.map(
        t => new Template(t, this.rootStore),
      );
    }

    return response;
  }

  @action findById = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.templates.getById;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
    });

    if (response) {
      this.rawTemplate = new Template(response.data, this.rootStore);
    }

    return response;
  };

  @action save = isEdit => async () => {
    const { method, url } = isEdit
      ? this.rootStore.urls.templates.update
      : this.rootStore.urls.templates.create;
    const template = isEdit
      ? this.rawTemplate.updateData
      : this.rawTemplate.createData;

    return await this.saveRequest(method, url, template);
  };

  @action.bound async clone() {
    const { method, url } = this.rootStore.urls.templates.create;
    const template = { ...this.rawTemplate.createData, name: this.cloneName };

    return await this.saveRequest(method, url, template, true);
  }

  @action.bound async saveRequest(method, url, body, isClone = false) {
    const errors = this.rootStore.validator.validateTemplate(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body,
    });

    if (response && isClone) {
      await this.findAll();
    }

    if (response && !isClone) {
      this.rootStore.routingStore.push("/templates");
    }

    return response;
  }

  @action deleteById = id => async () => {
    const { method, url } = this.rootStore.urls.templates.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
    });

    if (response) {
      this.rawTemplates = this.rawTemplates.filter(t => t.id !== id);
    }

    return response;
  };
}

export default Templates;
