import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button, Link } from "components/actions";
import Div from "components/Div";
import Form from "components/Form";
import { authForm } from "./settings";
import {
  StyledEmailPrefixIcon,
  StyledPostfixIcon,
  StyledWarningText,
  StyledInput
} from "./Styled";

const ForgotPassword = ({ authStore, rootStore, themeStore }) => {
  useEffect(() => {
    authStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = e => e.key === "Enter" && authStore.forgotPassword();

  return (
    <Form direction="column" align="center" width="20%">
      <Div width="100%" bottom={50} top={20}>
        <StyledEmailPrefixIcon
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        />
        <StyledInput
          name="email"
          value={authStore.email}
          placeholder={authForm.emailPlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.email ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        >
          {authForm.emailErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={50}>
        <Button type="auth" onClick={authStore.forgotPassword}>
          Send
        </Button>
      </Div>
      <Div width="100%" justify="center">
        <Link type="authLink" to="/login">
          Login
        </Link>
      </Div>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(ForgotPassword);
