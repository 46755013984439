import React from "react";
import { Popover } from "antd";
import styled from "styled-components";
import { Grid } from "react-virtualized";

import P from "components/P";
import Div from "components/Div";
import {
  segmentFill,
  timelineFill,
  widthChainLink,
  heightChainLink
} from "./settings";

export const Container = ({ n, children, theme }) => (
  <Div justify={n <= 8 ? "center" : "none"} width="100%">
    <Div
      styles={{
        width: `${n * widthChainLink + 80}px`,
        padding: "0 40px",
        background: timelineFill("#fff", "#fcfcfc", theme.mainLighterColor)
      }}
    >
      {children}
    </Div>
  </Div>
);

export const Chain = ({ children }) => (
  <Div
    direction="column"
    styles={{ width: `${widthChainLink}px`, height: `${heightChainLink}px` }}
  >
    {children}
  </Div>
);

export const Segment = ({ i, n }) => (
  <Div
    styles={{
      height: "3px",
      width: "100%",
      background: segmentFill(i, n, "#fff", "#ccc", "#bbb")
    }}
  ></Div>
);

// export const Point = ({ i, n, renderActions, theme, counterfeited, diverted, isActive }) => {
//   const size = i === 0 || i === n - 1 ? 18 : 23;
//   let backgroundColor = i === 0 || i === n - 1 ? theme.secondaryDarkColor : "#fff"
//   let activeColor = theme.accentColor
//   let activeWidth = size + 6
//   let activeHeight = size + 6
//   if (counterfeited || diverted) {
//     backgroundColor = '#ff9b9b'
//     activeColor = '#ff9b9b'
//   }
//   if (!isActive) {
//     activeWidth = size
//     activeHeight = size
//     activeColor = 'transparent'
//   }
//
//   activeWidth = size
//   activeHeight = size
//   return (
//     <Popover content={renderActions()}>
//       <div  style={{
//         position: "absolute",
//         top: `calc(50% - ${activeWidth * 0.5}px)`,
//         left: `calc(50% - ${activeWidth * 0.5}px)`,
//         width: `${activeWidth}px`,
//         height: `${activeHeight}px`,
//         border: `3px solid ${activeColor}`,
//         borderRadius: "50%",
//         backgroundColor
//       }}>
//         <div
//           style={{
//             position: "absolute",
//             top: `calc(50% - ${size * 0.5}px)`,
//             left: `calc(50% - ${size * 0.5}px)`,
//             width: `${size}px`,
//             height: `${size}px`,
//             border: `3px solid ${theme.secondaryDarkColor}`,
//             borderRadius: "50%",
//             backgroundColor
//           }}
//         ></div>
//         <div
//           style={{
//             width: '100%',
//             height: isActive ? '4px' : '0',
//             bottom: '-10px',
//             position: 'absolute',
//             backgroundColor: activeColor,
//           }}
//         ></div>
//       </div>
//     </Popover>
//   );
// };

export const Point = ({ i, n, renderActions, theme, counterfeited, diverted, isDiversionEnabled, isCounterfeitEnabled, isActive }) => {
  let size = 23;
  let backgroundColor = '#a3ee65'
  if (counterfeited || diverted) {
    backgroundColor = '#ff9b9b'
  }

  if (!isDiversionEnabled && !isCounterfeitEnabled) {
    backgroundColor = '#fff'
  }

  return (
    <Popover content={renderActions()}>
      <div  style={{
        position: "absolute",
        top: `calc(50% - ${size * 0.5}px)`,
        left: `calc(50% - ${size * 0.5}px)`,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: "50%",
        backgroundColor
      }}>
        <div
          style={{
            position: "absolute",
            top: `calc(50% - ${size * 0.5}px)`,
            left: `calc(50% - ${size * 0.5}px)`,
            width: `${size}px`,
            height: `${size}px`,
            border: `3px solid ${theme.secondaryDarkColor}`,
            borderRadius: "50%",
            backgroundColor
          }}
        ></div>
        <div
          style={{
            width: '100%',
            height: isActive ? '5px' : '0',
            bottom: '-10px',
            position: 'absolute',
            backgroundColor: theme.secondaryDarkColor,
          }}
        ></div>
      </div>
    </Popover>
  );
};

export const Panel = ({ children }) => (
  <Div justify="center" align="center" styles={{ height: "50%" }}>
    {children}
  </Div>
);

export const Label = ({ title, theme }) => (
  <P size={12} weight="600" color={theme.secondaryDarkerColor}>
    {title}
  </P>
);

export const StyledGrid = styled(Grid)`
  .ReactVirtualized__Grid__innerScrollContainer {
    margin: auto;
  }
`;
