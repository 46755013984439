import { useState } from "react";

export default (filters, paginationReset) => {
  const [filterOptions, setFilterOptions] = useState(filters)

  const resetFilter = () => {
    setFilterOptions(filters)
  }
  const onFilter = (e) => {
    const { target } = e;
    const newState = filterOptions.map(item => {
      if (item.key === target.name) {
        return { ...item, isSelected: !item.isSelected };
      }

      return item;
    });
    paginationReset()
    setFilterOptions(newState);
  }
  const getFilters = () => {
    return filterOptions.filter((item) => {
      return item.isSelected
    }).map((item) => {
      return {
        key: 'roll.tagType',
        value: item.key
      }
    }).reduce((acc, curVal) => {
      const existingFilter = acc.find((item) => {
        return item.key === curVal.key
      })
      if (!existingFilter) {
        acc.push({
          key: curVal.key,
          value: [curVal.value]
        })
      } else {
        existingFilter.value = [...existingFilter.value, curVal.value]
      }
      return acc
    }, [])
  }

  return [filterOptions, onFilter, getFilters, resetFilter];
};
