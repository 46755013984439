import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Menu } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { withResizeDetector } from "react-resize-detector";

import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import SubMenuTitle from "./SubMenuTitle";
import MenuIcon from "./MenuIcon";
import MenuItemTitle from "./MenuItemTitle";
import {
  StyledMenu,
  StyledSider,
  StyledLink,
  StyledSiderWrapper,
  StyledSidebarLogo,
  StyledSidebarLogoWrapper,
} from "./Styled";
import {
  sidebarMenu,
  trigger,
  siderWidth,
  theme,
  mode,
  siderCollapsedWidth,
} from "./settings";
import { SIDEBAR_COLLAPSED_LOGO, SIDEBAR_EXPANDED_LOGO } from "../../constants"

const { SubMenu } = Menu;

const Sidebar = ({ collapsed, authStore, location, height, themeStore, validator }) => {
  const [contentHeight, setContentHeight] = useState(0);

  const { pathname } = location;
  const { user, isInContext, isSuperAdmin} = authStore;
  const { company } = user
  const [ assetBundle ] = company?.assetBundles || []
  const assets = assetBundle?.assets || []
  const sidebarExpandedAsset = assets.find((item) => item.name === SIDEBAR_EXPANDED_LOGO)
  const sidebarCollapsedAsset = assets.find((item) => item.name === SIDEBAR_COLLAPSED_LOGO)
  const sidebarAsset = collapsed ? sidebarCollapsedAsset : sidebarExpandedAsset

  useEffect(() => {
    setContentHeight(height);
  }, [height]);

  const findActiveSubmenuChild = (c) =>
    c.find(({ href }) => href === pathname || pathname.includes(href));
  const findSubmenu = ({ children: c }) => findActiveSubmenuChild(c);

  const subMenus = sidebarMenu.filter(({ children: c }) => c);
  const subMenu = subMenus.find(findSubmenu);
  const subMenuChild =
    subMenu && subMenu.children.find(({ href }) => pathname.includes(href));
  const activeParentItem =
    sidebarMenu.find(({ href }) => pathname.includes(href)) || {};

  const renderItem = (item) => {
    const { key, title, href, icon, children, roles } = item;
    const isSearch = key === 'search';

    if (roles && !validator.validatePermissions(roles, user.role, isInContext))
      return null;

    if (children) {
      return (
        <SubMenu key={key} title={<SubMenuTitle title={title} icon={icon} />}>
          {children.map((child) => renderItem(child))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={href}>
        <StyledLink to={href}>
          <MenuIcon icon={icon} iconWidth={isSearch ? 20 : null} iconHeight={isSearch ? 20 : null} />
          <MenuItemTitle title={title} />
        </StyledLink>
      </Menu.Item>
    );
  };

  return (
    <StyledSiderWrapper>
      <Scrollbars
        style={{
          height: contentHeight || "100%",
          width: collapsed ? siderCollapsedWidth : siderWidth,
        }}
        className="sidebar__scrollbars-wrapper"
        autoHide
      >
        <StyledSider
          collapsible
          collapsed={collapsed}
          trigger={trigger}
          width={siderWidth}
        >
          <StyledMenu
            theme={theme}
            apptheme={themeStore.theme}
            mode={mode}
            selectedKeys={[
              subMenuChild ? subMenuChild.href : activeParentItem.href,
            ]}
            defaultOpenKeys={subMenu ? [subMenu.key] : []}
          >
            {sidebarMenu.map((item) => renderItem(item))}
          </StyledMenu>
        </StyledSider>
      </Scrollbars>
      {!isSuperAdmin && sidebarAsset && isFeatureEnabled('whiteLabeling') && company.isBrandingEnabled && (
        <StyledSidebarLogoWrapper collapsed={collapsed}>
          <StyledSidebarLogo sidebarAsset={sidebarAsset}></StyledSidebarLogo>
        </StyledSidebarLogoWrapper>
      )}
    </StyledSiderWrapper>
  );
};

export default compose(
  withResizeDetector,
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    themeStore: rootStore.themeStore,
    validator: rootStore.validator
  })),
  observer
)(Sidebar);
