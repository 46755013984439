import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledColorPicker, StyledSketchPicker, StyledColor } from "./Styled";
import Label from "../Label";
import { StyledInputWrapper } from "../common/Styled";

const ColorPicker = ({
  label,
  labelTextSize,
  labelTextWeight,
  horizontalLabel,
  color = "",
  height = "22px",
  width = "78px",
  disabled,
  themeStore,
  onChange = () => null,
}) => {
  const [isPickerShown, setIsPickerShown] = useState(false);

  const onOpen = () => setIsPickerShown(true);
  const onClose = () => setIsPickerShown(false);

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <StyledInputWrapper
        position="static"
        horizontalLabel={horizontalLabel}
        disabled={disabled}
      >
        {label && (
          <Label
            text={label}
            horizontalLabel={horizontalLabel}
            textSize={labelTextSize}
            textWeight={labelTextWeight}
          />
        )}
        <StyledColorPicker onClick={onOpen} height={height} width={width}>
          <StyledColor backgroundColor={color} theme={themeStore.theme} />
          {isPickerShown && (
            <StyledSketchPicker color={color} onChange={onChange} />
          )}
        </StyledColorPicker>
      </StyledInputWrapper>
    </OutsideClickHandler>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(ColorPicker);
