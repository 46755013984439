import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "../P";

const Tip = ({ text, themeStore, ...restProps }) => (
  <P size={11} color={themeStore.theme.mainDarkColor} {...restProps}>
    {text}
  </P>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Tip);
