import styled from "styled-components";

export const StyledLotListWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledPopoverIcon = styled.span`
  color: ${({ theme }) => theme.accentColor};
`;
