import { action, computed, observable } from "mobx";

import SubRoll from "../models/SubRoll";

class SubRolls {
  @observable rawSubRoll = {};
  @observable affectedLots = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawSubRoll = new SubRoll({}, rootStore);
  }

  @computed
  get subRoll() {
    return this.rawSubRoll;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rootStore.resetValidationErrors();
    this.rawSubRoll = new SubRoll({}, this.rootStore);
  }

  @action.bound resetAffectedLots() {
    this.affectedLots = [];
  }

  @action.bound async _create(data) {
    const { method, url } = this.rootStore.urls.subRolls.create;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: data || {},
    });

    return response;
  }

  @action.bound async _update(data) {
    const { method, url } = this.rootStore.urls.subRolls.update;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: data || {},
    });

    return response;
  }

  @action.bound async validateForBeenUsedTags(lotId, data) {
    const {
      method,
      url,
    } = this.rootStore.urls.subRolls.validateForBeenUsedTags;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {
        lotId,
        ...data,
      },
    });

    if (response) {
      this.affectedLots = response.data.lots;
      return response.data.lots;
    }

    return [];
  }

  @action create = (paginationConfig) => async ({ useUpdate, data }) => {
    const request = useUpdate ? this._update : this._create;
    const { data: response } = await request(data);

    if (response) {
      const {
        lot: { id: lotId },
      } = this.rootStore.lotsStore;
      await this.rootStore.tagsStore.findAll({ lotId }, paginationConfig);
      await this.rootStore.lotsStore.findById({ id: lotId })();
    }

    return response;
  };

  @action.bound async deleteAssignedTag(lotId, { id }, paginationConfig) {
    const { method, url } = this.rootStore.urls.subRolls.deleteTag;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/?tagId=${id}&lotId=${lotId}`,
    });

    if (response) {
      await this.rootStore.tagsStore.findAll({ lotId }, paginationConfig);
      paginationConfig.onChange(1);
      return response;
    }
  }
}

export default SubRolls;
