import React from "react";
import { Tooltip } from "antd";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledSpan } from "./Styled";

const TagOpticsLink = ({
  id,
  rootStore: {
    routingStore,
    themeStore: { theme }
  }
}) => {
  const openOptics = id => routingStore.push(`/tags/optics/${id}`);

  return (
    <Tooltip title="Tag Optics">
      <StyledSpan theme={theme} onClick={() => openOptics(id)}>
        {id}
      </StyledSpan>
    </Tooltip>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(TagOpticsLink);
