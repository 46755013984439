import React, { useState } from "react";
import { isEmpty } from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Search from "../Search";
import ResultsPopup from "./ResultsPopup";
import { StyledInputWrapper } from "../common/Styled";
import { StyledSearchIcon } from "./Styled";
import useSearch from "./useSearch";

const SearchWithSelect = ({
  data = [],
  dataKeys = [],
  resultsPopupOptions = [],
  width = 400,
  height = 300,
  name = "search",
  themeStore,
  placeholder,
  onSelect,
  onSearch
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult] = useSearch({
    data,
    value: searchValue,
    keys: dataKeys,
    onSearch
  });

  const onChange = (e) => {
    if (onSearch) {
      onSearch(e.target.value)
    }
    setSearchValue(e.target.value)
  };
  const onClosePopup = () => {
    if (onSearch) return
    setSearchValue("")
  };

  return (
    <StyledInputWrapper width={`${width}px`}>
      <OutsideClickHandler onOutsideClick={onClosePopup}>
        <Search
          value={searchValue}
          name={name}
          placeholder={placeholder}
          prefix={
            <StyledSearchIcon
              type="search"
              style={{ color: themeStore.theme.accentColor }}
            />
          }
          autoComplete="off"
          onChange={onChange}
        />
        {!isEmpty(searchResult) && (
          <ResultsPopup
            height={height}
            width={width}
            results={searchResult}
            options={resultsPopupOptions}
            setSearchValue={setSearchValue}
            theme={themeStore.theme}
            onSelect={onSelect}
          />
        )}
      </OutsideClickHandler>
    </StyledInputWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(SearchWithSelect);
