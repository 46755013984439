import { isArray } from "lodash";

// todo: add preloader
export default ({ data = [], keys = [], value = "", onSearch }) => {
  if (onSearch) {
    return [{}];
  }

  let result = {};
  const find = (values, valueKey, searchString) =>
    values.filter(v => {
      if (!v[valueKey]) return false;
      return v[valueKey].toLowerCase().includes(searchString.toLowerCase());
    });

  if (!isArray(keys) || !value) {
    return [{}];
  }

  keys.forEach(key => {
    const results = find(data, key, value);
    result = { ...result, [key]: results };
  });

  return [result];
};
