import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledTag } from "./Styled";

const Tag = ({ bgColor, borderColor, color, themeStore, children }) => (
  <StyledTag
    bgcolor={bgColor}
    bordercolor={borderColor}
    color={color}
    theme={themeStore.theme}
  >
    {children}
  </StyledTag>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Tag);
