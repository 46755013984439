import { useState } from "react";

export default () => {
  const [isShown, setIsShown] = useState(false);

  const callCb = async (cb, state) => {
    if (cb) {
      await cb();
      return setIsShown(state);
    }

    setIsShown(state);
  };

  const onSetIsShown = cb => () => callCb(cb, true);

  const onOkAction = cb => () => callCb(cb, false);

  const onCancelAction = cb => () => callCb(cb, false);

  return [isShown, onSetIsShown, onOkAction, onCancelAction];
};
