import React, {useState} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import AssignExperience from "../AssignExperience";
import Modal from "../../../components/Modal";
import SetExperienceForm from "../forms/SetExperience";
import {
  experienceTableColumns,
  searchOptions,
  useFilterOptions
} from "../../../pages/lots/settings";
import {PreviewSwitch, Select} from "../../../components/inputs";
import ExperiencesTable, {useRowSelect} from "../../../components/TableVirtualized";
import QRCode from "../../miscellany/previews/QRCode";
import Tip from "../../../components/Tip";
import Thumbnail from "../../miscellany/previews/Thumbnail";
import IFrame from "../../miscellany/previews/IFrame";
import {useFilter, useModal, useSorting} from "../../../components/hooks";
import {isMobile} from "react-device-detect";

const LotAdvanced = ({ options, themeStore, experiencesStore, lot }) => {
  const { experiences } = experiencesStore;
  const {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedItem,
    setSelected,
    resetAllSelected
  } = useRowSelect(experiences, "single");
  const [filteredData, filterOptions, setFilterOptions] = useFilter({
    data: dataWithSelectedRows,
    options: useFilterOptions.items,
    dataKey: useFilterOptions.dataKey
  });

  const [sortedData, onSort] = useSorting(filteredData);
  const [type, setType] = useState("");
  const [previewMode, setPreviewMode] = useState("iframe");
  const [preview, setPreview] = useState("");
  const [qrcLink, setQrcLink] = useState("");
  const [isOpenStateModal, setOpenStateModal] = useState(false);
  const [isClosedStateModal, setClosedStateModal] = useState(false);
  const [isModalShown, setModalShown, onModalOk, onModalCancel] = useModal();

  const numRows = isMobile ? 7 : 10;
  const previewMaxHeight = isMobile ? "472px" : "735px";
  const onModalClose = () => {
    setOpenStateModal(false)
    setClosedStateModal(false)
    onModalCancel()();
  };
  const onAssignExperience = () => {
    const selectedItem = getSelectedItem()
    if (isOpenStateModal) {
      lot.configAdvanced.openStateExperienceId = selectedItem.id
      setOpenStateModal(false)
      onModalCancel()();
      return
    }

    if (isClosedStateModal) {
      lot.configAdvanced.closedStateExperienceId = selectedItem.id
      setClosedStateModal(false)
      onModalCancel()();
      return
    }

    lot.assignExperience(selectedItem);
    onModalCancel()();
  };
  const onSelect = (experience, setIndex) => async () => {
    onRowSelect(experience, setIndex)();
    setType(experience.type.name);
    await loadPreview(experience);
  };
  const loadPreview = async ({ id, selected, type }) => {
    const currentType = !selected ? type.name : "";
    const currentExperience = experiencesStore.experiences.find(
      e => e.id === id
    );

    switch (currentType) {
      case "menu": {
        const url = `${process.env.REACT_APP_MENU_PREVIEW_HOST}/${currentExperience.id}`;
        setPreview(url);
        setQrcLink(url);
        break;
      }
      case "sequence": {
        const { image, url } = await currentExperience.steps[0].showPreview();
        setPreview(image);
        setQrcLink(url);
        break;
      }
      default: {
        setPreview("");
        setQrcLink("");
      }
    }
  };

  const openStateExperience = experiences.find((item) => {
    return item.id === lot.configAdvanced.openStateExperienceId
  })

  const closedStateExperience = experiences.find((item) => {
    return item.id === lot.configAdvanced.closedStateExperienceId
  })

  return (
    <Div direction="column">
      <Modal
        title="Set Experience"
        showModal={isModalShown}
        onCancel={onModalClose}
        onConfirm={onModalOk(onAssignExperience)}
        confirmButtonTitle="Save"
        confirmButtonProps={{ disabled: !hasSelected() }}
        width="1200px"
      >
        <Div justify="space-between" width="100%">
          <Div width="60%" direction="column">
            <Div justify="space-between">
              <SetExperienceForm
                data={sortedData}
                filterOptions={filterOptions}
                searchPopupOptions={searchOptions}
                onFilterChange={setFilterOptions}
                onSearchSelect={onSelect}
              />
              <PreviewSwitch
                types={["iframe", "qrc"]}
                currentType={previewMode}
                disabled={!hasSelected()}
                onClick={setPreviewMode}
              />
            </Div>
            <Div>
              <ExperiencesTable
                columns={experienceTableColumns}
                data={sortedData}
                onSort={onSort}
                rowCount={numRows}
                selectedIndex={selectedIndex}
                onRowSelect={onSelect}
              />
            </Div>
          </Div>
          <Div
            width="40%"
            align="center"
            direction="column"
            styles={{
              height: previewMaxHeight,
              overflowY: "hidden"
            }}
          >
            {!hasSelected() && (
              <Div
                height="100%"
                direction="column"
                justify="center"
                align="center"
              >
                <P size={16}>Choose experience to preview</P>
              </Div>
            )}
            {previewMode === "qrc" && hasSelected() && (
              <>
                <QRCode src={qrcLink} />
                <Tip
                  text="Scan QRC with your camera to preview experience"
                  top={20}
                />
              </>
            )}
            {previewMode === "iframe" && hasSelected() && (
              <Div align="center">
                {(type === "sequence" || !type) && (
                  <Thumbnail src={preview} width={338} height={730} />
                )}
                {type === "menu" && (
                  <IFrame src={preview} width={338} height={730} />
                )}
              </Div>
            )}
          </Div>
        </Div>
      </Modal>
      <Div direction="row" bottom={25}>
        <Div direction="column">
          <P bottom={12}>Lot experience</P>
          <AssignExperience
            experience={lot.experience}
            onSet={async () => {
              resetAllSelected()
              setSelected([lot.experience.id])
              setType(lot.experience.type.name)
              setModalShown()()
              await loadPreview(lot.experience);
            }}
            onDelete={lot.removeExperience}
          />
        </Div>
      </Div>

      <Div direction="row" bottom={15}>
        <Div right={40}>
          <Select
            label={
              <>
                <strong>Tamper</strong>
                <br />
                Closed state
              </>
            }
            list={[
              { name: "Use lot experience", value: "false" },
              { name: "Use custom experience", value: "true" }
            ]}
            inputWidth={"210px"}
            valueKey="value"
            selectedValue={String(lot.configAdvanced?.customClosedStateExperience) || "false"}
            onChange={v => (lot.configAdvanced.customClosedStateExperience = v === "true")}
          />
        </Div>
        {(
          <Div direction="column">
            <P top={22} bottom={closedStateExperience ? 16 : 8}>Custom experience</P>
            <AssignExperience
              disabled={String(lot.configAdvanced?.customClosedStateExperience) !== 'true'}
              title="Set experience"
              subTitle="This lot doesn't have experience for tags with closed state yet"
              experience={closedStateExperience}
              onSet={async () => {
                setClosedStateModal(true)
                resetAllSelected()
                if (closedStateExperience && closedStateExperience.id) {
                  setSelected([closedStateExperience.id])
                  setType(closedStateExperience.type.name)
                  loadPreview(closedStateExperience)
                }
                setModalShown()()
              }}
              onDelete={() => {
                lot.configAdvanced.closedStateExperienceId = null
              }}
            />
          </Div>
        )}
      </Div>

      <Div direction="row" bottom={15}>
        <Div right={40}>
          <Select
            label={
              <>
                <br />
                Open state
              </>
            }
            list={[
              { name: "Use lot experience", value: "false" },
              { name: "Use custom experience", value: "true" }
            ]}
            inputWidth={"210px"}
            valueKey="value"
            selectedValue={String(lot.configAdvanced?.customOpenStateExperience) || "false"}
            onChange={v => (lot.configAdvanced.customOpenStateExperience = v === "true")}
          />
        </Div>
        {(
          <Div direction="column">
            <P top={22} bottom={openStateExperience ? 16 : 8}>Custom experience</P>
            <AssignExperience
              disabled={String(lot.configAdvanced?.customOpenStateExperience) !== 'true'}
              title="Set experience"
              subTitle="This lot doesn't have experience for tags with open state yet"
              experience={openStateExperience}
              onSet={async () => {
                setOpenStateModal(true)
                resetAllSelected()
                if (openStateExperience && openStateExperience.id) {
                  setSelected([openStateExperience.id])
                  setType(openStateExperience.type.name)
                  loadPreview(openStateExperience);
                }
                setModalShown()()
              }}
              onDelete={() => {
                lot.configAdvanced.openStateExperienceId = null
              }}
            />
          </Div>
        )}
      </Div>

      <Div direction="row" bottom={25}>
        <Div right={40}>
          <Select
            label={
              <>
                <br />
                Tampered state
              </>
            }
            list={[
              { name: "Use lot experience", value: "false" },
              { name: "Use open tag experience", value: "open" },
              { name: "Use closed tag experience", value: "closed" }
            ]}
            inputWidth={"210px"}
            valueKey="value"
            selectedValue={lot.configAdvanced?.customTamperedStateExperience ? String(lot.configAdvanced?.customTamperedStateExperience) : "false"}
            onChange={v => {
              if (v === "false") {
                lot.configAdvanced.customTamperedStateExperience = false
              } else {
                lot.configAdvanced.customTamperedStateExperience = v
              }
            }}
          />
        </Div>
      </Div>

      {/*<Div direction="row" bottom={15}>*/}
      {/*  <Div right={40}>*/}
      {/*    <Select*/}
      {/*      label={*/}
      {/*        <>*/}
      {/*          <strong>Activation Parameters</strong>*/}
      {/*          <br />*/}
      {/*          Pass parameters in URL*/}
      {/*        </>*/}
      {/*      }*/}
      {/*      list={[*/}
      {/*        { name: "Yes", value: "true",  icon: "active" },*/}
      {/*        { name: "No", value: "false", icon: "inactive" },*/}
      {/*      ]}*/}
      {/*      inputWidth={"210px"}*/}
      {/*      iconKey="icon"*/}
      {/*      valueKey="value"*/}
      {/*      selectedValue={lot.configAdvanced?.passActivationParams ? String(lot.configAdvanced?.passActivationParams) : "false"}*/}
      {/*      onChange={v => (lot.configAdvanced.passActivationParams = v === "true")}*/}
      {/*    />*/}
      {/*  </Div>*/}
      {/*</Div>*/}

    </Div>
  );
}

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    experiencesStore: rootStore.experiencesStore
  })),
  observer
)(LotAdvanced);
