import L from "leaflet";
import "leaflet-providers";
import _ from "lodash";

import { tilesProviders, templateMapData, templateMapStyle } from "./settings";

export const createMap = (
  container,
  {
    mapFeaturesOff,
    zoomControl = true,
    zoom = 2,
    center = [0, 0],
    onEachFeature,
    bounds = [
      [-89.98155760646617, -180],
      [89.99346179538875, 180]
    ]
  }
) => {
  const map = L.map(container, {
    zoomControl: zoomControl,
    zoomSnap: 0.1,
    center: center,
    zoom: zoom,
    minZoom: 1.8,
    maxZoom: 17,
    maxBounds: bounds,
    maxBoundsViscosity: 1.0
  });

  map.fitBounds(bounds);
  map.on('drag', () => map.panInsideBounds(bounds, { animate: false }));

  // map features on by default:
  //  "dragging"
  //  "doubleClickZoom"
  //  "scrollWheelZoom"
  //  "boxZoom"
  //  "keyboard"
  //  "tap"
  mapFeaturesOff && mapFeaturesOff.forEach(f => map[f] && map[f].disable());

  const none = L.tileLayer("");
  const carto = L.tileLayer.provider("CartoDB", tilesProviders["CartoDB"]);
  const here = L.tileLayer.provider("HEREv3", tilesProviders["HEREv3"]);

  const standard = L.geoJSON(
    { ...templateMapData },
    { ...templateMapStyle, onEachFeature }
  );
  const highlighted = L.geoJSON(
    { ...templateMapData },
    { ...templateMapStyle, onEachFeature }
  );
  const markers = L.geoJSON(
    { ...templateMapData },
    { ...templateMapStyle, onEachFeature }
  );
  const base = {
    None: none,
    CARTO: carto,
    HERE: here
  };
  const overlay = {
    Selected: highlighted,
    Unselected: standard
  };
  const control = L.control.layers(base, overlay);

  [here, highlighted, markers].forEach(layer => layer.addTo(map));

  return { map, standard, highlighted, markers, control };
};

export const updateLayer = (layer, features, style) => {
  layer.clearLayers();
  layer.addData({ ...templateMapData, features });
  if (!_.isEmpty(style)) {
    layer.setStyle({ ...templateMapStyle, ...style });
  }
};

export const createMarker = geo => L.marker(geo);
