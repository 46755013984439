import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Button from "../Button";
import Icon from "../../Icon";
import { StyledSelectButtonTitle } from "../Styled";

const SelectButton = ({
  type = "largeSelect",
  title = "",
  icon = "",
  iconWidth = 22,
  iconHeight = 22,
  themeStore,
  disabled = false,
  onClick,
}) => (
  <Button type={type} disabled={disabled} onClick={onClick}>
    <Icon icon={icon} width={iconWidth} height={iconHeight} />
    {title ? (
      <StyledSelectButtonTitle theme={themeStore.theme}>
        {title}
      </StyledSelectButtonTitle>
    ) : null}
  </Button>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(SelectButton);
