import React from "react";

import Popover from "../../Popover";
import Div from "../../Div";
import { Button } from "../../actions";
import Icon from "../../Icon";
import { StyledSortingTitle } from "./Styled";

const SortingPopover = ({ hasTitle, options, theme, onSort }) => {
  const onSorting = (dataKey, condition) => () => onSort(dataKey, condition);

  return (
    <Div left={hasTitle ? 12 : 0}>
      <Popover
        button={
          <Button>
            <Icon icon="sort" width={14} height={14} />
          </Button>
        }
        placement="bottom"
      >
        <StyledSortingTitle theme={theme}>Sort by</StyledSortingTitle>
        {options.map((o) => (
          <Button
            type="sortAction"
            key={o.key}
            onClick={onSorting(o.dataKey, o.condition)}
          >
            {o.title}
          </Button>
        ))}
      </Popover>
    </Div>
  );
};

export default SortingPopover;
