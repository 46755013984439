import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import Image from "components/Image";
import { Link } from "components/actions";
import whiteLabel, { defaultWhiteLabel } from "domain/services/white-label";

const CompanyLogo = ({
  href = "https://tapwow.net",
  rootStore,
  themeStore: { theme }
}) => {
  const renderLogo = () => {
    if (whiteLabel !== defaultWhiteLabel) {
      return (
        <Div align="center" direction="column">
          <Image src={theme.constraintModal.logo} width={150} />
        </Div>
      );
    } else {
      return (
        <Link to={href} type="tapWowLogo" external>
          <Div align="center" direction="column">
            <Image src={theme.darkLogo} height={25} />
          </Div>
        </Link>
      );
    }
  };

  return (
    <Div direction="column">
      {renderLogo()}
      <Div top={6} justify="space-between">
        <P color={theme.versionColor} right={20}>
          client: {rootStore.appVersion}
        </P>
        <P color={theme.versionColor}>
          server: {rootStore.serverVersion}
        </P>
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(CompanyLogo);
