import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Div from "components/Div";
import P from "components/P";
import withCancelModal from "components/withCancelModal";
import { Header, Inner } from "components/Content";
import Constraint from "components/Constraint";
import IFrame from "features/miscellany/previews/IFrame";
import MenuSteps from "features/experiences/MenuSteps";
import EditMenuExperience from "features/experiences/forms/EditMenuExperience";

const Edit = ({
  experiencesStore,
  rootStore,
  templatesStore,
  themeStore,
  match,
  constraintGetter,
  onLeavePage
}) => {
  const { experience, findAllByType, findById, save, reset } = experiencesStore;
  const { id } = match.params;
  const isEditRoute = !!id;
  const innerTitle = isEditRoute ? "Edit Menu" : "Create Menu";

  useEffect(() => {
    const onMount = async () => {
      await templatesStore.findAll();
      await findAllByType("menu");
      if (id) {
        await findById({ id, type: "menu" })();
      }
    };

    onMount();
    return reset;
  }, [findAllByType, findById, templatesStore, reset, id]);

  return (
    <Constraint
      constraintGetter={constraintGetter}
      capacityGetter="experiences.menu.capacity"
      amount={experiencesStore.menuExperiences.length}
    >
      <Header
        title={innerTitle}
        backButton
        backButtonFn={onLeavePage(experience.isDirty)}
        backButtonLink="/experiences/menu"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button
              type="regularFlat"
              onClick={onLeavePage(experience.isDirty)}
            >
              Cancel
            </Button>
            <Button type="regular" onClick={save(isEditRoute, "menu")}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Inner>
        <EditMenuExperience />
        <Div width="100%" top={25}>
          <Div direction="column" width="100%" styles={{ maxWidth: "900px" }}>
            <Div display="block" right={25}>
              <P bottom={12}>Menu experience buttons</P>
              <MenuSteps
                experience={experience}
                errors={rootStore.validationErrors.steps}
              />
            </Div>
          </Div>
          <Div display="block">
            <Div justify="space-between" bottom={12}>
              <Div>
                <P>Preview</P>
              </Div>
              <Div>
                <Button type="preview" onClick={experience.showPreview} />
              </Div>
            </Div>
            <Div>
              <IFrame src={experience.preview} />
            </Div>
          </Div>
        </Div>
      </Inner>
    </Constraint>
  );
};

export default compose(
  withCancelModal("/experiences/menu"),
  inject(({ rootStore }) => ({
    experiencesStore: rootStore.experiencesStore,
    templatesStore: rootStore.templatesStore,
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(Edit);
