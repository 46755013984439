import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  .ant-table {
    color: ${({ theme }) => theme.mainDarkColor} !important;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
    color: ${({ theme }) => theme.mainDarkColor} !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: ${({ theme }) => theme.tertiaryDarkColor} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.accentColor} !important;
    border-color: ${({ theme }) => theme.accentColor} !important;
  }

  .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.tertiaryDarkColor} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.tertiaryDarkColor} !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.tertiaryDarkColor} !important;
  }

  ${({ fullHeight }) => fullHeight && `
    .ant-table-empty .ant-table-scroll .ant-table-body {
        min-height: 0;
    }
  `}

  .ant-table-scroll {
    position: relative;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${({ fullHeight }) => fullHeight && `
        border-radius: 4px;
        border-top: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;

        .ant-table-header {
          & > table {
             border: none;
           }
        }
        
        .ant-table-body {
          min-height: 192px;
          & > table {
             border: none;
          }
          
          td:last-child {
            border-right: none;
          }
        }
        
        .ant-table-placeholder {
          height: 192px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: none !important;
          border-right: none !important;
        }
  `}

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      height: 10px;
      width: 100%;
      bottom: 0px;
      border-bottom: 1px solid #e8e8e8;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ant-table-row:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .ant-table-header {
    table {
      border-top-right-radius: 0;
    }

    ::-webkit-scrollbar {
      width: 10px;
      border-top-right-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      visibility: hidden;
    }

    th:last-child {
      border-right: none;
    }
  }

  .ant-table-body {
    ::-webkit-scrollbar {
      width: 10px;
    }
    
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.15);
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      
      &:hover {
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.4);
      }
    }
  }
`;
