import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Asterix from "../common/Asterix";
import P from "../../P";
import Popover from "../../Popover";
import Div from "../../Div";

const Label = ({
  themeStore,
  text,
  horizontalLabel,
  textSize = 14,
  textWeight = "normal",
  infoPopover = false,
  infoPopoverContent = "",
  infoPopoverPlacement = "right",
  color = "",
  required = false,
}) => (
  <Div
    align="center"
    bottom={horizontalLabel ? 0 : 12}
    right={horizontalLabel ? 12 : 0}
  >
    <P
      color={color ? color : themeStore.theme.mainDarkColor}
      size={textSize}
      weight={textWeight}
    >
      {text}
      {required && <Asterix />}
    </P>
    {infoPopover && (
      <Popover.InfoPopover placement={infoPopoverPlacement}>
        {infoPopoverContent}
      </Popover.InfoPopover>
    )}
  </Div>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Label);
