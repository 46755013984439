import React, { useEffect } from "react";
import { Select } from "antd";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";

const LotSelect = ({ lotsStore, onLotSelect }) => {
  useEffect(() => {
    const fetch = async () => await lotsStore.findAll();

    fetch();
    return lotsStore.reset;
  }, [lotsStore]);

  const select = id => onLotSelect(lotsStore.lots.find(lot => lot.id === id));

  return (
    <Select
      showSearch
      dropdownMatchSelectWidth={false}
      placeholder="Select a lot"
      optionFilterProp="children"
      onChange={select}
      style={{ width: "100%" }}
    >
      {lotsStore.lots.map(lot => (
        <Select.Option key={lot.id} value={lot.id}>
          {lot.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    lotsStore: rootStore.lotsStore,
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(LotSelect);
