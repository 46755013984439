import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledInput } from "./Styled";
import { StyledInputWrapper } from "../common/Styled";
import Label from "../Label";
import ErrorMessage from "../../ErrorMessage";
import Div from "../../Div";

const Text = ({
  label = "",
  labelTextSize,
  value = "",
  placeholder,
  type = "text",
  index = 0,
  name = "",
  disabled,
  error = false,
  errorMessage = "",
  horizontalLabel = false,
  required = false,
  autoComplete = "off",
  suffixIcon,
  infoPopover = false,
  infoPopoverContent = "",
  infoPopoverPlacement = "right",
  themeStore,
  maxLength,
  onChange = () => null,
}) => {
  return (
    <StyledInputWrapper horizontalLabel={horizontalLabel}>
      {label && (
        <Label
          text={label}
          horizontalLabel={horizontalLabel}
          textSize={labelTextSize}
          required={required}
          infoPopover={infoPopover}
          infoPopoverContent={infoPopoverContent}
          infoPopoverPlacement={infoPopoverPlacement}
        />
      )}
      <Div display="block" width="100%">
        <StyledInput
          value={value}
          placeholder={placeholder}
          type={type}
          data-index={index}
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          error={String(error)}
          autoComplete={autoComplete}
          suffix={suffixIcon}
          theme={themeStore.theme}
          onChange={onChange}
        />
        {error && <ErrorMessage text={errorMessage} />}
      </Div>
    </StyledInputWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Text);
