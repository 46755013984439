import styled from "styled-components";
import { SketchPicker } from "react-color";

export const StyledColorPicker = styled.div`
  position: relative;
  display: flex;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const StyledColor = styled.span`
  width: 70px;
  height: 23px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ theme }) => theme.mainDarkColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  opacity: 1;
`;

export const StyledSketchPicker = styled(SketchPicker)`
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
`;
