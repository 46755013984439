import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledErrorMessage } from "./Styled";

const ErrorMessage = ({ text, bottom, left, themeStore }) => (
  <StyledErrorMessage bottom={bottom} left={left} theme={themeStore.theme}>
    {text}
  </StyledErrorMessage>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(ErrorMessage);
