import styled, { css } from "styled-components";
import { Collapse } from "antd";
import { get } from "lodash";

const { Panel } = Collapse;

const getLookList = (theme) => ({
  template: {
    collapse: css`
      width: 100%;
      border: 2px solid ${theme.mainLightColor} !important;

      .ant-collapse-header {
        width: 100%;
        padding: 18px 12px;
        background-color: ${theme.mainLightColor};
      }

      .ant-collapse-header p {
        font-weight: bold;
      }

      .ant-collapse-header > img {
        right: 12px;
      }
    `,
    panel: css`
      margin-bottom: 5px;

      &:after {
        display: none;
      }

      .ant-collapse-content-box:before {
        display: none;
      }
    `,
  },
});

export const StyledPanel = styled(Panel)`
  .ant-collapse-content-box {
    display: flex;
    flex-direction: row;
    padding: 0 !important;

    &::before {
      position: relative;
      content: "";
      width: ${({ isLastChild }) => !isLastChild && `2px`};
      margin: -12px 40px;
      background-color: ${({ theme }) => theme.mainDarkColor};
    }
  }

  .ant-collapse-content {
    overflow: initial;
  }

  ${({ look = "", theme }) => get(getLookList(theme)[look], "panel", "")}
`;

export const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  width: 90%;

  .ant-collapse-header {
    padding-left: ${({ withHeaderSwitch }) =>
      withHeaderSwitch ? "60px" : "26px "}!important;
    padding-right: 40px !important;

    & > img {
      position: absolute;
      right: 0;
      top: calc(50% - 4px);
      transform: rotate(180deg) !important;
      transition: transform 0.24s;
      -webkit-transition: -webkit-transform 0.24s;
    }
  }

  .ant-collapse-item {
    position: relative;
    display: flex;
    flex-direction: column;
    border: none !important;

    &::after {
      position: absolute;
      content: "";
      left: 82px;
      height: 1px;
      bottom: 0;
      width: ${({ separatorWidth }) =>
        separatorWidth ? `${separatorWidth}` : `60%`};
      background-color: ${({ theme }) => theme.mainLightColor};
    }

    i {
      font-size: 16px !important;
    }
  }

  .ant-collapse-item:last-child {
    &:after {
      display: none;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header > img {
      transform: rotate(360deg) !important;
    }
  }

  .ant-collapse-header {
    align-self: start;

    &::after {
      height: 1px;
      width: 60%;
      background-color: ${({ theme }) => theme.secondaryLightColor};
    }
  }

  ${({ look, theme }) => get(getLookList(theme)[look], "collapse", "")}
`;

export const StyledSwitcherWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 0;
`;
