import React from "react";

import { dateFormatter } from "utils/format";
import TagOpticsLink from "features/tags/TagOpticsLink";
import { ValueWithDetail } from "../Dashboard/render";

const createdAtFields = ["createdAt", "timezone"];

const whereFields = [
  "country",
  "region",
  "postalCode",
  "county",
  "city",
  "houseNumber",
  "street",
  "lat",
  "lon",
  "ipAddress",
  "browserAccuracy"
];

const addressFields = ["street", "city", "region", "postalCode", "country"];

const uaFields = [
  "browserName",
  "browserVersion",
  "browserMajor",
  "engineName",
  "engineVersion",
  "osName",
  "osVersion",
  "deviceVendor",
  "deviceModel",
  "deviceType"
];

export const columns = [
  {
    title: "When",
    key: "createdAt",
    dataIndex: "createdAt",
    width: "10vw",
    render: (_, row) => (
      <ValueWithDetail
        value={dateFormatter(row.createdAt)}
        detailFields={createdAtFields}
        detailValues={createdAtFields.map(f => row[f])}
      />
    )
  },
  {
    title: "Where",
    key: "where",
    dataIndex: "label",
    align: "center",
    render: (_, row) => (
      <ValueWithDetail
        value={
          row.label ||
          addressFields
            .filter(f => !!row[f])
            .map(f => row[f])
            .join(", ")
        }
        detailFields={whereFields}
        detailValues={whereFields.map(f => row[f])}
      />
    )
  },
  {
    title: "Tag",
    key: "tag",
    dataIndex: "tagId",
    align: "center",
    width: "16vw",
    render: (_, row) => <TagOpticsLink id={row.tagId} />
  },
  {
    title: "Device",
    key: "device",
    dataIndex: "deviceId",
    align: "center",
    width: "16vw",
    render: (_, row) => (
      <ValueWithDetail
        value={row.deviceId}
        detailFields={uaFields}
        detailValues={uaFields.map(f => row[f])}
      />
    )
  }
];
