import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text } from "components/inputs";
import Form from "components/Form";

const CompanyPrimaryContact = ({
  companiesStore: { company },
  rootStore: { validationErrors }
}) => (
  <Form width="100%">
    <Div width="30%" direction="column">
      <Div bottom={25}>
        <Text
          label="Name"
          value={company.contactName}
          name="contactName"
          error={validationErrors.contactName}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Email"
          value={company.contactEmail}
          name="contactEmail"
          error={validationErrors.contactEmail}
          errorMessage="Must be valid email."
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          type="tel"
          label="Phone"
          value={company.contactPhone}
          name="contactPhone"
          error={validationErrors.contactPhone}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    rootStore
  })),
  observer
)(CompanyPrimaryContact);
