import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import QRCode from "qrcode.react";

import { compose } from "utils/hoc";
import Modal from "components/Modal";
import Div from "components/Div";
import P from "components/P";
import Table from "components/Table";
import { Button } from "components/actions";
import { useModal, useTablePaginator } from "components/hooks";
import withCancelModal from "components/withCancelModal";
import LotDetails, { getLotDetailsConfig } from "features/lots/LotDetails";
import FullRoll from "features/lots/tags/FullRoll";
import FromLot from "features/lots/tags/FromLot";
import SeparateTags from "features/lots/tags/SeparateTags";
import Range from "features/lots/tags/Range";
import { assignedTagsTableColumns } from "./settings";
import Metadata from "../../miscellany/Metadata";
import AssetBundle from "../../miscellany/AssetBundle";

const { REACT_APP_REDIRECT_HOST } = process.env;

const EditLotTags = ({
  lot,
  isEditRoute,
  rootStore: {
    tagsStore,
    subRollsStore,
    themeStore: { theme }
  }
}) => {
  const { save } = tagsStore;
  const [tag, setTag] = useState({});
  const qrc = useRef(null);

  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel
  ] = useModal();
  const [isQRCodeModalShown, setQRCodeModalShown, onQRCodeModalOk] = useModal();
  const [isMetadataModalShown, setMetadataModalShown, onMetadataModalOk, onMetadataModalCancel] = useModal();
  const [isAttachmentsModalShown, setAttachmentsModalShown, onAttachmentsModalOk] = useModal();

  const [pagination, paginationReset] = useTablePaginator({
    onPageChange: tagsStore.onNextPage({ lotId: lot.id })
  });

  useEffect(() => {
    const fetchTags = async lotId => {
      !lotId
        ? tagsStore.reset({ tagsByLot: true })
        : await tagsStore.findAll({ lotId }, pagination);
    };

    fetchTags(lot.id);
    return () => {
      tagsStore.reset({ tagsByLot: true });
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lot.id]);

  const onSetCurrentTag = t => () => setTag(t);

  const onBundleCreated = async (relation) => {
    if (relation && relation.relationEntity === 'tag') {
      const tag = await tagsStore.findById({id: relation.relationId})
      tagsStore.updateLotTag(tag)
    }
  }

  const renderActions = row => (
    <>
      <Button
        type="popoverRegular"
        onClick={setQRCodeModalShown(onSetCurrentTag(row))}
      >
        Activation Link
      </Button>
      <Button
        type="popoverRegular"
        onClick={setMetadataModalShown(onSetCurrentTag(row))}
      >
        Properties
      </Button>
      <Button
        type="popoverRegular"
        onClick={setAttachmentsModalShown(onSetCurrentTag(row))}
      >
        Attachments
      </Button>
      <Button
        type="popoverRedText"
        onClick={setDeleteModalShown(onSetCurrentTag(row))}
      >
        Delete
      </Button>
    </>
  );

  const onDownloadQRC = () => {
    const canvas = qrc.current.children[0];
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = tag.id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const activationURL = (tag?.id) ? new URL(tag.id, REACT_APP_REDIRECT_HOST).toString() : "";

  return (
    <>
      <Modal
        title="Tag properties"
        showModal={isMetadataModalShown}
        onCancel={onMetadataModalCancel()}
        confirmButtonTitle="Save"
        onConfirm={onMetadataModalOk(
          async () => {
            await save(tag)
          }
        )}
        width="auto"
      >
        <Div direction="column" align="center">
          <Metadata parentStore={tag} parentName="tag" />
        </Div>
      </Modal>
      <Modal
        title="Tag attachments"
        showModal={isAttachmentsModalShown}
        onCancel={onAttachmentsModalOk()}
        width="auto"
      >
        <Div direction="column" align="center">
          <AssetBundle
            onBundleCreated={(relation) => onBundleCreated(relation)}
            companyId={tag.company && tag.company.id}
            assetBundles={tag.assetBundles}
            relationEntity="tag"
            relationId={tag.id}
            parentText="tag"
          />
        </Div>
      </Modal>
      <Modal
        title="Delete tag from lot"
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel(() => onSetCurrentTag({}))}
        onConfirm={onDeleteModalOk(
          async () => {
            const response = await subRollsStore.deleteAssignedTag(lot.id, tag, pagination);
            if (response) {
              lot.changeTagsQty(tag?.roll?.tagType);
            }
          }
        )}
      >
        <Div direction="column" align="center">
          <P bottom={5}>Are you sure you want to delete tag from lot</P>
          <P>{`with id ${tag.id}?`}</P>
        </Div>
      </Modal>
      <Modal
        title="Activation Link"
        showModal={isQRCodeModalShown}
        confirmButtonTitle={"Download"}
        onConfirm={onQRCodeModalOk(onDownloadQRC)}
        onCross={onQRCodeModalOk(() => onSetCurrentTag({}))}
      >
        <Div top={10} direction="column" align="center">
          <div
            ref={qrc}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <QRCode
              value={activationURL}
              size={280}
              level={"H"}
              includeMargin={true}
              renderAs={"canvas"}
              style={{ borderRadius: theme.borderRadius }}
              fgColor="#fffffff8"
              bgColor={theme.mainDarkColor}
              imageSettings={{
                src: theme.icons.qrcCenterImage,
                width: 45,
                height: 45,
                excavate: true
              }}
            />
          </div>
          <Div top={15} justify="center">
            <P>{activationURL}</P>
          </Div>
        </Div>
      </Modal>
      <Div width="100%" direction="column">
        <Div justify="center" bottom={25}>
          <LotDetails options={getLotDetailsConfig(lot)} />
        </Div>
        <Div justify="space-between" paddingBottom="17px">
          <FullRoll
            lotId={lot.id}
            isEditRoute={isEditRoute}
            createSubRoll={subRollsStore.create(pagination)}
          />
          <Range
            lotId={lot.id}
            isEditRoute={isEditRoute}
            createSubRoll={subRollsStore.create(pagination)}
          />
          <SeparateTags
            lotId={lot.id}
            isEditRoute={isEditRoute}
            createSubRoll={subRollsStore.create(pagination)}
          />
          <FromLot
            lotId={lot.id}
            isEditRoute={isEditRoute}
            createSubRoll={subRollsStore.create(pagination)}
          />
        </Div>
        <Div bottom={25}>
          <Table
            columns={assignedTagsTableColumns}
            data={tagsStore.tagsByLot}
            paginationConfig={pagination}
            renderActions={renderActions}
          />
        </Div>
      </Div>
    </>
  );
};

export default compose(
  withCancelModal("/lots"),
  inject(({ rootStore }) => ({ rootStore })),
  observer
)(EditLotTags);
