import { observable, action, computed, toJS } from "mobx";

import uuid from "uuid/v4";
import DirtyState from "./DirtyState";
import MetadataItem from "./MetadataItem";

const PATH_LIST_TO_OBSERVE = ["description", "metadata"];

class Roll extends DirtyState {
  @observable description = "";
  @observable assetBundles = [];
  @observable metadata = {};
  @observable metadataArray = [];
  @observable rawMetadataItem = {};

  @observable includeTags = false;
  @observable includeQRCodes = false;
  @observable qrCodeType = "simple";

  constructor(roll, rootStore) {
    super(PATH_LIST_TO_OBSERVE, roll);

    this.rootStore = rootStore;
    Object.assign(this, roll);

    const rollMetadata = roll.metadata;

    if (rollMetadata) {
      const newMetadata = [];

      Object.keys(rollMetadata).map(key => {
        return newMetadata.push({
          id: uuid(),
          name: key,
          value: rollMetadata[key]
        });
      });

      this.metadataArray = newMetadata;
    }

    this.rawMetadataItem = new MetadataItem({}, rootStore);
  }

  @computed
  get metadataItem() {
    return this.rawMetadataItem;
  }

  @action.bound resetMetadata() {
    this.rawMetadataItem = new MetadataItem({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @computed
  get updateData() {
    return toJS({
      description: this.description,
      metadata: toJS(this.metadata)
    });
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }

  @action addMetadata = async () => {
    const body = {
      name: this.rawMetadataItem.name,
      value: this.rawMetadataItem.value
    };

    const errors = await this.rootStore.validator.validateMetadata({
      name: this.rawMetadataItem.name,
      value: this.rawMetadataItem.value,
      metadata: this.metadata
    });

    if (this.rootStore.hasValidationErrors(errors)) return;

    this.resetMetadata();
    this.metadata = { ...this.metadata, [body.name]: body.value };
    
    return this.metadataArray.push(
      new MetadataItem({ ...body, id: uuid() }, this.rootStore)
    );
  };

  @action deleteMetadataById = id => {
    const deleteItemFromMetadata = () =>
      this.metadataArray.filter(m => m.id !== id);
    this.metadataArray = deleteItemFromMetadata();

    let newMetadata = {};
    this.metadataArray.map(item => {
      return newMetadata = { ...newMetadata, [item.name]: item.value };
    });

    this.metadata = newMetadata;
  };

  @action.bound onChangeIncludeTags() {
    this.includeTags = !this.includeTags;
  }

  @action.bound onIncludeQRCodes() {
    this.includeQRCodes = !this.includeQRCodes;
  }

  @action.bound onQRCodeTypeSelect(value) {
    this.qrCodeType = value;
  }
}

export default Roll;
