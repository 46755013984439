import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text } from "components/inputs";
import Form from "components/Form";

const MetadataForm = ({ metadata, rootStore }) => {
  const { validationErrors } = rootStore;

  return (
    <Form direction="column" width="100%">
      <Div bottom={25}>
        <Text
          label="Name"
          value={metadata.name}
          name="name"
          error={validationErrors.name}
          errorMessage="Must be more than 1 and less than 64 symbols. Must be be unique"
          onChange={metadata.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Value"
          value={metadata.value}
          name="value"
          error={validationErrors.value}
          errorMessage="Must be more than 1 and less than 256 symbols"
          onChange={metadata.change}
          required
        />
      </Div>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore
  })),
  observer
)(MetadataForm);
