import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Select, Text, TextArea } from "components/inputs";
import Form from "components/Form";
import { companyStatuses } from "./settings";

const EditCompany = ({
  constraintsStore: { constraints },
  companiesStore: { company },
  rootStore: { validationErrors }
}) => (
  <Form direction="row" width="100%">
    <Div direction="column" width="30%" right={30}>
      <Div bottom={25}>
        <Text
          label="Company name"
          value={company.name}
          name="name"
          error={validationErrors.name}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <TextArea
          label="Description"
          name="description"
          value={company.description}
          error={validationErrors.description}
          errorMessage="Must be less than 250 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Select
          label="Company status"
          list={companyStatuses}
          selectedValue={company.isActive ? "active" : "inactive"}
          valueKey={"status"}
          iconKey="status"
          optionName={"status"}
          onChange={company.toggleStatus}
        />
      </Div>
      {!company.isCoreCompany && (
        <Div>
          <Select
            label="Company constraints"
            list={constraints}
            selectedValue={company.constraint.name}
            optionName={"name"}
            error={validationErrors.constraintId}
            errorMessage="Choose company constraint"
            onChange={company.toggleConstraints}
          />
        </Div>
      )}
    </Div>
    <Div direction="column" width="30%">
      <Div bottom={25}>
        <Text
          label="Address 1"
          value={company.address1}
          name="address1"
          error={validationErrors.address1}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Address 2"
          value={company.address2}
          name="address2"
          error={validationErrors.address2}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="City"
          value={company.city}
          name="city"
          error={validationErrors.city}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="State"
          value={company.state}
          name="state"
          error={validationErrors.state}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Country"
          value={company.country}
          name="country"
          error={validationErrors.country}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={company.change}
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="ZIP"
          value={company.zip}
          name="zip"
          error={validationErrors.zip}
          errorMessage="Must be more than 3 and less than 14 symbols."
          onChange={company.change}
        />
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    constraintsStore: rootStore.constraintsStore,
    rootStore
  })),
  observer
)(EditCompany);
