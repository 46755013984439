import styled from "styled-components";
import { Steps } from "antd";

const { Step } = Steps;

export const StyledStep = styled(Step)`
  .ant-steps-item-icon {
    position: relative !important;
    top: 8px !important;
    width: 32px !important;
    height: 32px !important;
    background-color: transparent !important;
    border: 2px solid ${({ theme }) => theme.mainDarkColor} !important;
  }

  .ant-steps-icon {
    top: 0 !important;
    left: 0 !important;
    display: flex;
    width: 28px !important;
    height: 28px !important;
    align-items: center !important;
    justify-content: center !important;
    color: ${({ theme }) => theme.accentColor} !important;
    font-weight: bold;
    line-height: 32 !important;
    background-color: #ffffff;
    border-radius: 50%;
  }

  .ant-steps-item-tail {
    margin-top: 8px !important;
    padding: 0 !important;
  }

  .ant-steps-item-tail::after {
    width: 2px !important;
    background-color: ${({ theme }) => theme.mainDarkColor} !important;
  }

  .ant-steps-item-content {
    padding-left: 48px !important;
    overflow: visible !important;
  }
`;

export const StyledDot = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.mainDarkColor};
  border-radius: 50%;
`;
