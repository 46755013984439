import { observable, action, computed } from "mobx";
import { computedFn } from "mobx-utils";
import keys from "lodash/keys";

class SubRoll {
  @observable selectedLot = "";
  @observable selectedLots = [];
  @observable selectedRoll = "";
  @observable selectedRolls = [];
  @observable selectedTag = "";
  @observable selectedTags = [];
  @observable selectedRange = {};

  constructor(subRoll, rootStore) {
    this.rootStore = rootStore;
    Object.assign(this, subRoll);
  }

  @computed
  get selectedTagsIds() {
    return this.selectedTags.map(({ id }) => id);
  }

  @computed
  get selectedRowKeys() {
    const { start, end, sequenceStart, sequenceEnd } = this.selectedRange;
    const { tagsByRoll } = this.rootStore.tagsStore;

    if (start && end) {
      return tagsByRoll.reduce((acc, t) => {
        const isStartFirstTag = sequenceStart < sequenceEnd;
        const startLessThenEnd =
          t.sequence >= sequenceStart && t.sequence <= sequenceEnd;
        const endLessThenStart =
          t.sequence >= sequenceEnd && t.sequence <= sequenceStart;

        if (isStartFirstTag ? startLessThenEnd : endLessThenStart) {
          acc = [...acc, t.id];
        }

        return acc;
      }, []);
    }
    return [start];
  }

  createData = computedFn(type => {
    const { id: lotId } = this.rootStore.lotsStore.lot;
    const {
      method: createMethod,
      url: createUrl
    } = this.rootStore.urls.subRolls.create;
    const {
      method: updateMethod,
      url: updateUrl
    } = this.rootStore.urls.subRolls.update;

    switch (type) {
      case "add-full-roll": {
        return {
          method: createMethod,
          url: createUrl,
          body: {
            lotId,
            rollIds: this.selectedRolls
          }
        };
      }
      case "add-range": {
        return {
          method: createMethod,
          url: createUrl,
          body: {
            lotId,
            ...this.selectedRange
          }
        };
      }
      case "add-one-each": {
        return {
          method: createMethod,
          url: createUrl,
          body: {
            lotId,
            tagIds: this.selectedTagsIds
          }
        };
      }
      case "move-from-lot": {
        return {
          method: updateMethod,
          url: updateUrl,
          body: {
            lotId,
            currentLotIds: this.selectedLots
          }
        };
      }

      default: {
        return {};
      }
    }
  });

  @action.bound onSelectRange(row) {
    const { start, end } = this.selectedRange;
    const isSelected = keys(this.selectedRange).some(
      key => this.selectedRange[key] === row.id
    );

    if (isSelected) return (this.selectedRange = {});

    if (start && end) return;

    this.selectedRange = start
      ? { ...this.selectedRange, end: row.id, sequenceEnd: row.sequence }
      : { start: row.id, sequenceStart: row.sequence };
  }

  @action.bound onSelectTag(row, maxTagsToSelect = 5) {
    const { id } = row;
    const maxTagsValue = this.selectedTags.length === maxTagsToSelect;
    const alreadySelected = this.selectedTags.some(item => item.id === id);
    this.selectedTag = id;

    if (maxTagsValue || alreadySelected) return;

    this.selectedTags = [...this.selectedTags, row];
  }

  @action.bound onDeleteSelectedTag(id) {
    this.selectedTags = this.selectedTags.filter(t => t.id !== id);
  }

  @action.bound onSelectRoll({ id }) {
    this.selectedRoll = id;
  }

  @action.bound onSelectRolls({ id: rollId }, maxAmount = 10) {
    const isSelected = this.selectedRolls.find(item => item === rollId);
    const selectedMax = this.selectedRolls.length === maxAmount;

    if (isSelected || selectedMax) {
      const filteredRolls = this.selectedRolls.filter(item => item !== rollId);
      return (this.selectedRolls = filteredRolls);
    }

    this.selectedRolls = [...this.selectedRolls, rollId];
  }

  @action.bound async onSelectLot({ id: lotId }, maxAmount = 10) {
    const isSelected = this.selectedLots.find(item => item === lotId);
    const selectedMax = this.selectedLots.length === maxAmount;

    if (isSelected || selectedMax) {
      const filteredLots = this.selectedLots.filter(item => item !== lotId);
      return (this.selectedLots = filteredLots);
    }

    this.selectedLots = [...this.selectedLots, lotId];
  }
}

export default SubRoll;
