import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { dateFormatter } from "utils/format";
import Div from "components/Div";
import P from "components/P";
import Icon from "components/Icon";
import DetailCard from "features/miscellany/DetailCard";
import LotSelect from "./LotSelect";

const LotDetailCard = ({ lot, onSelect }) => {
  const fields = [
    ["Description", lot.description],
    ["Status", lot.status],
    ["Experience", lot.experience?.name],
    ["Diversion", !lot.id ? "" : lot.interceptDiversion === true ? "on" : "off"],
    ["Anti-counterfeiting", !lot.id ? "" : lot.interceptCounterfeit === true ? "on" : "off"],
    ["Created", lot.createdAt && dateFormatter(lot.createdAt)]
  ];
  const stats = [
    ["nfc", "NFC Tags", lot.nfcTags],
    ["qrc", "QRC Tags", lot.qrcTags],
    ["lotDescriptionTotal", "Total Tags", lot.tags]
  ];

  const Banner = () => (
    <Div top={3} justify="space-around">
      {stats.map(([ico, k, v], i) => (
        <Div key={k + i} direction="column" align="center">
          <Icon width={19} height={19} icon={ico} />
          <P top={3} size={10} uppercase color="rgba(0,0,0,0.65)">
            {k}
          </P>
          <P size={12} weight="600" color="rgba(0,0,0,0.65)">
            {v}
          </P>
        </Div>
      ))}
    </Div>
  );

  return (
    <DetailCard
      header={onSelect && <LotSelect onLotSelect={onSelect} />}
      title={lot.name}
      fields={fields}
      banner={<Banner />}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(LotDetailCard);
