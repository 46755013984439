import styled from "styled-components";

export const NotificationBox = styled.div`
  font-size: 14px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.accentColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : 0)};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : "auto")};
`;
