import React from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";

const {
  REACT_APP_REDIRECT_AFTER_LOGIN,
  REACT_APP_REDIRECT_AFTER_MOBILE_LOGIN
} = process.env;

const ProtectedRoutes = ({ isMobile, authStore, children, ...restProps }) => {
  const history = useHistory();
  const isInitRoute = history.location.pathname === "/";

  if (!authStore.isAuthorized) return history.push("/login");

  if (isInitRoute)
    return history.push(
      isMobile
        ? REACT_APP_REDIRECT_AFTER_MOBILE_LOGIN
        : REACT_APP_REDIRECT_AFTER_LOGIN
    );

  return React.cloneElement(children, restProps);
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore
  })),
  observer
)(ProtectedRoutes);
