import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledTable } from "components/TableCheckboxes/Styled";
import { columns } from "./settings";

const SelectCloudEndpoints = ({
  selectedIds,
  onChange,
  rootStore: { cloudEndpointsStore, themeStore }
}) => {
  useEffect(() => {
    const fetchCloudEndpoints = async () => await cloudEndpointsStore.findAll();
    
    fetchCloudEndpoints();
    return cloudEndpointsStore.reset;
  }, [cloudEndpointsStore]);

  return (
    <StyledTable
      style={{ width: 950 }}
      bordered={true}
      size="middle"
      columns={columns}
      dataSource={cloudEndpointsStore.cloudEndpoints}
      rowKey="id"
      rowSelection={{ onChange, selectedRowKeys: selectedIds }}
      pagination={false}
      theme={themeStore.theme}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(SelectCloudEndpoints);
