import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "../P";
import Div from "../Div";
import Modal from "../Modal";
import Image from "../Image";

const Constraint = ({ themeStore, constraintsStore, ...restProps }) => {
  const [sentStatus, setSentStatus] = useState(false);

  const onContactMe = async () => {
    const response = await constraintsStore.onContactMe();

    if (response) {
      setSentStatus(true);
    }
  };

  return (
    <Modal
      width="500px"
      confirmButtonTitle={sentStatus ? "Ok" : "Contact me"}
      cancelButtonTitle="Dismiss"
      onConfirm={sentStatus ? constraintsStore.onDismiss : onContactMe}
      onCancel={sentStatus ? null : constraintsStore.onDismiss}
      {...restProps}
    >
      <Div direction="column" align="center">
        <Div justify="center" top={15} bottom={15}>
          <Image src={themeStore.theme.constraintModal.logo} height="50px" />
        </Div>
        <Div justify="center" top={25} bottom={35}>
          <Image src={themeStore.theme.constraintModal.rocket} height="150px" />
        </Div>
        {!sentStatus ? (
          <P
            size={21}
            textAlign="center"
            bottom={35}
            color={themeStore.theme.mainDarkColor}
          >
            Please contact your sales representative to access these features.
          </P>
        ) : null}
        {sentStatus ? (
          <P
            size={21}
            textAlign="center"
            bottom={35}
            color={themeStore.theme.mainDarkColor}
          >
            Thank you! Your request was successfully sent. We will contact you
            shortly.
          </P>
        ) : null}
      </Div>
    </Modal>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    constraintsStore: rootStore.constraintsStore,
  })),
  observer,
)(Constraint);
