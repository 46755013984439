import { dateFormatter } from "utils/format";

export const strategyColors = {
  inclusion: "#36d45d",
  exclusion: "#ed5353"
};

const containerStyle = `
  padding-bottom: 10px; 
  display:flex; 
  flex-direction:column; 
  align-items:center; 
  text-align: center; 
  width:210px;
`;

const label = (item) => {
  const {
    street, city, region,
    postalCode, country
  } = item;
  return `${street || ''} ${city || ''} ${region || ''} ${postalCode} ${country}`;
}

export const markerContent = (item) => {
  const { createdAt } = item;
  const when = dateFormatter(createdAt);
  return `
    <div style='${containerStyle}'>
      <strong>Point of Interaction</strong><br />
      ${when}<br />
      ${label(item)}<br />
    </div>
  `;
}
