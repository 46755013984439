import React from "react";
import { observer } from "mobx-react";

import { Button } from "components/actions";
import Div from "components/Div";
import P from "components/P";
import ErrorMessage from "components/ErrorMessage";
import MenuExperienceStepForm from "./forms/MenuExperienceStep";

const MenuSteps = ({ experience, errors }) => (
  <>
    {experience.steps.length === 0 && (
      <P bottom={25}>Press &quot;<strong>+ Button</strong>&quot; below to begin building your experience.</P>
    )}
    {experience.steps.map(
      (
        {
          buttonName,
          url,
          id,
          buttonIcon,
          changeButtonName,
          changeUrl,
          changeIcon,
          removeIcon
        },
        i
      ) => (
        <MenuExperienceStepForm
          key={i}
          experience={experience}
          errors={errors}
          url={url}
          index={i}
          buttonName={buttonName}
          stepId={id}
          buttonIcon={buttonIcon}
          onChangeButtonName={changeButtonName}
          onChangeUrl={changeUrl}
          onIconChange={changeIcon}
          onIconRemove={removeIcon}
        />
      )
    )}
    <Button type="purpleUnderlined" onClick={experience.addStep}>
      + Button
    </Button>
    <Div height="15px" top={20}>
      {!!errors && !errors.length && (
        <ErrorMessage
          bottom={0}
          left={0}
          text="At least one step should exist"
        />
      )}
    </Div>
  </>
);

export default observer(MenuSteps);
