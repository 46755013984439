import React from "react";
import { AutoComplete } from 'antd';

import SearchInput from "../inputs/Search";

const HistorySearch = ({search, searchHistory, placeholder, disabled, theme, onSearch}) => {
  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={true}
      style={{ width: 350 }}
      dataSource={searchHistory}
      onChange={(value) => {
        onSearch({ value })
      }}
      value={search}
    >
      <SearchInput
        value={search}
        placeholder={placeholder}
        disabled={disabled}
        theme={theme}
      />
    </AutoComplete>
  );
};

export default HistorySearch;
