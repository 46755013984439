import React from "react";
import { isMobile } from "react-device-detect";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import { Link } from "../actions";
import Icon from "../Icon";
import Div from "../Div";
import P from "../P";
import PoweredByLogo from "features/miscellany/PoweredByLogo";
import AboutModal from "features/miscellany/AboutModal";
import AppContextSelect from "./AppContextSelect";
import { StyledHeader, StyledHeaderMenuIcon, StyledHeaderLogo } from "./Styled";
import { HEADER_LOGO } from "../../constants"

const Header = ({
  company,
  companyName,
  isSuperAdmin,
  onCollapse,
  themeStore: { theme },
  constraintsStore,
  tagsStore,
  history
}) => {
  const constraint = constraintsStore.getConstraint("help");
  const [assetBundle] = company?.assetBundles || []
  const assets = assetBundle?.assets || []
  const headerAsset = assets.find((item) => item.name === HEADER_LOGO)

  return (
    <StyledHeader theme={theme}>
      <Div align="center">
        {!isMobile && <StyledHeaderMenuIcon onClick={onCollapse} />}
        <StyledHeaderLogo
          theme={theme}
          headerAsset={headerAsset}
          isSuperAdmin={isSuperAdmin}
          isWhiteLabelingEnabled={isFeatureEnabled('whiteLabeling') && company.isBrandingEnabled}
        />
        <P size={24} weight="bold" color="#ffffff" uppercase>
          {companyName}
        </P>
      </Div>
      <Div align="center">
        {!isFeatureEnabled("hidePoweredBy") && (
          <PoweredByLogo textSize={12} lineHeight={15} logoWidth={124} logoHeight={22} logoTheme="light" />
        )}
        {isSuperAdmin && <AppContextSelect />}
        <Div left={35} right={35}>
          <Link
            to={
              constraint.tagKit
                ? "http://help-gstk.dirx2.com?username=helpvisitor&pass=help"
                : "http://help.dirx2.com?username=helpvisitor&pass=help"
            }
            target="dirx2help"
            external
          >
            <Icon width="24" height="24" icon="question" />
          </Link>
        </Div>
        <Div>
          <AboutModal />
        </Div>
      </Div>
    </StyledHeader>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    constraintsStore: rootStore.constraintsStore,
    tagsStore: rootStore.tagsStore,
  })),
  observer
)(Header);
