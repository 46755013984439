import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { TextArea } from "components/inputs";
import Form from "components/Form";

const EditRoll = ({
  rollsStore: { roll },
  rootStore: { validationErrors }
}) => (
  <Form direction="row" width="90%">
    <Div direction="column" width="30%">
      <TextArea
        label="Roll description"
        name="description"
        value={roll.description}
        error={validationErrors.description}
        errorMessage="Must be less than 250 symbols"
        onChange={roll.change}
      />
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    rootStore
  })),
  observer
)(EditRoll);
