import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Form from "components/Form";
import Div from "components/Div";
import { Text, TextArea } from "components/inputs";

const EditTemplate = ({
  templatesStore: { template },
  rootStore: { validationErrors },
}) => (
  <Form width="100%" direction="column">
    <Div width="100%" bottom={25}>
      <Text
        label="Menu template name"
        value={template.name}
        error={validationErrors.name}
        errorMessage="Must be more than 1 and less than 50 symbols"
        onChange={template.changeUsingEvent("name")}
        required
      />
    </Div>
    <Div width="100%">
      <TextArea
        label="Description"
        value={template.description}
        error={validationErrors.description}
        errorMessage="Must be less than 250 symbols"
        onChange={template.changeUsingEvent("description")}
      />
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    templatesStore: rootStore.templatesStore,
    rootStore,
  })),
  observer
)(EditTemplate);
