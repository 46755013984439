import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledRow } from "./Styled";

const Row = ({
  className,
  columns,
  index,
  rowData,
  style,
  themeStore,
  onClick,
}) => (
  <StyledRow
    className={className}
    index={index}
    style={style}
    isSelected={rowData && rowData.selected}
    theme={themeStore.theme}
    onClick={rowData && onClick(rowData)}
  >
    {columns}
  </StyledRow>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Row);
