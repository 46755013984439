import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledButton } from "../Styled";

const Button = ({
  constraintsStore,
  themeStore,
  constraintGetter = "",
  type = "",
  disabled = false,
  children,
  isMobile,
  styles,
  onClick = () => null,
  ...rest
}) => {
  const { canActivate } = constraintsStore;

  const clickAction = (e) => {
    if (
      constraintGetter &&
      !canActivate({ getter: constraintGetter, target: "action" })
    )
      return;

    e.preventDefault();
    onClick(e);
  };

  return (
    <StyledButton
      type={type}
      disabled={disabled}
      isMobile={isMobile}
      style={styles}
      onClick={clickAction}
      theme={themeStore.theme}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    constraintsStore: rootStore.constraintsStore,
  })),
  observer
)(Button);
