import React from "react";

import { ColumnTitle } from "components/TableVirtualized";

export const rollsTableColumns = [
  {
    title: onSort => (
      <ColumnTitle
        title="Roll"
        sortingOptions={[
          {
            key: "desc",
            title: "The newest",
            dataKey: "roll.createdAt",
            condition: "desc",
          },
          {
            key: "asc",
            title: "The oldest",
            dataKey: "roll.createdAt",
            condition: "asc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "id",
    dataIndex: "id",
    width: 3
  },
  {
    title: <ColumnTitle title="Description" />,
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 4
  },
  {
    title: onSort => (
      <ColumnTitle
        title="Type"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "roll.tagType",
            condition: "asc",
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "roll.tagType",
            condition: "desc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tagType",
    dataIndex: "tagType",
    align: "center",
    width: 2
  },
  {
    title: onSort => (
      <ColumnTitle
        title="Tags"
        sortingOptions={[
          {
            key: "desc",
            title: "From highest",
            dataKey: "roll.tags",
            condition: "desc",
          },
          {
            key: "asc",
            title: "From lowest",
            dataKey: "roll.tags",
            condition: "asc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tags",
    dataIndex: "tags",
    align: "center",
    width: 2
  },
  {
    title: onSort => (
      <ColumnTitle
        sortingOptions={[
          {
            key: "selected",
            title: "Selected",
            dataKey: "selected",
            condition: "selected"
          },
          {
            key: "unselected",
            title: "Unselected",
            dataKey: "selected",
            condition: "unselected"
          }
        ]}
        onSort={onSort}
      />
    ),
    key: "selected",
    dataIndex: "selected",
    width: 1
  }
];

export const tagsTableColumns = [
  {
    title: <ColumnTitle title="Sequence" />,
    key: "sequence",
    dataIndex: "sequence",
    width: 1
  },
  {
    title: <ColumnTitle title="Tag" />,
    key: "id",
    dataIndex: "id",
    align: "center",
    width: 3
  },
  {
    title: <ColumnTitle title="Lot" />,
    key: "lotName",
    dataIndex: "lot.name",
    align: "center",
    width: 4
  },
  {
    key: "selected",
    dataIndex: "selected",
    width: 0.6
  }
];

export const useRollsFilterOptions = {
  dataKey: "tagType",
  items: [
    { key: "nfc", title: "NFC", isSelected: true },
    { key: "qrc", title: "QRC", isSelected: true }
  ]
};

export const rollsSearchOptions = [
  { key: "id", title: "Roll id", iconByType: "iconDefaultSearch" },
  {
    key: "description",
    title: "Roll description",
    iconByType: "iconDescriptionSearch"
  }
];

export const tagsSearchOptions = [
  { key: "id", title: "Tag id", iconByType: "iconDefaultSearch" }
];

export const summaryOptions = [
  { key: "id", title: "Roll ID:" },
  { key: "tags", title: "Tags:" },
  { key: "tagType", title: "Type:" },
  { key: "description", title: "Description:" }
];
