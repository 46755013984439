import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import {
  StyledModalWrapper,
  StyledModalInner,
  StyledModalHeader,
  StyledModalBody,
  StyledModalFooter,
  StyledCSSTransitionGroup
} from "./Styled";
import { openedModalBodyStyle } from "./settings";
import { Button } from "../actions";

const Modal = ({
  height,
  width,
  title = "",
  showModal,
  confirmButtonTitle = "Ok",
  confirmButtonProps = {},
  cancelButtonTitle = "Cancel",
  zIndex,
  constraint,
  onConfirm = null,
  onCross = null,
  onCancel = null,
  children,
  themeStore
}) => {
  useEffect(() => {
    const body = document.querySelector("body");

    if (showModal) {
      body.style.position = openedModalBodyStyle.position;
      body.style.width = openedModalBodyStyle.width;
      body.style.overflow = openedModalBodyStyle.overflow;
    }

    return () => {
      body.style.cssText = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const ModalComponent = (
    <StyledCSSTransitionGroup
      transitionName="modal-animation"
      transitionAppear={true}
      transitionAppearTimeout={200}
      transitionEnter={false}
      transitionLeave={false}
    >
      <StyledModalWrapper zIndex={zIndex} constraint={constraint}>
        <StyledModalInner width={width} height={height}>
          {(onCross || onCancel) && (
            <Button
              type="cross"
              onClick={onCross ? onCross : onCancel}
              styles={{ zIndex: 2 }}
            />
          )}
          {title ? (
            <StyledModalHeader theme={themeStore}>{title}</StyledModalHeader>
          ) : null}
          <StyledModalBody constraint={constraint}>{children}</StyledModalBody>
          <StyledModalFooter>
            {onCancel && (
              <Button type="regularFlat" onClick={onCancel}>
                {cancelButtonTitle}
              </Button>
            )}
            {onConfirm && (
              <Button
                type="regular"
                onClick={onConfirm}
                {...confirmButtonProps}
              >
                {confirmButtonTitle}
              </Button>
            )}
          </StyledModalFooter>
        </StyledModalInner>
      </StyledModalWrapper>
    </StyledCSSTransitionGroup>
  );

  return showModal ? ModalComponent : null;
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Modal);
