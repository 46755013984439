import styled from "styled-components";

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.6rem 2rem;
  color: ${({ isValid, theme }) =>
    isValid ? `${theme.mainDarkColor}` : `${theme.errorColor}`};
  line-height: 0;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ isValid, theme }) =>
    isValid ? `${theme.mainDarkColor}` : `${theme.errorColor}`};
  background: none;
  outline: none !important;
  transition: all 0.3s;

  &::placeholder {
    line-height: normal !important;
    color: ${({ isValid, theme }) =>
      isValid ? `${theme.mainDarkColor}` : `${theme.errorColor}`};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition-delay: 99999s;
    -webkit-transition-delay: 99999s;
  }
`;

const prefixIconCommonStyles = `
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 1rem;
  height: 1rem;
`;

export const StyledEmailPrefixIcon = styled.img.attrs(({ isValid, theme }) => ({
  src: isValid ? theme.icons.message : theme.icons.messageError,
}))`
  ${prefixIconCommonStyles}
`;

export const StyledPasswordPrefixIcon = styled.img.attrs(
  ({ isValid, theme }) => ({
    src: isValid ? theme.icons.lock : theme.icons.lockError,
  })
)`
  ${prefixIconCommonStyles}
`;

export const StyledPostfixIcon = styled.img.attrs(({ theme }) => ({
  src: theme.icons.attention,
}))`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: 1rem;
  height: 1rem;
  border-image-width: 0;
  border: 0;
`;

export const StyledWarningText = styled.p`
  position: absolute;
  top: 48%;
  left: 105%;
  display: ${({ isValid }) => (isValid ? "none" : "block")};
  transform: translate(0, -50%);
  color: ${({ theme }) => theme.errorColor};
  white-space: nowrap;
`;

