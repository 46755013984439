import { observable, action } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "id",
  "name",
  "status",
  "file",
];

class Asset extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable status = "";
  @observable file = "";
  @observable access = "";
  @observable createdAt = "";
  
  constructor(assetBundle, rootStore) {
    super(PATH_LIST_TO_OBSERVE, assetBundle);

    this.rootStore = rootStore;
    Object.assign(this, assetBundle);
  }

  @action.bound assign(property, value) {
    this[property] = value;
  }
};

export default Asset;
