import { message } from "utils/format";

export default error => {
  const { status, config, data } = error;
  const isLogin = config.url.includes("auth/login");
  const authErrorMsg = isLogin
    ? "Wrong email or password. Please, try again."
    : "Your session has expired. Please login again to continue working.";

  switch (status) {
    case 400: {
      return message(
        "Sorry, something went wrong. Please, try again.",
        "error"
      );
    }

    case 401: {
      return message(authErrorMsg, "error");
    }

    default: {
      message(data.message, "error");
    }
  }
};
