import { observable, computed, action } from "mobx";
import startCase from "lodash/startCase";

import Experience from "../models/Experience";

class Experiences {
  @observable rawMenuExperiences = [];
  @observable rawSequenceExperiences = [];
  @observable rawExperiences = [];
  @observable rawExperience = {};
  @observable params = {};
  @observable cloneName = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawExperience = new Experience({}, rootStore);
  }

  @computed
  get menuExperiences() {
    return this.rawMenuExperiences;
  }

  @computed
  get sequenceExperiences() {
    return this.rawSequenceExperiences;
  }

  @computed
  get experiences() {
    return this.rawExperiences;
  }

  @computed
  get experience() {
    return this.rawExperience;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawMenuExperiences = [];
    this.rawSequenceExperiences = [];
    this.rawExperiences = [];
    this.rawExperience = new Experience({}, this.rootStore);
    this.params = {};
    this.cloneName = "";
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value,
    };
  }

  @action save = (isEdit, type, isClone = false) => async () => {
    const { method, url } = isEdit
      ? this.rootStore.urls.experiences.update
      : this.rootStore.urls.experiences.create;
    let body = isEdit
      ? this.rawExperience[`update${startCase(type)}Data`]
      : this.rawExperience[`create${startCase(type)}Data`];

    if (this.cloneName) {
      body = { ...body, name: this.cloneName };
    }

    const errors = this.rootStore.validator[`validate${startCase(type)}`](body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body,
    });

    if (response && isClone) {
      await this.findAllByType(type);
      this.cloneName = "";
      this.rawExperience = new Experience({}, this.rootStore);
    }

    if (response && !isClone) {
      this.rootStore.routingStore.push(`/experiences/${type}`);
    }

    return response;
  };

  @action.bound async findAll(typesList = ["sequence", "menu"]) {
    const response = await Promise.all(
      typesList.map(t => this.findAllByType(t)),
    );

    const mergedData = response.reduce(
      (acc, res) => (!res ? acc : [...acc, ...res.data]),
      [],
    );

    this.rawExperiences = mergedData.map(
      m => new Experience(m, this.rootStore),
    );

    return mergedData;
  }

  @action.bound async findAllByType(type) {
    const { method, url } = this.rootStore.urls.experiences.getAll;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {
        ...this.params,
        ...{ type },
      },
    });

    if (response) {
      this[`raw${startCase(type)}Experiences`] = response.data.map(
        m => new Experience(m, this.rootStore),
      );
    }

    return response;
  }

  @action findById = ({ id, type }) => async () => {
    const { method, url } = this.rootStore.urls.experiences.getById;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
      params: {
        ...{ type },
      },
    });

    if (response) {
      this.rawExperience = new Experience(response.data, this.rootStore);
    }

    return response.data;
  };

  @action.bound deleteById = (id, type) => async () => {
    const { method, url } = this.rootStore.urls.experiences.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
      params: {
        ...{ type },
      },
    });

    if (response) {
      this[`raw${startCase(type)}Experiences`] = this[
        `raw${startCase(type)}Experiences`
      ].filter(e => e.id !== id);
    }

    return response;
  };

  @action.bound changeCloneName(event) {
    this.cloneName = event.target.value;
  }

  @action clone = type => async () => {
    return await this.save(false, type, true)();
  };
}

export default Experiences;
