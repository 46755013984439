import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "components/P";
import Image from "components/Image";
import Div from "components/Div";
import { IMAGES } from "./settings";

const TagManagement = ({
  title = "",
  description = "",
  imageType = "",
  themeStore
}) => {
  return (
    <Div align="center" direction="column" width="100%">
      <P textAlign="center" bottom={5} size={16} weight="bold">
        {title}
      </P>
      <P
        textAlign="center"
        bottom={20}
        size={12}
        color={themeStore.theme.mainDarkColor}
      >
        {description}
      </P>
      <Image height="6vh" src={IMAGES[imageType]} />
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(TagManagement);
