import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "../../Div";
import Label from "../Label";
import { StyledSwitch } from "./Styled";

const Switch = ({
  checked,
  size = "large",
  label,
  labelSize,
  labelColor = "#ffffff",
  themeStore,
  onClick = () => null,
}) => (
  <Div align="center">
    <Label
      text={label}
      color={labelColor}
      textSize={labelSize}
      horizontalLabel
    />
    <StyledSwitch
      theme={themeStore.theme}
      checked={checked}
      size={size}
      onClick={onClick}
    />
  </Div>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Switch);
