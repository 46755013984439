import React from "react";

import CopyToClipboard from "components/CopyToClipboard";

export const TAG_AUTH_SERVICE = {
  sic_cmac: "SIC CMAC",
  sic_tamper: "SIC Tamper",
  identiv_u_create: "Identiv uCreate",
};

export const assignedTagsTableColumns = [
  {
    title: "Tag",
    key: "tag-id",
    dataIndex: "id",
    align: "center",
    width: 325,
    render: row => <CopyToClipboard valueToCopy={row}>{row}</CopyToClipboard>
  },
  {
    title: "Roll",
    key: "roll-id",
    dataIndex: "roll.id",
    align: "center",
    width: 325
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "roll.tagType",
    align: "center"
  },
  {
    title: "Model",
    key: "model",
    dataIndex: "roll.tagModel",
    align: "center"
  },
  {
    title: "Authorization service",
    key: "auth-service",
    dataIndex: "roll.tagAuthService",
    align: "center",
    render(value) {
      return TAG_AUTH_SERVICE[value]
    }
  }
];
