import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "description",
  "address1",
  "address2",
  "city",
  "state",
  "country",
  "zip",
  "contactName",
  "contactEmail",
  "contactPhone",
  "isActive",
  "constraint"
];

class Company extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable description = "";
  @observable address1 = "";
  @observable address2 = "";
  @observable city = "";
  @observable state = "";
  @observable country = "";
  @observable zip = "";
  @observable contactName = "";
  @observable contactEmail = "";
  @observable contactPhone = "";
  @observable user = {};
  @observable isActive = true;
  @observable constraint = {};
  @observable isBrandingEnabled = false;
  isCoreCompany = false;

  constructor(company, rootStore) {
    super(PATH_LIST_TO_OBSERVE, company);

    this.rootStore = rootStore;
    Object.assign(this, company);
  }

  @computed
  get createData() {
    return toJS({
      name: this.name,
      description: this.description,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      constraintId: this.constraint.id,
      isBrandingEnabled: this.isBrandingEnabled
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      name: this.name,
      description: this.description,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.zip,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      isActive: this.isActive,
      constraintId: this.constraint.id,
      isBrandingEnabled: this.isBrandingEnabled,
    });
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }
  @action.bound async toggleBranding(isEnabled) {
    this.isBrandingEnabled = isEnabled === 'true';
  }

  @action.bound async toggleStatus() {
    this.isActive = !this.isActive;
  }

  @action.bound async toggleConstraints(id) {
    const constraint = this.rootStore.constraintsStore.constraints.find(
      c => c.id === id
    );
    this.constraint = constraint || {};
  }
}

export default Company;
