import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledRadio } from "./Styled";

const Radio = ({ title, checked, name = "", themeStore, onChange }) => {
  const change = () => onChange(name);

  return (
    <StyledRadio
      theme={themeStore.theme}
      checked={checked}
      name={name}
      onChange={change}
    >
      {title}
    </StyledRadio>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Radio);
