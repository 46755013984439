import React from "react";

import { ColumnTitle } from "components/TableVirtualized";

export const tableColumns = [
  {
    title: <ColumnTitle title="Tag" />,
    key: "id",
    dataIndex: "id",
    width: 3,
  },
  {
    title: <ColumnTitle title="Roll" />,
    key: "rollId",
    dataIndex: "roll.id",
    align: "center",
    width: 3,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        title="Type"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "roll.tagType",
            condition: "asc",
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "roll.tagType",
            condition: "desc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "tagType",
    dataIndex: "roll.tagType",
    align: "center",
    width: 1,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        title="Lot"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "lot.name",
            condition: "asc",
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "lot.name",
            condition: "desc",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "lotName",
    dataIndex: "lot.name",
    align: "center",
    width: 3,
  },
  {
    title: (onSort) => (
      <ColumnTitle
        sortingOptions={[
          {
            key: "selected",
            title: "Selected",
            dataKey: "selected",
            condition: "selected",
          },
          {
            key: "unselected",
            title: "Unselected",
            dataKey: "selected",
            condition: "unselected",
          },
        ]}
        onSort={onSort}
      />
    ),
    key: "selected",
    dataIndex: "selected",
    width: 1,
  },
];

export const useFilterOptions = {
  dataKey: "roll.tagType",
  items: [
    { key: "nfc", title: "NFC", isSelected: true },
    { key: "qrc", title: "QRC", isSelected: true },
  ],
};

export const useSortOptions = {
  'roll.tagType': "type",
  'lot.name': "name",
  'roll.tags': "tags",
  'roll.createdAt': "createdAt",
  'lot.tags': "tags",
};

export const searchOptions = [
  { key: "id", title: "Tag id", iconByType: "iconDefaultSearch" },
];
