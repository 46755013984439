import { observable, action } from "mobx";

class Activations {
  @observable activations = [];
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.activations = [];
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  getPaginationParams(paginationConfig) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return paginationParams || {};
  }

  @action.bound onNextPage = params => paginationOptions =>
      this.findAll(params, paginationOptions);

  @action.bound async findAll(params, paginationOptions) {
    params = params || this.params;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const { method, url } = this.rootStore.urls.analytics.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {...params, ...paginationParams}
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      if (paginationOptions?.isScrollPagination) {
        this.activations = [...this.activations, ...rows];
      } else {
        this.activations = [...rows];
      }

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }
    }
    return response;
  }
}

export default Activations;
