const getImageDimensionsFromFile = file =>
  new Promise(function(resolve) {
    let image = new Image();

    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      });
    };

    image.src = window.URL.createObjectURL(file);
  });

export const checkThatImageHeightCorrect = async (file, maxHeight) => {
  const imageDimensions = await getImageDimensionsFromFile(file);
  return !(imageDimensions.height > maxHeight);
};
