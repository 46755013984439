import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Header } from "components/Content";
import { Tabs, Tab } from "components/Tabs";
import ActivationDashboard from "features/analytics/ActivationDashboard";
import TagDetailCard from "features/tags/TagDetailCard";

const Optics = ({ rootStore: { tracesStore, tagsStore }, match }) => {
  const { tagId } = match.params;
  const [tag, setTag] = useState({ lot: {} });
  const [tab, setTab] = useState("track-trace");

  useEffect(() => {
    const fetchTag = async () => {
      const data = await tagsStore.findById({ id: tagId });
      setTag(data);
    };

    fetchTag();
  }, [tagsStore, tagId]);

  return (
    <>
      <Header title="Tag Optics" backButton backButtonReturn />
      <Tabs activeKey={tab} onChange={setTab}>
        <Tab tab="Track & Trace" key="track-trace">
          <ActivationDashboard
            lot={tag.lot}
            tagId={tag.id}
            detailListView="timeline"
            detailCard={<TagDetailCard tag={tag} />}
            store={tracesStore}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Optics);
