import React from "react";

import { Link } from "components/actions";

export const templatesTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 400,
    render: (_, { id, name, isDefault, isTagKit }) =>
      isDefault || isTagKit ? (
        name
      ) : (
        <Link type="tableLink" to={`/templates/edit/${id}`}>
          {name}
        </Link>
      )
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center"
  },
  {
    title: "Experiences",
    key: "experiences",
    dataIndex: "experiences",
    align: "center",
    width: 300
  }
];
