import { observable, action, computed, toJS } from "mobx";
import { set, get, isEmpty } from "lodash";

import { filesToFormData } from "utils/format";
import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "description",
  "config.header.disabled",
  "config.header.height",
  "config.header.gridTemplateRows",
  "config.header.text.value",
  "config.header.text.color",
  "config.header.text.fontFamily",
  "config.header.text.fontWeight",
  "config.header.text.fontSize",
  "config.header.text.letterSpacing",
  "config.header.text.alignment",
  "config.header.image.src",
  "config.header.image.name",
  "config.header.image.backgroundHorizontal",
  "config.header.image.backgroundVertical",
  "config.header.image.backgroundSize",
  "config.header.image.order",
  "config.header.background.color",
  "config.header.background.image.src",
  "config.header.background.image.name",
  "config.header.text.order",
  "config.header.text.fontSizeDimension",
  "config.header.text.letterSpacingDimension",
  "config.header.text.verticalAlignment",
  "config.background.disabled",
  "config.background.color",
  "config.background.image.src",
  "config.background.image.name",
  "config.button.borderRadius",
  "config.button.backgroundColor",
  "config.button.borderColor",
  "config.button.textColor",
  "config.button.fontFamily",
  "config.button.fontWeight",
  "config.button.fontSize",
  "config.button.letterSpacing",
  "config.button.alignment",
  "config.button.fontSizeDimension",
  "config.button.letterSpacingDimension",
  "config.button.icons",
  "config.footer.disabled",
  "config.footer.height",
  "config.footer.gridTemplateRows",
  "config.footer.text.value",
  "config.footer.text.color",
  "config.footer.text.fontFamily",
  "config.footer.text.fontWeight",
  "config.footer.text.fontSize",
  "config.footer.text.letterSpacing",
  "config.footer.text.alignment",
  "config.footer.text.verticalAlignment",
  "config.footer.image.src",
  "config.footer.image.name",
  "config.footer.image.backgroundHorizontal",
  "config.footer.image.backgroundVertical",
  "config.footer.image.backgroundSize",
  "config.footer.image.order",
  "config.footer.background.color",
  "config.footer.background.image.src",
  "config.footer.background.image.name",
  "config.footer.text.order",
  "config.footer.text.fontSizeDimension",
  "config.footer.text.letterSpacingDimension",
  "config.banner.disabled",
  "config.banner.image.src",
  "config.banner.image.name",
  "config.banner.image.backgroundSize",
  "config.banner.url",
  "config.body.disabled",
  "config.body.color",
  "config.body.image.src",
  "config.body.image.name",
];

class Template extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable description = "";
  @observable config = {
    banner: {
      disabled: true,
      image: {
        src: "",
        name: "",
        backgroundSize: "contain",
      },
      url: "https://",
    },
    header: {
      disabled: false,
      height: "1fr",
      gridTemplateRows: "1fr auto",
      background: {
        color: "",
        image: {
          src: "",
          name: "",
        },
      },
      text: {
        order: 2,
        value: "",
        color: "#000000",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "14",
        fontSizeDimension: "px",
        letterSpacing: "1",
        letterSpacingDimension: "px",
        alignment: "left",
        verticalAlignment: "start",
      },
      image: {
        src: "",
        name: "",
        backgroundHorizontal: "50%",
        backgroundVertical: "50%",
        backgroundSize: "contain",
        order: 1,
      },
    },
    button: {
      borderRadius: "rounded",
      backgroundColor: "#000000",
      borderColor: "transparent",
      textColor: "#ffffff",
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "14",
      fontSizeDimension: "px",
      letterSpacing: "1",
      letterSpacingDimension: "px",
      alignment: "flex-start",
      columnLayout: "one",
      order: 0,
      icons: [],
    },
    footer: {
      disabled: true,
      height: "1fr",
      gridTemplateRows: "1fr auto",
      background: {
        color: "",
        image: {
          src: "",
          name: "",
        },
      },
      text: {
        order: 2,
        value: "",
        color: "#000000",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "14",
        fontSizeDimension: "px",
        letterSpacing: "1",
        letterSpacingDimension: "px",
        alignment: "left",
        verticalAlignment: "start",
      },
      image: {
        name: "",
        src: "",
        backgroundHorizontal: "50%",
        backgroundVertical: "50%",
        backgroundSize: "contain",
        order: 1,
      },
    },
    body: {
      disabled: false,
      color: "",
      image: {
        src: "",
        name: "",
      },
    },
    background: {
      disabled: false,
      color: "",
      image: {
        src: "",
        name: "",
      },
    },
  };
  @observable previewUrl = "";

  constructor(rawTemplate, rootStore) {
    super(PATH_LIST_TO_OBSERVE, rawTemplate);

    this.rootStore = rootStore;

    const template = !isEmpty(rawTemplate)
      ? {
          ...rawTemplate,
          config: {
            ...rawTemplate.config,
            banner: {
              ...rawTemplate.config.banner,
              url: rawTemplate.config.banner.url || "https://",
            },
          },
        }
      : rawTemplate;

    Object.assign(this, template);
  }

  @action.bound showPreview = async () => {
    const { method, url } = this.rootStore.urls.templates.createPreview;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: { config: this.config },
    });

    this.previewUrl = `${
      process.env.REACT_APP_MENU_PREVIEW_HOST
    }?templatePreviewId=${response ? response.data : ""}`;
  };

  @computed
  get createData() {
    return toJS({
      name: this.name,
      description: this.description,
      config: this.config,
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      name: this.name,
      description: this.description,
      config: this.config,
    });
  }

  async uploadImage(target) {
    const fileName = target.files[0].name;
    const { method, url } = this.rootStore.urls.uploadFile;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: filesToFormData(target, ["file"]),
    });

    if (response) return { url: response.data.url, fileName };
  }

  @action.bound assign(property, path, value) {
    if (path) {
      set(this.config, path, value);
    } else {
      this[property] = value;
    }

    this.showPreview();
  }

  @action changeUsingEvent = (property, path) => event => {
    const { value } = event.target;
    this.assign(property, path, value);
  };

  @action changeUsingData = (property, path) => data => {
    this.assign(property, path, data);
  };

  @action changeColor = path => data => {
    this.assign(
      "config",
      path,
      `rgba(${data.rgb.r},${data.rgb.g},${data.rgb.b},${data.rgb.a})`,
    );
  };

  @action upload = path => async event => {
    const response = await this.uploadImage(event.target);

    if (response) {
      this.assign("config", `${path}.src`, response.url);
      this.assign("config", `${path}.name`, response.fileName);
    }
  };

  @action uploadMany = path => async event => {
    const response = await this.uploadImage(event.target);

    if (response) {
      const newImage = {
        src: response.url,
        name: response.fileName,
      };
      const imageList = get(this.config, path, []);
      imageList.push(newImage);
      this.assign("config", `${path}`, imageList);
    }
  };

  @action removeImage = (path, target) => () => {
    this.assign("config", `${path}.src`, "");
    this.assign("config", `${path}.name`, "");

    if ((path === "header.image" || path === "footer.image") && target) {
      this.config[target].image.backgroundSize = "auto";
    }
  };

  @action removeFromList = path => index => () => {
    const imageList = get(this.config, path, []);
    const newList = imageList.filter((_, i) => i !== index);
    this.assign("config", `${path}`, newList);
  };

  @action setImageSize = target => () => {
    const currentValue = this.config[target].image.backgroundSize;

    if (currentValue === "auto") {
      this.config[target].image.backgroundSize = "contain";
    } else {
      this.config[target].image.backgroundSize = "auto";
    }

    this.showPreview();
  };
}

export default Template;
