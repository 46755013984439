import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Tabs, Tab } from "components/Tabs";
import { useTabs } from "components/hooks";
import LotDetailCard from "features/lots/LotDetailCard";
import TableauDashboard from "features/analytics/TableauDashboard";
/*
import ActivationDashboard from "features/analytics/ActivationDashboard";
import InterceptorDashboard from "features/analytics/InterceptorDashboard";
*/

const Analytics = ({
  rootStore: { activationsStore, diversionsStore, counterfeitsStore }
}) => {
  const [tab, setTab] = useTabs("#summary");
  /*
  const [lotActivation, setActivationLot] = useState({});
  const [lotDiversion, setDiversionLot] = useState({});
  const [lotCounterfeit, setCounterfeitLot] = useState({});
  */
 
  return (
    <Tabs activeKey={tab} onChange={setTab}>
      <Tab tab="Summary" key="#summary">
        <TableauDashboard dataKey="summary" />
      </Tab>
      {/*
      <Tab tab="Activations" key="#activations">
        <ActivationDashboard
          lot={lotActivation}
          detailCard={
            <LotDetailCard lot={lotActivation} onSelect={setActivationLot} />
          }
          store={activationsStore}
        />
      </Tab>
      <Tab tab="Diversions" key="#diversions">
        <InterceptorDashboard
          type="diversion"
          lot={lotDiversion}
          detailCard={
            <LotDetailCard lot={lotDiversion} onSelect={setDiversionLot} />
          }
          interceptsStore={diversionsStore}
        />
      </Tab>
      <Tab tab="Counterfeits" key="#counterfeits">
        <InterceptorDashboard
          type="counterfeit"
          lot={lotCounterfeit}
          detailCard={
            <LotDetailCard lot={lotCounterfeit} onSelect={setCounterfeitLot} />
          }
          interceptsStore={counterfeitsStore}
        />
      </Tab>
    */}
    </Tabs>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Analytics);
