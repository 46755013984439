import React from "react";

import { SearchWithSelect } from "components/inputs";
import Form from "components/Form";
import Filter from "components/Filter";

const FullRoll = ({
  data,
  filterOptions,
  searchPopupOptions,
  onFilterChange,
  onSearchSelect,
  onSearch
}) => (
  <Form
    width="100%"
    align="center"
    justify="flex-start"
    paddingLeft={15}
    paddingRight={15}
    bottom={15}
    gap={10}
  >
    <Filter options={filterOptions} onChange={onFilterChange} />
    <SearchWithSelect
      onSearch={onSearch}
      data={data}
      dataKeys={searchPopupOptions.map(({ key }) => key)}
      resultsPopupOptions={searchPopupOptions}
      placeholder="Search by roll id or description"
      onSelect={onSearchSelect}
    />
  </Form>
);

export default FullRoll;
