import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { ColorPicker, ImageUpload } from "components/inputs";
import { StyledAccordionPanelWrapper } from "./Styled";

const TemplateDeviceSection = ({ template, themeStore }) => (
  <StyledAccordionPanelWrapper>
    <Div bottom={28}>
      <ImageUpload
        label="Upload device background image"
        labelColor={themeStore.theme.mainDarkColor}
        darkIcon
        fileName={template.config.background.image.name}
        fileSrc={template.config.background.image.src}
        onUpload={template.upload("background.image")}
        onRemove={template.removeImage("background.image")}
      />
    </Div>
    <ColorPicker
      label="Choose device background color"
      labelTextSize={14}
      horizontalLabel
      color={template.config.background.color}
      onChange={template.changeColor("background.color")}
    />
  </StyledAccordionPanelWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(TemplateDeviceSection);
