import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledLink, StyledExternalLink } from "../Styled";

const Link = ({
  type = "",
  to = "",
  external = false,
  target,
  styles,
  themeStore,
  children,
  onClick,
}) => {
  const Link = external ? StyledExternalLink : StyledLink;

  return (
    <Link
      onClick={onClick}
      to={to}
      target={target}
      type={type}
      style={styles}
      theme={themeStore.theme}
    >
      {children}
    </Link>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    constraintsStore: rootStore.constraintsStore,
  })),
  observer,
)(Link);