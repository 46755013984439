import React from "react";
import { Redirect, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";

const {
  REACT_APP_REDIRECT_AFTER_LOGIN,
  REACT_APP_REDIRECT_AFTER_MOBILE_LOGIN
} = process.env;

const AuthRoute = ({ isMobile, authStore, ...restProps }) => {
  const { component: Page, ...routeProps } = restProps;

  return (
    <Route
      {...routeProps}
      render={props =>
        !authStore.isAuthorized ? (
          <Page {...props} />
        ) : (
          <Redirect
            to={
              isMobile
                ? REACT_APP_REDIRECT_AFTER_MOBILE_LOGIN
                : REACT_APP_REDIRECT_AFTER_LOGIN
            }
          />
        )
      }
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore
  })),
  observer
)(AuthRoute);
