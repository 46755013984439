import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import { Button } from "components/actions";
import SequenceExperienceStepForm from "../forms/SequenceExperienceStep";

const Step = ({
  step,
  menuExperiences,
  error,
  onStepDelete,
  themeStore: { theme }
}) => (
  <Div direction="column" right={25} bottom={15}>
    <Div align="center">
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100px",
          height: "100%",
          borderRadius: theme.borderRadius,
          border: `1px solid ${theme.secondaryLightColor}`, 
          background: `${theme.secondaryLightColor}`,
          opacity: 0.4
        }}
      />
      <div style={{ zIndex: 1 }}>
        <P width="6ch" textAlign="center">
          {!step.isMenuLink ? "URL" : "Menu"}
        </P>
      </div>
      <SequenceExperienceStepForm
        step={step}
        error={error}
        menuExperiences={menuExperiences}
      />
      <Div align="center" right={15}>
        <Button type="preview" onClick={step.showPreview} />
      </Div>
      <Button type="bin" onClick={onStepDelete} />
    </Div>
  </Div>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Step);
