import React from "react";
import { inject, observer } from "mobx-react";
import { Empty } from "antd";

import { compose } from "utils/hoc";
import { dateFormatter } from "utils/format";
import P from "components/P";
import Div from "components/Div";
import {Segment, Panel, Point, Label, StyledGrid} from "./Styled";

const Timeline = ({
  data = [],
  idKey = "id",
  dateKey = "createdAt",
  title = "Timeline",
  renderActions,
  onScroll,
  themeStore: { theme },
  scrollToColumn,
  activeActivation
}) => {
  const cellRenderer = ({ columnIndex, style, key }) => {
    const i = columnIndex;
    const o = data[i];

    const configCounterfeit = o?.event?.configCounterfeit || {}
    const configDiversion = o?.event?.configDiversion || {}

    const isDiversionEnabled = configDiversion.enabled
    const isCounterfeitEnabled = configCounterfeit.enabled

    return (
      <div key={o[idKey] + i} theme={theme} style={style}>
        <Panel theme={theme}>
          {i % 2 === 0 && (
            <Label title={dateFormatter(o[dateKey])} theme={theme} />
          )}
        </Panel>
        <Segment i={i} n={data.length} theme={theme} />
        <Panel theme={theme}>
          {i % 2 === 1 && (
            <Label title={dateFormatter(o[dateKey])} theme={theme} />
          )}
        </Panel>
        <Point
          isDiversionEnabled={isDiversionEnabled}
          isCounterfeitEnabled={isCounterfeitEnabled}
          isActive={activeActivation === o.id}
          counterfeited={o?.event?.counterfeit}
          diverted={o?.event?.diversion}
          i={i}
          n={data.length}
          renderActions={() => renderActions(o)}
          theme={theme}
        />
      </div>
    );
  };

  return (
    <Div width="100%" direction="column">
      <Div
        justify="center"
        styles={{
          width: "100%",
          padding: "15px",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: theme.secondaryLightColor,
        }}
      >
        <P uppercase weight="bold">
          {title}
        </P>
      </Div>
      <Div
        justify="center"
        align="center"
        styles={{
          width: "100%",
          minHeight: "168px",
          borderBottom: "1px solid",
          display: 'block',
          borderColor: theme.secondaryLightColor,
          overflowY: "hidden"
        }}
      >
        {!data || !data.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            <StyledGrid
              scrollToColumn={scrollToColumn}
              onScroll={(data) => {
                onScroll(data)
              }}
              isScrollingOptOut
              height={168}
              columnCount={data.length}
              columnWidth={160}
              rowCount={1}
              rowHeight={168}
              cellRenderer={cellRenderer}
              width={1260}
            />
          </>
        )}
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(Timeline);
