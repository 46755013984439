import React from "react";

import { Link } from "components/actions";
import { ColumnTitle } from "components/TableVirtualized";
import LotName from "features/lots/LotName";

export const lotListTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 250,
    render: (_, { id, name, status }) => (
      <LotName status={status}>
        <Link type="tableLink" to={`/lots/edit/${id}`}>
          {name}
        </Link>
      </LotName>
    )
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 250,
  },
  {
    title: "Experience",
    key: "experience",
    dataIndex: "experience.name",
    align: "center",
    width: 300
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    align: "center",
    width: 100
  }
];

export const experienceTableColumns = [
  {
    title: onSort => (
      <ColumnTitle
        title="Name"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "name",
            condition: "asc"
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "name",
            condition: "desc"
          }
        ]}
        onSort={onSort}
      />
    ),
    key: "name",
    dataIndex: "name",
    width: 5
  },
  {
    title: onSort => (
      <ColumnTitle
        title="Type"
        sortingOptions={[
          {
            key: "asc",
            title: "A - Z",
            dataKey: "type.name",
            condition: "asc"
          },
          {
            key: "desc",
            title: "Z - A",
            dataKey: "type.name",
            condition: "desc"
          }
        ]}
        onSort={onSort}
      />
    ),
    key: "type",
    dataIndex: "type.name",
    align: "center",
    width: 2
  },
  {
    title: "",
    key: "selected",
    dataIndex: "selected",
    width: 1
  }
];

export const lotStatuses = [
  {
    id: "1",
    status: "active"
  },
  {
    id: "2",
    status: "inactive"
  }
];

export const useFilterOptions = {
  dataKey: "type.name",
  items: [
    { key: "menu", title: "Menu", isSelected: true },
    { key: "sequence", title: "Sequence", isSelected: true }
  ]
};

export const searchOptions = [
  { key: "name", title: "Experience name", iconByProperty: "type.name" }
];
