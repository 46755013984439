import React from "react";

import MenuIcon from "../MenuIcon";
import MenuItemTitle from "../MenuItemTitle";

const SubMenuTitle = ({ title, icon, iconWidth = 16, iconHeight = 16 }) => (
  <span>
    <MenuIcon icon={icon} iconWidth={iconWidth} iconHeight={iconHeight} />
    <MenuItemTitle title={title} />
  </span>
);

export default SubMenuTitle;
