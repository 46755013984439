import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";

const DetailCard = ({
  title,
  small = false,
  banner,
  header,
  fields,
  footer,
  themeStore: { theme }
}) => {
  return (
    <Div
      direction="column"
      styles={{
        height: "350px",
        minWidth: "300px",
        padding: "15px",
        border: "1px solid",
        borderColor: theme.secondaryLightColor,
        borderRadius: theme.borderRadius,
        backgroundColor: theme.mainLighterColor,
        overflowY: "hidden"
      }}
    >
      {header ? (
        header
      ) : (
        <Div
          direction="column"
          justify="center"
          align="center"
          styles={{
            width: "100%",
            minHeight: "30px",
            backgroundColor: "#fff",
            padding: "8px 11px",
            borderRadius: theme.borderRadius,
            border: "1px solid #d9d9d9"
          }}
        >
          <P size={small ? 12 : 14} color="rgba(0,0,0,0.65)">
            {title}
          </P>
        </Div>
      )}
      <Div direction="column" gap={6} top={10}>
        {banner && banner}
        {fields
          .filter(([, v]) => v !== "")
          .map(([k, v], i) => (
            <Div key={k + i} direction="column">
              <P size={10} uppercase color="rgba(0,0,0,0.65)">
                {k}
              </P>
              <P size={12} weight="600" color="rgba(0,0,0,0.65)">
                {v}
              </P>
            </Div>
          ))}
        {footer && footer}
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(DetailCard);
