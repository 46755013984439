import React from "react";

import { Link } from "components/actions";

export const tableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 400,
    render: (_, { id, name }) => (
      <Link type="tableLink" to={`/experiences/menu/edit/${id}`}>
        {name}
      </Link>
    )
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center"
  },
  {
    title: "Template",
    key: "template",
    dataIndex: "template.name",
    align: "center",
    width: 300
  }
];
