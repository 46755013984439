import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { compose } from "utils/hoc";
import { Header, Inner } from "components/Content";
import Table from "components/Table";
import { Button, Link } from "components/actions";
import Modal from "components/Modal";
import { useModal, useModalWithValidation } from "components/hooks";
import P from "components/P";
import Div from "components/Div";
import CloneForm from "features/miscellany/forms/Clone";
import { templatesTableColumns } from "./settings";

const List = ({ templatesStore }) => {
  const {
    rootStore,
    templates,
    template,
    findAll,
    reset,
    findById,
    deleteById,
    clone,
    changeParams,
    params: { search },
  } = templatesStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel,
  ] = useModal();
  const [
    isCloneModalShown,
    setCloneModalShown,
    onCloneModalOk,
    onCloneModalCancel,
  ] = useModalWithValidation(rootStore);

  const delayedQuery = useRef(debounce(findAll, 500)).current;

  useEffect(() => {
    findAll();
    return reset;
  }, [findAll, reset]);

  const onSearch = ({ key, value }) => {
    changeParams({ key, value });
    delayedQuery();
  };

  const renderActions = row => (
    <>
      <Button
        constraintGetter="templates.clone"
        type="popoverRegular"
        onClick={setCloneModalShown(findById({ id: row.id }))}
      >
        Clone
      </Button>
      {!row.isDefault ? (
        <Link type="popoverRegular" to={`/templates/edit/${row.id}`}>
          Edit
        </Link>
      ) : null}
      {!row.isDefault ? (
        <Button
          constraintGetter="templates.delete"
          type="popoverRedText"
          onClick={setDeleteModalShown(findById({ id: row.id }))}
        >
          Delete
        </Button>
      ) : null}
    </>
  );

  return (
    <>
      <Modal
        title="Delete template"
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel()}
        onConfirm={onDeleteModalOk(deleteById(template.id))}
      >
        {`Are you sure you want to delete ${template.name} template?`}
      </Modal>
      <Modal
        title={
          <Div direction="column" align="center">
            <P bottom={10} size={18} textAlign="center">
              {`You are going to clone ${template?.name} template.`}
            </P>
            <P size={18}>Please, add a name for the cloned template.</P>
          </Div>
        }
        showModal={isCloneModalShown}
        confirmButtonTitle="Clone"
        onCancel={onCloneModalCancel()}
        onConfirm={onCloneModalOk(clone)}
      >
        <CloneForm storeName="templates" />
      </Modal>
      <Header
        title="All Templates"
        searchPlaceholder="Search by name"
        actionButtons={[
          <Link type="regular" key="create-btn" to="/templates/create">
            Create Template
          </Link>,
        ]}
        withSearch
        searchDisabled={false} //todo: remake when backend send count of items
        search={search}
        onSearch={onSearch}
      />
      <Inner>
        <Table
          columns={templatesTableColumns}
          data={templates}
          renderActions={renderActions}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    templatesStore: rootStore.templatesStore,
  })),
  observer,
)(List);
