import styled from "styled-components";
import { StyledTable } from "components/TableCheckboxes/Styled";

export const SlidingTable = styled(StyledTable)`
  position: absolute;

  &.slideLeft {
    animation-duration: 175ms;
    animation-name: slideleft;
    animation-timing-function: ease-out;

    @keyframes slideleft {
      from { left: 100% }  
      to { left: 0% }
    }
  }

  &.slideRight {
    animation-duration: 175ms;
    animation-name: slideright;
    animation-timing-function: ease-out;

    @keyframes slideright {
      from { left: -100% }  
      to { left: 0% }
    }
  }

  .ant-table-thead > tr > th.ant-table-selection-column, 
  .ant-table-tbody > tr > td.ant-table-selection-column {
    border-right: 1px solid #e8e8e8;
  }
}
`;

export const IconEffect = styled.div`
  display: flex;
  height: 21px;
  width: 21px;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;

  &:hover {
    opacity: 1;
    transition: opacity 175ms ease-in;
  }
`;
