import MetadataItem from "./MetadataItem";
import {action, computed, observable, toJS} from "mobx";
import uuid from "uuid/v4";
class Tag {
  @observable id = "";
  @observable assetBundles = [];
  @observable metadata = {};
  @observable metadataArray = [];
  @observable rawMetadataItem = {};
  constructor(tag, rootStore) {
    this.rootStore = rootStore;
    Object.assign(this, tag);

    const tagMetadata = tag.metadata;

    if (tagMetadata) {
      const newMetadata = [];

      Object.keys(tagMetadata).map(key => {
        return newMetadata.push({
          id: uuid(),
          name: key,
          value: tagMetadata[key]
        });
      });

      this.metadataArray = newMetadata;
    }

    this.rawMetadataItem = new MetadataItem({}, rootStore);
  }

  @computed
  get metadataItem() {
    return this.rawMetadataItem;
  }

  @action.bound resetMetadata() {
    this.rawMetadataItem = new MetadataItem({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @computed
  get updateData() {

    return toJS({
      metadata: this.metadata,
    });
  }

  @action addMetadata = async () => {
    const body = {
      name: this.rawMetadataItem.name,
      value: this.rawMetadataItem.value
    };

    const errors = await this.rootStore.validator.validateMetadata({
      name: this.rawMetadataItem.name,
      value: this.rawMetadataItem.value,
      metadata: this.metadata
    });

    if (this.rootStore.hasValidationErrors(errors)) return;

    this.resetMetadata();
    this.metadata = { ...this.metadata, [body.name]: body.value };

    return this.metadataArray.push(
        new MetadataItem({ ...body, id: uuid() }, this.rootStore)
    );
  };

  @action deleteMetadataById = id => {
    const deleteItemFromMetadata = () =>
        this.metadataArray.filter(m => m.id !== id);
    this.metadataArray = deleteItemFromMetadata();

    let newMetadata = {};
    this.metadataArray.map(item => {
      return newMetadata = { ...newMetadata, [item.name]: item.value };
    });

    this.metadata = newMetadata;
  };
}

export default Tag;
