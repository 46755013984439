import styled from "styled-components";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const StyledTabs = styled(Tabs)`
  height: 100% !important;

  .ant-tabs-nav-container {
    padding: 0 20px !important;
  }

  .ant-tabs-content.ant-tabs-top-content {
    height: 100% !important;
    padding: 0 20px !important;
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    margin-bottom: 30px !important;
    border-bottom: none !important;
    box-shadow: 0 3px 6px #00000029 !important;
  }

  .ant-tabs-tab {
    user-select: none;
    min-width: 180px !important;
    padding: 22px 15px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    color: ${({ theme }) => theme.mainDarkColor} !important;
    text-align: center !important;

    &:hover {
      color: ${({ theme }) => theme.accentColor} !important;
    }
  }

  .ant-tabs-tab-active.ant-tabs-tab {
    color: ${({ theme }) => theme.accentColor} !important;

    &:hover {
      color: ${({ theme }) => theme.accentColor} !important;
    }
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-no-animated {
    height: 7px !important;
    background-color: ${({ theme }) => theme.accentColor} !important;
  }
`;

export const StyledTab = styled(TabPane)`
  width: 100% !important;
  height: ${({ height }) => height || "auto"};
`;
