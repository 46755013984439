import React from "react";
import { inject, observer } from "mobx-react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import Layout from "components/Layout";
import Constraint from "components/Constraint";
import ProtectedRoutes from "./ProtectedRoutes";
import AuthRoute from "./AuthRoute";
import { routes, authRoutes } from "./routes";

const { REACT_APP_REDIRECT_AFTER_LOGIN } = process.env;

const App = ({ authStore, validator, history }) => {
  const { user, isInContext } = authStore;

  const mapRoutes = () =>
    routes.map((props) => {
      const { component: Page, path, exact, roles, constraintGetter } = props;

      if (!validator.validatePermissions(roles, user.role, isInContext)) {
        return null;
      }

      const renderContent = props => (
        <Constraint constraintGetter={constraintGetter}>
          <Page isMobile={isMobile} {...props} />
        </Constraint>
      );

      return (
        <Route key={path} path={path} exact={!!exact} render={renderContent} />
      );
    });

  return (
    <Router history={history}>
      <Route exact path="/">
        <Redirect to={REACT_APP_REDIRECT_AFTER_LOGIN} />
      </Route>
      <Switch history={history}>
        <AuthRoute isMobile={isMobile} {...authRoutes} />
        <ProtectedRoutes isMobile={isMobile}>
          <Layout history={history}>{mapRoutes()}</Layout>
        </ProtectedRoutes>
      </Switch>
    </Router>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    validator: rootStore.validator
  })),
  observer
)(App);
