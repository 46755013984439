import styled from "styled-components";
import isNumber from "lodash/isNumber";

export const StyledErrorMessage = styled.div`
  position: absolute;
  bottom: ${({ bottom }) => (isNumber(bottom) ? bottom : -20)}px;
  left: ${({ left }) => (isNumber(left) ? left : 5)}px;
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
`;
