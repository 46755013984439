import React, { useRef, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { tableauOptions } from "./settings";
import { StyledTableauContainer } from "./Styled";

const TableauDashboard = ({ dataKey, tableauStore, authStore }) => {
  const [companyId] = useState(() => {
    if (authStore.isInContext) return authStore.contextCompanyId;
    if (authStore.isSuperAdmin) return "";
    return authStore.contextCompanyId;
  });
  const tableauVizContainer = useRef(null);

  useEffect(() => {
    const onMount = async () => {
      const urls = await tableauStore.getDashboardUrls();

      if (tableauVizContainer) {
        // temporary commented
        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        new window.tableau.Viz(tableauVizContainer.current, urls[dataKey], {
          ...tableauOptions,
          "companyId (analytics)": companyId ? companyId : "",
          // temporary commented
          // "Select Timezone": timezone,
        });
      }
    };

    onMount();
  }, [dataKey, tableauStore, companyId]);

  return <StyledTableauContainer ref={tableauVizContainer} />;
};

export default compose(
  inject(({ rootStore }) => ({
    tableauStore: rootStore.tableauStore,
    authStore: rootStore.authStore,
  })),
  observer,
)(TableauDashboard);
