export default {
  api: {
    getVersion: {
      method: "get",
      url: "version"
    }
  },
  auth: {
    login: {
      method: "post",
      url: "auth/login"
    },
    changePassword: {
      method: "put",
      url: "auth/password/change"
    },
    getCompanyContext: {
      method: "put",
      url: "auth/company"
    },
    forgotPasswordRequest: {
      method: "post",
      url: "auth/password/forgot"
    },
    forgotPasswordTokenValidation: {
      method: "get",
      url: "auth/password/forgot"
    },
    forgotPasswordUpdate: {
      method: "put",
      url: "auth/password/forgot"
    }
  },
  profile: {
    update: {
      method: "put",
      url: "auth/profile"
    },
    get: {
      method: "get",
      url: "auth/profile"
    }
  },
  refreshToken: {
    method: "put",
    url: "auth/refresh"
  },
  experiences: {
    getAll: {
      method: "get",
      url: "experiences"
    },
    getAllMenu: {
      method: "get",
      url: "experiences?type=menu"
    },
    getAllSequence: {
      method: "get",
      url: "experiences?type=sequence"
    },
    getById: {
      method: "get",
      url: "experiences"
    },
    create: {
      method: "post",
      url: "experiences"
    },
    update: {
      method: "put",
      url: "experiences"
    },
    delete: {
      method: "delete",
      url: "experiences"
    },
    createPreview: {
      method: "post",
      url: "experiences/preview"
    }
  },
  templates: {
    getAll: {
      method: "get",
      url: "templates"
    },
    create: {
      method: "post",
      url: "templates"
    },
    update: {
      method: "put",
      url: "templates"
    },
    getById: {
      method: "get",
      url: "templates"
    },
    delete: {
      method: "delete",
      url: "templates"
    },
    createPreview: {
      method: "post",
      url: "templates/preview"
    }
  },
  preview: {
    get: {
      method: "get",
      url: "preview"
    }
  },
  lots: {
    getAll: {
      method: "get",
      url: "lots"
    },
    getById: {
      method: "get",
      url: "lots"
    },
    getTypes: {
      method: "get",
      url: "lot-types"
    },
    create: {
      method: "post",
      url: "lots"
    },
    update: {
      method: "put",
      url: "lots"
    },
    delete: {
      method: "delete",
      url: "lots"
    }
  },
  rolls: {
    getAll: {
      method: "get",
      url: "rolls"
    },
    update: {
      method: "put",
      url: "rolls"
    },
    getByRollId: {
      method: "get",
      url: "rolls"
    },
    export: {
      method: "post",
      url: "rolls/export"
    }
  },
  tags: {
    getById: {
      method: "get",
      url: "tags"
    },
    getAll: {
      method: "get",
      url: "tags"
    },
    update: {
      method: "put",
      url: "tags"
    },
    aiSearch: {
      method: "post",
      url: "tags/ai-search"
    }
  },
  subRolls: {
    create: {
      method: "post",
      url: "subrolls"
    },
    update: {
      method: "put",
      url: "subrolls"
    },
    delete: {
      method: "delete",
      url: "subrolls"
    },
    validateForBeenUsedTags: {
      method: "get",
      url: "subrolls/payload"
    },
    deleteTag: {
      method: "delete",
      url: "subrolls/tag"
    }
  },
  companies: {
    getAll: {
      method: "get",
      url: "companies"
    },
    create: {
      method: "post",
      url: "companies"
    },
    update: {
      method: "put",
      url: "companies"
    },
    delete: {
      method: "delete",
      url: "companies"
    },
    getById: {
      method: "get",
      url: "companies"
    }
  },
  users: {
    getAll: {
      method: "get",
      url: "users"
    },
    create: {
      method: "post",
      url: "users"
    },
    update: {
      method: "put",
      url: "users"
    },
    isUniqEmail: {
      method: "get",
      url: "auth/email/unique"
    },
    delete: {
      method: "delete",
      url: "users"
    },
    getById: {
      method: "get",
      url: "users"
    },
    credentials: {
      method: "put",
      url: "users/credentials"
    }
  },
  uploadFile: {
    method: "post",
    url: "upload/image"
  },
  assetBundles: {
    create: {
      method: "post",
      url: "asset-bundles"
    }
  },
  assets: {
    create: {
      method: "post",
      url: "assets"
    },
    delete: {
      method: "delete",
      url: "assets"
    },
    getByBundleId: {
      method: "get",
      url: "assets"
    }
  },
  tableau: {
    method: "get",
    url: "tableau/dashboard/urls"
  },
  constraints: {
    getAll: {
      method: "get",
      url: "constraints"
    },
    contactMe: {
      method: "post",
      url: "constraints/contact-me"
    }
  },
  intercepts: {
    getAll: {
      method: "get",
      url: "intercepts"
    }
  },
  analytics: {
    getAll: {
      method: "get",
      url: "analytics"
    }
  },
  cloudEndpoints: {
    getAll: {
      method: "get",
      url: "cloud-endpoints"
    }
  }
};
