import React from "react";

import SearchInput from "../inputs/Search";
import Form from "../Form";

const Search = ({ placeholder, width = '350px', disabled, search, theme, onSearch, onSubmit = () => {} }) => (
  <Form left={20} width={width} onSubmit={onSubmit}>
    <SearchInput
      value={search}
      placeholder={placeholder}
      disabled={disabled}
      theme={theme}
      onChange={({ target }) =>
        onSearch({ key: target.name, value: target.value })
      }
    />
  </Form>
);

export default Search;
