import React, { useRef } from "react";

import { Button } from "components/actions";
import Div from "components/Div";
import { StyledUploadInput } from "./Styled";

const UploadAsset = ({ onUpload, disabled }) => {
  const uploadInputRef = useRef(null);

  const handleClick = () => {
    const current = uploadInputRef.current;
    if (uploadInputRef.current) {
      current.click();
    }
  };

  return (
    <Div align="center">
      <Button
        type="purpleUnderlined"
        styles={{ position: "relative" }}
        onClick={handleClick}
        disabled={disabled}
      >
        + Add file
      </Button>
      <StyledUploadInput
        htmlFor="file-upload"
        type="file"
        onChange={onUpload}
        ref={uploadInputRef}
      />
    </Div>
  );
};

export default UploadAsset;
