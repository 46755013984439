import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import whiteLabel, { defaultWhiteLabel } from "domain/services/white-label";
import P from "components/P";
import Div from "components/Div";
import Image from "components/Image";
import PoweredByLogo from "./PoweredByLogo";

const themeConfigMap = {
  dark: {
    poweredByTheme: "dark",
    logoName: "whiteLabelLogoDark"
  },
  light: {
    poweredByTheme: "white",
    logoName: "whiteLabelLogoLight"
  }
};

const LogoWithTagline = ({
  rootStore: {
    themeStore: { theme }
  },
  logoTheme,
  poweredByTop,
  poweredByBottom,
  poweredByLogoWidth,
  poweredByLogoHeight,
  poweredByTextSize,
  poweredByLineHeight,
  sloganTop,
  sloganBottom,
  sloganTextSize,
  productLogoWidth
}) => {
  const selectedTheme = themeConfigMap[logoTheme];

  const renderSubHeader = () => {
    if (whiteLabel !== defaultWhiteLabel) {
      return !isFeatureEnabled("hidePoweredBy") && (
        <Div top={poweredByTop} bottom={poweredByBottom} alignSelf="flex-end">
          <Div height={poweredByLogoHeight} align="flex-end">
            <PoweredByLogo
              textSize={poweredByTextSize}
              lineHeight={poweredByLineHeight}
              logoWidth={poweredByLogoWidth}
              logoHeight={poweredByLogoHeight}
              logoTheme={selectedTheme.poweredByTheme}
            />
          </Div>
        </Div>
      );
    } else {
      return (
        <Div top={sloganTop} bottom={sloganBottom}>
          <P color={theme.accentColor} size={sloganTextSize} weight="bold">
            Redefining Customer Connection&trade;
          </P>
        </Div>
      );
    }
  };

  return (
    <>
      <Image
        src={theme[selectedTheme.logoName]}
        width={`${productLogoWidth}px`}
      />
      {renderSubHeader()}
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(LogoWithTagline);
