import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import SortingPopover from "./SortingPopover";
import { StyledTitleWrapper, StyledTitle } from "./Styled";

const ColumnTitle = ({
  title = "",
  sortingOptions = [],
  themeStore,
  onSort = null,
}) => (
  <StyledTitleWrapper>
    <StyledTitle theme={themeStore.theme} onClick={onSort}>
      {title}
    </StyledTitle>
    {onSort && (
      <SortingPopover
        hasTitle={!!title}
        options={sortingOptions}
        theme={themeStore.theme}
        onSort={onSort}
      />
    )}
  </StyledTitleWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(ColumnTitle);
