import styled from "styled-components";

export const StyledPageWrapper = styled.div`
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.authPage.bgColor};
  background-image: ${({ theme }) =>
    `url(${theme.authPage.bgImages.authPageLeftBottom}), url(${theme.authPage.bgImages.authPageRightBottom})`};
  background-repeat: no-repeat;
  background-position: 0 100%, 100% 100%;
  background-size: 20%, 15%;
`;

export const StyledTopBlock = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  padding: 6vh 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.bgColor};
  background-image: ${({ theme }) =>
    `url(${theme.authPage.bgImages.authPageLeftTop}), url(${theme.authPage.bgImages.authPageRightTop})`};
  background-repeat: no-repeat;
  background-position: 0 50%, 100% 0;
  background-size: contain, 10%;
  border-bottom: ${({ theme }) => `6px solid ${theme.accentColor}`};
`;
