import React from "react";

import Icon from "components/Icon";
import Popover from "components/Popover";

const TagType = ({ tagModel = "", tagType = "" }) => {
  return (
    <Popover.InfoPopover
      icon={<Icon icon={tagType} />}
      width="auto"
      offset={0}
      justify="center"
    >
      {tagModel}
    </Popover.InfoPopover>
  );
};

export default TagType;
