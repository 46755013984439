import React  from "react";
import { inject, observer } from "mobx-react";
import { Tabs, Tab } from "components/Tabs";
import { useTabs } from "components/hooks";
import { compose } from "utils/hoc";
import { isFeatureEnabled } from "utils/helpers";
import SimpleSearch from './SimpleSearch'
import AISearch from './AISearch'

const Search = () => {
  const [tab, setTab] = useTabs("#simple");

  return (
    <>
      <Tabs activeKey={tab} onChange={setTab}>
        <Tab tab="Simple search" key="#simple">
          <SimpleSearch />
        </Tab>
        {isFeatureEnabled('aiSearch') && <Tab tab="TapVision" key="#ai">
          <AISearch />
        </Tab>}
      </Tabs>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore
  })),
  observer
)(Search);
