import styled from "styled-components";
import { Layout } from "antd";

import MenuIcon from "assets/images/MenuIcon.svg";

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  display: flex !important;
  height: 87px !important;
  width: 100%;
  padding: 0 74px 0 25px !important;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) =>
    `${theme.header.bgColor} url(${theme.header.bgImage}) no-repeat`} !important;
  background-position: 30% 0 !important;
`;

export const StyledHeaderMenuIcon = styled.img.attrs(() => ({
  src: MenuIcon,
}))`
  width: 25px;
  height: 25px;
  color: #ffffff;
  margin-right: 32px;
  cursor: pointer;
`;

export const StyledHeaderLogo = styled.img.attrs(({ theme, headerAsset, isSuperAdmin, isWhiteLabelingEnabled }) => ({
  src: (!isSuperAdmin && isWhiteLabelingEnabled && headerAsset?.file) || theme.logoIcon,
}))`
  height: 28px;
  color: #ffffff;
  margin-right: 30px;
`;
