import { observable, action } from "mobx";

class MetadataItem {
  @observable name = "";
  @observable value = "";

  constructor(metadataItem, rootStore) {
    this.rootStore = rootStore;
    Object.assign(this, metadataItem);
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }
}

export default MetadataItem;
