import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Header, Inner } from "components/Content";
import { Button } from "components/actions";
import Div from "components/Div";
import P from "components/P";
import SequenceSteps from "features/experiences/SequenceSteps";
import Thumbnail from "features/miscellany/previews/Thumbnail";
import IFrame from "features/miscellany/previews/IFrame";
import withCancelModal from "components/withCancelModal";
import Constraint from "components/Constraint";
import EditSequenceExperience from "features/experiences/forms/EditSequenceExperience";

const Edit = ({
  experiencesStore,
  validator,
  rootStore,
  match,
  constraintGetter,
  onLeavePage
}) => {
  const {
    menuExperiences,
    experience,
    findAllByType,
    findById,
    save,
    reset
  } = experiencesStore;
  const { id } = match.params;
  const isEditRoute = !!id;
  const innerTitle = isEditRoute ? "Edit Sequence" : "Create Sequence";
  const isThumbnailPreview = validator.validateBase64(experience.preview);

  useEffect(() => {
    const onMount = async () => {
      await findAllByType("menu");
      await findAllByType("sequence");
      if (id) {
        await findById({ id, type: "sequence" })();
      }
    };

    onMount();
    return reset;
  }, [findAllByType, findById, reset, id]);

  return (
    <Constraint
      constraintGetter={constraintGetter}
      capacityGetter="experiences.sequence.capacity"
      amount={experiencesStore.sequenceExperiences.length}
    >
      <Header
        title={innerTitle}
        backButton
        backButtonFn={onLeavePage(experience.isDirty)}
        backButtonLink="/experiences/sequence"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button
              type="regularFlat"
              onClick={onLeavePage(experience.isDirty)}
            >
              Cancel
            </Button>
            <Button type="regular" onClick={save(isEditRoute, "sequence")}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Inner>
        <EditSequenceExperience />
        <Div width="100%" top={25}>
          <Div direction="column" width="100%" styles={{ maxWidth: "800px" }}>
            <P bottom={12}>Sequence experience steps</P>
            <SequenceSteps
              experience={experience}
              menuExperiences={menuExperiences}
              errors={rootStore.validationErrors.steps}
            />
          </Div>
          <Div direction="column" display="block">
            <P bottom={12}>Preview</P>
            <Div>
              {isThumbnailPreview && <Thumbnail src={experience.preview} />}
              {!isThumbnailPreview && <IFrame src={experience.preview} />}
            </Div>
          </Div>
        </Div>
      </Inner>
    </Constraint>
  );
};

export default compose(
  withCancelModal("/experiences/sequence"),
  inject(({ rootStore }) => ({
    experiencesStore: rootStore.experiencesStore,
    validator: rootStore.validator,
    rootStore
  })),
  observer
)(Edit);
