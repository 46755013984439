import styled from "styled-components";

export const StyledInputWrapper = styled.div`
  position: ${({ position }) => position || "relative"};
  display: flex;
  width: ${({ width }) => width || "100%"};
  flex-direction: ${({ horizontalLabel }) =>
    horizontalLabel ? "row" : "column"};
  align-items: ${({ horizontalLabel }) =>
    horizontalLabel ? "center" : "normal"};
  white-space: nowrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
