import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import ErrorMessage from "../../ErrorMessage";
import Div from "../../Div";
import Label from "../Label";
import { StyledTextArea, StyledTextAreaWrapper } from "./Styled";
import { StyledInputWrapper } from "../common/Styled";

const TextArea = ({
  themeStore,
  label = "",
  labelTextSize,
  labelTextWeight,
  value = "",
  name = "",
  rows = 4,
  disabled,
  error = false,
  errorMessage = "",
  horizontalLabel = false,
  onChange = () => null,
}) => {
  return (
    <StyledInputWrapper horizontalLabel={horizontalLabel}>
      {label && (
        <Label
          text={label}
          horizontalLabel={horizontalLabel}
          textSize={labelTextSize}
          textWeight={labelTextWeight}
        />
      )}
      <Div display="block">
        <StyledTextAreaWrapper theme={themeStore.theme}>
          <StyledTextArea
            rows={rows}
            value={value}
            name={name}
            disabled={disabled}
            error={String(error)}
            theme={themeStore.theme}
            onChange={onChange}
          />
        </StyledTextAreaWrapper>
        {error && <ErrorMessage text={errorMessage} />}
      </Div>
    </StyledInputWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(TextArea);
