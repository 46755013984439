import React, {useEffect, useState, useRef} from "react";
import { inject, observer } from "mobx-react";
import { filter, debounce } from "lodash";

import { compose } from "utils/hoc";
import Modal from "components/Modal";
import { Button } from "components/actions";
import {useModal, useServerSorting, useSorting, useTablePaginator, useServerOrdering} from "components/hooks";
import Div from "components/Div";
import Table, { useRowSelect } from "components/TableVirtualized";
import TagManagement from "../TagManagement";
import WithAffectedModal from "../WithAffectedModal";
import AddOneEachForm from "../../forms/SeparateTags";
import { tableColumns, searchOptions, useFilterOptions, useSortOptions } from "./settings";

const SeparateTags = ({
  rootStore,
  subRollsStore,
  tagsStore,
  lotId,
  isEditRoute,
  createSubRoll
}) => {
  const { validateForBeenUsedTags, affectedLots } = subRollsStore;
  const { findAll, tags: data } = tagsStore;
  const [ tags, setTags ] = useState([])
  const {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedIds,
    resetAllSelected,
    resetSelectedIndex
  } = useRowSelect(filter(tags, ({ lot }) => !lot || lot.id !== lotId));

  const [search, setSearch] = useState('')
  const [pagination, paginationReset] = useTablePaginator({
    isScrollPagination: true,
    onPageChange: (paginationConfig) => {
      fetchTags(paginationConfig)
    }
  });
  const [sortedData, onSortTags] = useSorting(dataWithSelectedRows);
  const [filterOptions, onFilter, getFilters, resetFilter] = useServerSorting(useFilterOptions.items, paginationReset)
  const [order, onOrder, resetOrder] = useServerOrdering(useSortOptions, paginationReset, onSortTags)
  const [isModalWasOpened, setModalWasOpened] = useState(false)
  const [isModalShown, setModalShown, onModalOk, onModalCancel] = useModal(
      rootStore
  );
  const [
    isAffectedModalShown,
    setAffectedModalShown,
    onAffectedModalOk,
    onAffectedModalCancel
  ] = useModal();

  useEffect(() => {
    let items = data
    if (dataWithSelectedRows.length) {
      items = items.map((tag) => {
        const selectedTag = dataWithSelectedRows.find((selectedTag) => tag.id === selectedTag.id)
        return {
          ...tag,
          selected: selectedTag?.selected
        }
      })
    }


    setTags([...items])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (isModalShown) {
      setModalWasOpened(true)
      fetchTags(pagination)
    }

    if (!isModalShown && isModalWasOpened) {
      setModalWasOpened(false)
      paginationReset()
      setSearch('')
      resetOrder()
      resetFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalShown, search, filterOptions, order]);

  const fetchTags = (paginationOptions) => {
    const filter = getFilters()

    return findAll({}, paginationOptions, {
      search,
      filter,
      order,
    });
  }

  const onAffectedCancel = () => {
    resetAllSelected();
    onAffectedModalCancel()();
    setModalShown()();
  };

  const onSubRollCreate = async () => {
    await createSubRoll({
      data: { lotId, tagIds: getSelectedIds() }
    });
    tagsStore.reset();
    subRollsStore.resetAffectedLots();
    onAffectedModalCancel()();
    onModalCancel()();
    resetAllSelected();
  };

  const onSave = async () => {
    const affectedLots = await validateForBeenUsedTags(lotId, {
      tagIds: getSelectedIds({ stringify: true })
    });

    if (affectedLots.length) {
      onModalCancel()();
      return setAffectedModalShown()();
    }

    await onSubRollCreate();
  };

  const scrollTable = async ({ scrollTop, scrollHeight, clientHeight }) => {
    let maxScroll = scrollHeight - clientHeight
    if (scrollTop === maxScroll && tags.length < pagination.total) {
      pagination.onChange(pagination.current + 1)
    }
  };

  const onSearch = useRef(debounce((val) => {
    paginationReset()
    setSearch(val)
  }, 500)).current;

  return (
    <WithAffectedModal
      isModalShown={isAffectedModalShown}
      affectedItems={affectedLots}
      onCancel={onAffectedCancel}
      onCross={onAffectedModalCancel()}
      onConfirm={onAffectedModalOk(onSubRollCreate)}
    >
      <Button
        type="tagManagement"
        disabled={!isEditRoute}
        onClick={setModalShown()}
      >
        <TagManagement
          title="Add Individual Tags"
          description="Add tags one by one to lot"
          imageType="addOneEach"
        />
      </Button>
      <Modal
        title="Add Individual Tags"
        showModal={isModalShown}
        onCancel={onModalCancel(resetAllSelected)}
        onConfirm={onModalOk(onSave)}
        confirmButtonProps={{ disabled: !hasSelected() }}
        confirmButtonTitle="Add"
        width="1190px"
      >
        <Div direction="column" width="100%">
          <AddOneEachForm
            onSearch={onSearch}
            data={[]}
            searchPopupOptions={searchOptions}
            filterOptions={filterOptions}
            onFilterChange={onFilter}
            onSearchSelect={onRowSelect}
          />
          <Table
            columns={tableColumns}
            data={sortedData}
            selectedIndex={selectedIndex}
            onSort={onOrder}
            onRowSelect={onRowSelect}
            onResetSelectedIndex={resetSelectedIndex}
            onScroll={scrollTable}
          />
        </Div>
      </Modal>
    </WithAffectedModal>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    subRollsStore: rootStore.subRollsStore,
    tagsStore: rootStore.tagsStore
  })),
  observer
)(SeparateTags);
