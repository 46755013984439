import styled from "styled-components";

export const StyledRow = styled.div`
  transition: 0.3s;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.mainLightColor} !important;
      box-shadow: 0 2px 5px #0000001a;

      & .ReactVirtualized__Table__rowColumn {
        color: ${theme.mainDarkColor};
        font-weight: bold;
      }
  `}
`;
