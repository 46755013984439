import React, {useEffect, useRef} from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { compose } from "utils/hoc";
import { Inner } from "components/Content";
import Table from "components/Table";
import { useTablePaginator } from "components/hooks";
import SearchForm from "components/forms/Search";
import { tagListTableColumns } from "./settings";

const SimpleSearch = ({ tagsStore, authStore }) => {
  const {
    tags,
    findAll,
    reset,
  } = tagsStore;
  const {
    setSearch,
    setSearchHistory,
    search,
    searchHistory
  } = authStore

  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: (paginationConfig) => {
      findAll(null, paginationConfig, {search});
    }
  });

  const delayedQuery = useRef(
    debounce((search) => {
      paginationReset();

      if (search) {
        setSearchHistory(search)
        findAll(null, pagination, {search});
      } else {
        reset();
      }
    }, 500)
  ).current;

  useEffect(() => {
    if (search) {
      findAll(null, pagination, {search});
    }

    return () => {
      reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAll, reset]);

  const onSearch = ({ value }) => {
    setSearch(value)
    delayedQuery(value);
  };

  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <SearchForm
          search={search}
          onSearch={({value}) => {
            setSearch(value)
          }}
          placeholder="Type here"
        />
      </div>

      <Inner>
        <Table
          columns={tagListTableColumns}
          paginationConfig={pagination}
          data={tags}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore
  })),
  observer
)(SimpleSearch);
