import { message as antdMessage } from "antd";

export const dateFormatter = (d) => new Date(d).toLocaleString(undefined);

export const utc2tz = (d, tz) =>
  new Date(
    (typeof d === "string" ? new Date(d) : d).toLocaleString(undefined, {
      timeZone: tz
    })
  );

export const message = (text = "", type = "info", config = {}) => {
  antdMessage.config({
    top: 100,
    maxCount: 1,
    ...config
  });

  antdMessage[type](text);
};

export const removeNonNumericCharacters = string => string.replace(/\D/g, "");

export const filesToFormData = (input, fieldNames) => {
  const formData = new FormData();
  fieldNames.forEach((name, i) => formData.append(name, input.files[i]));
  input.value = "";
  return formData;
};

export const dateFormatMMMMDDYYYY = (date) => {
  const newDate = new Date(date);
  
  return newDate.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
