import geo from "domain/services/geo";

export const templateZone = {
  continentCode: "*",
  countryCode: "*",
  regionCode: "*",
  city: "*",
  postalCode: "*"
};

export const contextContinents = (render) => ({
  data: [...geo.continents],
  rowKey: "code",
  columns: [{
    title: "Continents",
    key: "code",
    dataIndex: "name",
    render
  }],
  selections: (data, zones) => (
    data.reduce((collect, item) => {
      const continent = item.code;
      const check = !!zones.find(z => z.continentCode === continent);
      if (check) collect.push(continent);
      return collect;
    }, [])
  ),

  scope: "continentCode",
  zone: { ...templateZone },
  filtered: (zones, continent) => (
    zones.filter(z => z.continentCode !== continent)
  )
});

export const contextCountries = (title, continent) => ({
  data: geo.makeIndexByContinent(continent),
  rowKey: "code",
  columns: [{
    title,
    key: "code",
    dataIndex: "name"
  }],
  selections: (data, zones) => (
    data.reduce((collect, item) => {
      const country = item.code;
      const check = !!zones.find(
        z =>
          z.continentCode === continent &&
          (z.countryCode === "*" || z.countryCode === country)
      );
      if (check) collect.push(country);
      return collect;
    }, [])
  ),

  scope: "countryCode",
  zone: { ...templateZone, continentCode: continent },
  filtered: (zones, country) => (
    zones.filter(
      z =>
        !(z.continentCode === continent && z.countryCode === country)
    )
  )
});
