import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledSearch, StyledSearchIcon } from "./Styled";

const Search = ({ placeholder, disabled, value, themeStore, onChange }) => (
  <StyledSearch
    value={value}
    name="search"
    placeholder={placeholder}
    prefix={
      <StyledSearchIcon
        type="search"
        style={{ color: themeStore.theme.accentColor }}
      />
    }
    type="search"
    disabled={disabled}
    autoComplete="false"
    theme={themeStore.theme}
    onChange={onChange}
  />
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Search);
