import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { compose } from "utils/hoc";
import Table from "components/Table";
import { Button, Link } from "components/actions";
import Modal from "components/Modal";
import { useModal, useModalWithValidation } from "components/hooks";
import P from "components/P";
import Div from "components/Div";
import { Header, Inner } from "components/Content";
import CloneForm from "features/miscellany/forms/Clone";
import { getExperienceModalTitle } from "../helpers";
import { tableColumns } from "./settings";

const List = ({ experiencesStore, rootStore }) => {
  const {
    menuExperiences,
    findAll,
    findById,
    deleteById,
    reset,
    changeParams,
    params: { search },
    clone,
  } = experiencesStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel,
  ] = useModal();
  const [
    isCloneModalShown,
    setCloneModalShown,
    onCloneModalOk,
    onCloneModalCancel,
  ] = useModalWithValidation(rootStore);

  const [experience, setExperience] = useState({});

  const delayedQuery = useRef(debounce(findAll, 500)).current;

  useEffect(() => {
    changeParams({ key: "type", value: "menu" });
    findAll();
    return reset;
  }, [changeParams, findAll, reset]);

  const onSearch = ({ key, value }) => {
    changeParams({ key, value });
    delayedQuery();
  };

  const onDelete = row => async () => {
    const experience = await findById({ id: row.id, type: "menu" })();
    setExperience(experience);

    setDeleteModalShown()();
  };

  const onClone = row => async () => {
    const experience = await findById({ id: row.id, type: "menu" })();
    setExperience(experience);

    setCloneModalShown()();
  };

  const renderActions = row => (
    <>
      <Button
        constraintGetter="experiences.menu.clone"
        type="popoverRegular"
        onClick={onClone(row)}
      >
        Clone
      </Button>
      <Link type="popoverRegular" to={`/experiences/menu/edit/${row.id}`}>
        Edit
      </Link>
      <Button
        type="popoverRedText"
        onClick={onDelete(row)}
        constraintGetter="experiences.menu.delete"
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title={getExperienceModalTitle(experience)}
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel()}
        onConfirm={onDeleteModalOk(deleteById(experience?.id, "menu"))}
      >
        <P size={16} textAlign="center">
          {`Are you sure you want to delete ${experience?.name} experience?`}
        </P>
      </Modal>
      <Modal
        title={
          <Div direction="column" align="center">
            <P bottom={10} size={18} textAlign="center">
              {`You are going to clone ${experience?.name} experience.`}
            </P>
            <P size={18}>Please, add a name for the cloned experience.</P>
          </Div>
        }
        showModal={isCloneModalShown}
        confirmButtonTitle="Clone"
        onCancel={onCloneModalCancel()}
        onConfirm={onCloneModalOk(clone("menu"))}
      >
        <CloneForm storeName="experiences" />
      </Modal>
      <Header
        title="All Menus"
        searchPlaceholder="Search by name"
        actionButtons={[
          <Link type="regular" key="create-btn" to="/experiences/menu/create">
            Create Menu
          </Link>,
        ]}
        withSearch
        searchDisabled={false} //todo: remake when backend send count of items
        search={search}
        onSearch={onSearch}
      />
      <Inner>
        <Table
          columns={tableColumns}
          data={menuExperiences}
          renderActions={renderActions}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    experiencesStore: rootStore.experiencesStore,
    rootStore,
  })),
  observer,
)(List);
