import React, { useState } from "react";
import { Select as AntSelect } from "antd";
import { isEmpty } from "lodash";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledSelect, StyledOptionTitle } from "./Styled";
import { StyledInputWrapper } from "../common/Styled";
import Label from "../Label";
import Div from "../../Div";

const { Option } = AntSelect;

const SelectImage = ({
  label = "",
  labelTextSize,
  themeStore,
  list = [],
  optionKey = "name",
  valueKey = "src",
  srcKey = "src",
  selectedValue = "",
  defaultValue = "",
  showSearch = false,
  lowercase = false,
  inputWidth = "auto",
  onChange = () => null,
}) => {
  const [data, setData] = useState(list);

  const onSearch = (value) => {
    if (value === "") return setData(list);
    const searchResult = list.filter(
      (i) => i[optionKey].includes(value) && i[optionKey] !== "none"
    );
    setData(searchResult);
  };

  const onDropdownVisibleChange = () => setData(list);

  return (
    <StyledInputWrapper>
      {label && <Label text={label} textSize={labelTextSize} />}
      <Div display="block" width={inputWidth}>
        <StyledSelect
          showSearch={showSearch}
          optionFilterProp="children"
          value={isEmpty(selectedValue) ? defaultValue : selectedValue}
          theme={themeStore.theme}
          filterOption={data}
          onChange={onChange}
          onSearch={onSearch}
          onDropdownVisibleChange={onDropdownVisibleChange}
        >
          {data.map((item, i) => (
            <Option key={item[valueKey]} value={item[valueKey]}>
              {item[srcKey] ? (
                <img
                  src={item[srcKey]}
                  width="26px"
                  height="26px"
                  style={{ marginRight: "5px", filter: "invert(20%)" }}
                  alt="icon"
                />
              ) : null}
              <StyledOptionTitle lowercase={lowercase}>
                {item[optionKey]}
              </StyledOptionTitle>
            </Option>
          ))}
        </StyledSelect>
      </Div>
    </StyledInputWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(SelectImage);
