import styled from "styled-components";

export const StyledP = styled.p`
  position: ${({ position }) => `${position || "initial"}`};
  display: ${({ display }) => `${display || "block"}`};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  flex-direction: ${({ direction }) => `${direction || "row"}`};
  justify-content: ${({ justify }) => `${justify || "normal"}`};
  align-items: ${({ align }) => `${align || "normal"}`};
  flex-grow: ${({ grow }) => `${grow || 0}`};
  flex-wrap: ${({ flexWrap }) => `${flexWrap || "nowrap"}`};
  margin-top: ${({ top }) => (top ? `${top}px` : 0)};
  margin-right: ${({ right }) => (right ? `${right}px` : 0)};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : 0)};
  margin-left: ${({ left }) => (left ? `${left}px` : 0)};
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: ${({ weight }) => `${weight || "normal"}`};
  line-height: ${({ lineHeight }) => lineHeight || "normal"};
  color: ${({ color, theme }) => `${color || theme.mainDarkColor}`};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  text-align: ${({ textAlign }) => `${textAlign || "start"}`};
  transition: 0.3s;
`;
