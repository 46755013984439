import React from "react";
import { Popover as AntdPopover } from "antd";

import Div from "../Div";
import { Button } from "../actions";
import Icon from "../Icon";

const Popover = ({
  trigger = "hover",
  button = () => null,
  placement = "bottom",
  children,
}) => (
  <AntdPopover content={children} placement={placement} trigger={trigger}>
    {button}
  </AntdPopover>
);

const InfoPopover = ({
  placement,
  children,
  icon = <Icon icon="info" width={12} height={12} />,
  width = "330px",
  offset = 10,
  justify = "flex-start",
}) => (
  <Div left={offset} justify={justify}>
    <Popover button={<Button>{icon}</Button>} placement={placement}>
      <Div width={width} direction="column">
        {children}
      </Div>
    </Popover>
  </Div>
);

Popover.InfoPopover = InfoPopover;

export default Popover;
