import * as db from "./geo-db.json";

class GeoDB {
  continents = db.continents;
  countries = db.countries;
  continentsIndex = {};
  countriesIndex = {};
  constructor() {
    this.continents.forEach(({ code, name }) => (this.continentsIndex[code] = name));
    this.countries.forEach(({ properties: { code, name }}) => (this.countriesIndex[code] = name));
  }

  makeIndexByContinent(continent) {
    const index = [];
    this.countries.forEach(
      ({ properties }) => (properties.continent === continent && index.push(properties))
    );
    return index.sort((a, b) => a.name < b.name ? -1 : 1);
  }
}

export default new GeoDB();
