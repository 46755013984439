export const companyStatuses = [
  {
    id: "1",
    status: "active",
  },
  {
    id: "2",
    status: "inactive",
  },
];
