import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Icon from "../../Icon";
import { StyledCheckbox } from "./Styled";

const Checkbox = ({
  title,
  icon,
  checked,
  name = "",
  large,
  themeStore,
  disabled = false,
  onChange,
  ...rest
}) => (
  <StyledCheckbox
    checked={checked}
    name={name}
    large={large}
    theme={themeStore.theme}
    disabled={disabled}
    onChange={onChange}
    {...rest}
  >
    {icon && <Icon icon={icon} width={11} height={11} margin="0 6px 0 0" />}
    {title}
  </StyledCheckbox>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Checkbox);
