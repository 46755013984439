import React, {useEffect, useRef, useState} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import { useSorting, useModal, useTablePaginator, useServerSorting, useServerOrdering } from "components/hooks";
import Modal from "components/Modal";
import Div from "components/Div";
import Table, { useRowSelect } from "components/TableVirtualized";
import TagManagement from "../TagManagement";
import WithAffectedModal from "../WithAffectedModal";
import AddRollForm from "../../forms/FullRoll";
import { tableColumns, useFilterOptions, searchOptions } from "./settings";
import {debounce} from "lodash";
import {useSortOptions} from "../SeparateTags/settings";

const AddRoll = ({
  rollsStore,
  subRollsStore,
  authStore,
  lotId,
  createSubRoll,
  isEditRoute
}) => {
  const { validateForBeenUsedTags, affectedLots } = subRollsStore;
  const { findAll, rolls: data } = rollsStore;
  const { contextCompanyId } = authStore;
  const [ rolls, setRolls ] = useState([])
  const {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedIds,
    resetAllSelected,
    resetSelectedIndex
  } = useRowSelect(rolls);
  const [search, setSearch] = useState('')
  const [pagination, paginationReset] = useTablePaginator({
    isScrollPagination: true,
    onPageChange: (paginationConfig) => {
      fetchRolls(paginationConfig)
    }
  });
  const [sortedData, onSortRolls] = useSorting(dataWithSelectedRows);
  const [filterOptions, onFilter, getFilters, resetFilter] = useServerSorting(useFilterOptions.items, paginationReset)
  const [order, onOrder, resetOrder] = useServerOrdering(useSortOptions, paginationReset, onSortRolls)
  const [isModalWasOpened, setModalWasOpened] = useState(false)
  const [isModalShown, setModalShown, onModalOk, onModalCancel] = useModal();
  const [
    isAffectedModalShown,
    setAffectedModalShown,
    onAffectedModalOk,
    onAffectedModalCancel
  ] = useModal();

  useEffect(() => {
    let items = data
    if (dataWithSelectedRows.length) {
      items = items.map((roll) => {
        const selectedRoll = dataWithSelectedRows.find((selectedRoll) => roll.id === selectedRoll.id)
        return {
          ...roll,
          selected: selectedRoll?.selected
        }
      })
    }

    setRolls([...items])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (isModalShown) {
      setModalWasOpened(true)
      fetchRolls(pagination)
    }

    if (!isModalShown && isModalWasOpened) {
      setModalWasOpened(false)
      paginationReset()
      setSearch('')
      resetOrder()
      resetFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalShown, search, filterOptions, order]);

  const fetchRolls = (paginationOptions) => {
    const filter = getFilters()

    return findAll({
      params: { companyId: contextCompanyId, withTags: true, search, order, filter},
      paginationOptions,
    });
  }

  const onAffectedCancel = () => {
    resetAllSelected();
    onAffectedModalCancel()();
    setModalShown()();
  };

  const onSubRollCreate = async () => {
    await createSubRoll({
      data: { lotId, rollIds: getSelectedIds() }
    });
    rollsStore.reset();
    subRollsStore.resetAffectedLots();
    onAffectedModalCancel()();
    onModalCancel()();
    resetAllSelected();
  };

  const onSave = async () => {
    const affectedLots = await validateForBeenUsedTags(lotId, {
      rollIds: getSelectedIds({ stringify: true })
    });

    if (affectedLots.length) {
      onModalCancel()();
      return setAffectedModalShown()();
    }

    await onSubRollCreate();
  };

  const scrollTable = async ({ scrollTop, scrollHeight, clientHeight }) => {
    let maxScroll = scrollHeight - clientHeight
    if (scrollTop === maxScroll && rolls.length < pagination.total) {
      pagination.onChange(pagination.current + 1)
    }
  };

  const onSearch = useRef(debounce((val) => {
    paginationReset()
    setSearch(val)
  }, 500)).current;

  return (
    <WithAffectedModal
      isModalShown={isAffectedModalShown}
      affectedItems={affectedLots}
      onCancel={onAffectedCancel}
      onCross={onAffectedModalCancel()}
      onConfirm={onAffectedModalOk(onSubRollCreate)}
    >
      <Button
        type="tagManagement"
        disabled={!isEditRoute}
        onClick={setModalShown()}
      >
        <TagManagement
          title="Add Tags from Roll"
          description="Add entire roll of tags to lot"
          imageType="addRoll"
        />
      </Button>
      <Modal
        title="Add Tags from Roll"
        showModal={isModalShown}
        onCancel={onModalCancel(resetAllSelected)}
        onConfirm={onModalOk(onSave)}
        confirmButtonProps={{ disabled: !hasSelected() }}
        confirmButtonTitle="Add"
        width="1190px"
      >
        <Div direction="column" width="100%">
          <AddRollForm
            onSearch={onSearch}
            data={sortedData}
            searchPopupOptions={searchOptions}
            filterOptions={filterOptions}
            onFilterChange={onFilter}
            onSearchSelect={onRowSelect}
          />
          <Table
            columns={tableColumns}
            data={dataWithSelectedRows}
            selectedIndex={selectedIndex}
            onSort={onOrder}
            onRowSelect={onRowSelect}
            onResetSelectedIndex={resetSelectedIndex}
            onScroll={scrollTable}
          />
        </Div>
      </Modal>
    </WithAffectedModal>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    subRollsStore: rootStore.subRollsStore,
    authStore: rootStore.authStore
  })),
  observer
)(AddRoll);
