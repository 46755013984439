import styled from "styled-components";

export const UploadedFilesWrapper = styled.div`
  display: grid;
  padding: 10px 20px 10px 0;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  justify-items: stretch;
  align-items: stretch;
`;
