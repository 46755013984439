import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import { compose } from "utils/hoc";
import { Header, Inner } from "components/Content";
import Table from "components/Table";
import { Button, Link } from "components/actions";
import Modal from "components/Modal";
import {
  useModalWithValidation,
  useModal,
  useTablePaginator,
  useFilter
} from "components/hooks";
import P from "components/P";
import AssignCompanyForm from "features/rolls/forms/AssignCompany";
import ExportForm from "features/rolls/forms/Export";
import { rollListTableColumns, useFilterOptions } from "./settings";

const List = ({ rollsStore, companiesStore, rootStore }) => {
  const {
    rolls,
    exportRollData,
    findAll,
    reset,
    findById,
    assignToCompany,
    changeParams,
    removeFromParams,
    params: { search }
  } = rollsStore;
  const {
    company,
    findById: findCompanyById,
    resetSelectedCompany
  } = companiesStore;
  const [
    isAssignModalShown,
    setAssignModalShown,
    onAssignModalOk,
    onAssignModalCancel
  ] = useModal(rootStore);
  const [
    isExportModalShown,
    setExportModalShown,
    onExportModalOk,
    onExportModalCancel
  ] = useModal(rootStore);
  const [
    isWarningModalShown,
    setWarningModalShown,
    onWarningModalOk,
    onWarningModalCancel
  ] = useModalWithValidation(rootStore);

  const [, filterOptions, setFilterOptions] = useFilter({
    options: useFilterOptions.items,
    dataKey: useFilterOptions.dataKey
  });

  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: rollsStore.onNextPage
  });

  const delayedQuery = useRef(
    debounce(() => {
      paginationReset();
      findAll({ paginationOptions: pagination });
    }, 500)
  ).current;

  const getFilters = () => {
    const withTags = filterOptions.filter(f => f.key === "withTags")[0];
    const withoutTags = filterOptions.filter(f => f.key === "withoutTags")[0];

    return {
      withTags,
      withoutTags
    };
  };

  useEffect(() => {
    const { withTags, withoutTags } = getFilters();

    if (
      (withTags.isSelected && !withoutTags.isSelected) ||
      (!withTags.isSelected && withoutTags.isSelected)
    ) {
      changeParams({
        key: "withTags",
        value: withTags.isSelected
      });
    } else if (withTags.isSelected && withoutTags.isSelected) {
      removeFromParams("withTags");
    } else {
      paginationReset();
      reset();
      return;
    }

    paginationReset();
    findAll({ paginationOptions: {limit: 10, offset: 0, setTotal: pagination.setTotal} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  useEffect(() => {
    const onMount = async () => {
      await companiesStore.findAll();
    };

    onMount();
    return () => {
      reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAll, companiesStore, reset]);

  const onSearch = ({ key, value }) => {
    const { withTags, withoutTags } = getFilters();

    changeParams({ key, value });

    if (!withTags.isSelected && !withoutTags.isSelected) return;
    delayedQuery();
  };

  const onCompanyChange = async id => await findCompanyById({ id })();

  const onCompanyAssign = async () => {
    const result = await assignToCompany(company);
    if (result) return resetSelectedCompany();
  };

  const okButtonProps = {
    disabled: isEmpty(company.id)
  };

  const renderActions = row => (
    <>
      <Link type="popoverRegular" to={`/rolls/edit/${row.id}`}>
        Edit
      </Link>
      {rootStore.authStore.isSuperAdmin && !rootStore.authStore.isInContext ? (
        <Button
          type="popoverRegular"
          onClick={setAssignModalShown(findById({ id: row.id }))}
        >
          Assign to company
        </Button>
      ) : null}
      <Button
        type="popoverRegular"
        onClick={setExportModalShown(findById({ id: row.id }))}
      >
        Export
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title="Please, choose a company from the list below"
        showModal={isAssignModalShown}
        onCancel={onAssignModalCancel(resetSelectedCompany)}
        onConfirm={onAssignModalOk(setWarningModalShown())}
        okButtonProps={okButtonProps}
      >
        <AssignCompanyForm onCompanyChange={onCompanyChange} />
      </Modal>
      <Modal
        title="Export Roll Data"
        showModal={isExportModalShown}
        confirmButtonTitle="Generate and Export"
        onCancel={onExportModalCancel(() => { })}
        onConfirm={onExportModalOk(exportRollData)}
      >
        <ExportForm />
      </Modal>
      <Modal
        showModal={isWarningModalShown}
        onCancel={onWarningModalCancel(resetSelectedCompany)}
        onConfirm={onWarningModalOk(onCompanyAssign)}
      >
        <P top={30} size={21}>
          Are you sure you want to reassign this roll?
        </P>
      </Modal>
      <Header
        title="All Rolls"
        searchPlaceholder="Search by roll id or description"
        withSearch
        searchDisabled={false}
        search={search}
        onSearch={onSearch}
        withFilter
        filterOptions={filterOptions}
        onFilterChange={setFilterOptions}
      />
      <Inner>
        <Table
          columns={rollListTableColumns}
          data={rolls}
          paginationConfig={pagination}
          renderActions={renderActions}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    companiesStore: rootStore.companiesStore,
    rootStore
  })),
  observer
)(List);
