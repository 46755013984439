import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Modal from "components/Modal";
import {useModal, useServerOrdering, useSorting, useTablePaginator} from "components/hooks";
import Div from "components/Div";
import Table, { useRowSelect } from "components/TableVirtualized";
import WithAffectedModal from "../WithAffectedModal";
import TagManagement from "../TagManagement";
import AddFromLotForm from "../../forms/FromLot";
import { tableColumns, searchOptions } from "./settings";
import {debounce} from "lodash";
import {useSortOptions} from "../SeparateTags/settings";

const FromLot = ({ lotsStore, lotId, createSubRoll, isEditRoute }) => {
  const [affectedLots, setAffectedLots] = useState([]);
  const { findAll, lots: data } = lotsStore;
  const [ lots, setLots ] = useState([])
  const {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedIds,
    resetAllSelected,
    resetSelectedIndex
  } = useRowSelect(lots.filter((lot) => {
    return lot.id !== lotId
  }));
  const [sortedData, onSortLots] = useSorting(dataWithSelectedRows);
  const [search, setSearch] = useState('')
  const [pagination, paginationReset] = useTablePaginator({
    isScrollPagination: true,
    onPageChange: (paginationConfig) => {
      fetchLots(paginationConfig)
    }
  });
  const [order, onOrder, resetOrder] = useServerOrdering(useSortOptions, paginationReset, onSortLots)

  const [
    isAffectedModalShown,
    setAffectedModalShown,
    onAffectedModalOk,
    onAffectedModalCancel
  ] = useModal();
  const [isModalWasOpened, setModalWasOpened] = useState(false)
  const [isModalShown, setModalShown, onModalOk, onModalCancel] = useModal();

  useEffect(() => {
    let items = data
    if (dataWithSelectedRows.length) {
      items = items.map((lot) => {
        const selectedLot = dataWithSelectedRows.find((selectedLot) => lot.id === selectedLot.id)
        return {
          ...lot,
          selected: selectedLot?.selected
        }
      })
    }

    setLots([...items])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (isModalShown) {
      setModalWasOpened(true)
      fetchLots(pagination)
    }

    if (!isModalShown && isModalWasOpened) {
      setModalWasOpened(false)
      paginationReset()
      resetOrder()
      setSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalShown, search, order]);

  const fetchLots = (paginationOptions) => {
    // const filter = getFilters()
    return findAll({ search, order }, paginationOptions);
  }

  const onAffectedCancel = () => {
    resetAllSelected();
    onAffectedModalCancel()();
    setModalShown()();
  };

  const onSubRollCreate = async () => {
    await createSubRoll({
      data: { lotId, currentLotIds: getSelectedIds() },
      useUpdate: true
    });
    onAffectedModalCancel()();
    onModalCancel()();
    resetAllSelected();
  };

  const onSave = async () => {
    const affectedLots = dataWithSelectedRows
      .filter(({ selected }) => selected)
      .map(({ name }) => name);

    if (affectedLots.length) {
      setAffectedLots(affectedLots);
      onModalCancel()();
      return setAffectedModalShown()();
    }

    await onSubRollCreate();
  };

  const scrollTable = async ({ scrollTop, scrollHeight, clientHeight }) => {
    let maxScroll = scrollHeight - clientHeight
    if (scrollTop === maxScroll && lots.length < pagination.total) {
      pagination.onChange(pagination.current + 1)
    }
  };

  const onSearch = useRef(debounce((val) => {
    paginationReset()
    setSearch(val)
  }, 500)).current;

  return (
    <WithAffectedModal
      isModalShown={isAffectedModalShown}
      affectedItems={affectedLots}
      onCancel={onAffectedCancel}
      onCross={onAffectedModalCancel()}
      onConfirm={onAffectedModalOk(onSubRollCreate)}
    >
      <Button
        type="tagManagement"
        disabled={!isEditRoute}
        onClick={setModalShown()}
      >
        <TagManagement
          title="Add Tags from Lot"
          description="Move all tags from another lot"
          imageType="moveFromLot"
        />
      </Button>
      <Modal
        title="Add Tags from Lot"
        showModal={isModalShown}
        onCancel={onModalCancel(resetAllSelected)}
        onConfirm={onModalOk(onSave)}
        confirmButtonProps={{ disabled: !hasSelected() }}
        confirmButtonTitle="Add"
        width="1190px"
      >
        <Div direction="column" width="100%">
          <AddFromLotForm
            onSearch={onSearch}
            data={sortedData}
            searchPopupOptions={searchOptions}
            onSearchSelect={onRowSelect}
          />
          <Table
            columns={tableColumns}
            data={sortedData}
            selectedIndex={selectedIndex}
            onSort={onOrder}
            onRowSelect={onRowSelect}
            onResetSelectedIndex={resetSelectedIndex}
            onScroll={scrollTable}
          />
        </Div>
      </Modal>
    </WithAffectedModal>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    lotsStore: rootStore.lotsStore
  })),
  observer
)(FromLot);
