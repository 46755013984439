import React, { useState } from "react";

import Modal from "./Modal";
import P from "./P";

const withCancelModal = url => WrappedComponent => props => {
  const [isShown, setIsShown] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(false);

  const onLeavePage = (isDirty, differentUrl) => e => {
    setRedirectUrl(false)
    if (isDirty) {
      e.preventDefault();
      if (differentUrl) {
        setRedirectUrl(differentUrl)
      }
      setIsShown(true);
    } else {
      if (differentUrl) {
        props.history.push(differentUrl);
        return
      }
      props.history.push(url);
    }
  };

  const onConfirm = () => {
    if (redirectUrl) {
      props.history.push(redirectUrl)
      setRedirectUrl(false)
      return
    }

    props.history.push(url)
  };

  const onCancel = () => setIsShown(false);

  return (
    <>
      <Modal
        title="Save changes?"
        showModal={isShown}
        confirmButtonTitle="Ok"
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        <P size={16} textAlign="center">
          You have unsaved changes.
          <br />
          Are you sure you want to cancel?
        </P>
      </Modal>
      <WrappedComponent {...props} onLeavePage={onLeavePage} onConfirm={onConfirm} />
    </>
  );
};

export default withCancelModal;
