import React from "react";
import { get } from "lodash";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import {
  ColorPicker,
  ImageUpload,
  Select,
  RadioGroup
} from "components/inputs";
import { useTemplateTab } from "components/hooks";
import { removeNonNumericCharacters } from "utils/format";
import { StyledAccordionPanelWrapper } from "./Styled";
import {
  fontWeightValues,
  fontFamilyValues,
  fontSizeDimensionValues,
  getTemplateBodySectionOptions,
  generateSizesList
} from "./settings";

const TemplateBodySection = ({ template, themeStore }) => {
  const { activeTab, onSwitchTab, isTabActive } = useTemplateTab("image");

  const onPropertyChange = path => data => () =>
    template.changeUsingData("config", path)(data);

  const imageTab = (
    <>
      <Div direction="column">
        <Div bottom={28}>
          <ImageUpload
            label="Upload body background image"
            labelColor={themeStore.theme.mainDarkColor}
            darkIcon
            fileName={template.config.body.image.name}
            fileSrc={template.config.body.image.src}
            onUpload={template.upload("body.image")}
            onRemove={template.removeImage("body.image")}
          />
        </Div>
        <ColorPicker
          label="Choose body background color"
          labelTextSize={14}
          horizontalLabel
          color={template.config.body.color}
          onChange={template.changeColor("body.color")}
        />
      </Div>
    </>
  );

  const buttonTab = (
    <>
      <Div bottom={28}>
        <Div grow={1} right={26}>
          <Select
            label="Font family"
            look="underlined"
            list={fontFamilyValues}
            valueKey="name"
            optionName="name"
            selectedValue={template.config.button.fontFamily}
            onChange={template.changeUsingData("config", "button.fontFamily")}
          />
        </Div>
        <Div width="100px" right={26}>
          <Select
            label="Font weight"
            look="underlined"
            list={fontWeightValues}
            valueKey="value"
            optionName="name"
            selectedValue={template.config.button.fontWeight}
            onChange={template.changeUsingData("config", "button.fontWeight")}
          />
        </Div>
      </Div>
      <Div width="200px" bottom={28}>
        <Div align="flex-end" right={50}>
          <Div right={26} width="60px">
            <Select
              look="underlined"
              label="Font size"
              list={generateSizesList(32)}
              valueKey="value"
              optionName="value"
              selectedValue={removeNonNumericCharacters(template.config.button.fontSize)}
              lowercase
              onChange={template.changeUsingData("config", "button.fontSize")}
            />
          </Div>
          <Div width="60px">
            <Select
              look="underlined"
              list={fontSizeDimensionValues}
              valueKey="value"
              optionName="value"
              selectedValue={template.config.button.fontSizeDimension}
              lowercase
              onChange={template.changeUsingData("config", "button.fontSizeDimension")}
            />
          </Div>
        </Div>
        <Select
          look="underlined"
          label="Letter spacing"
          list={generateSizesList(12)}
          valueKey="value"
          optionName="value"
          selectedValue={removeNonNumericCharacters(template.config.button.letterSpacing)}
          inputWidth="60px"
          lowercase
          onChange={template.changeUsingData("config", "button.letterSpacing")}
        />
      </Div>
      <Div bottom={28} align="center">
        <ColorPicker
          label="Text color"
          labelTextSize={14}
          horizontalLabel
          color={template.config.button.textColor}
          onChange={template.changeColor("button.textColor")}
        />
      </Div>
      <Div bottom={28}>
        <Div right={50}>
          <RadioGroup
            label="Column placement"
            popoverInner="Could be one or two columns (default: one)"
            items={getTemplateBodySectionOptions("columnPlacement", {
              selectedKey: get(template, "config.button.columnLayout", "one"),
              onClick: onPropertyChange("button.columnLayout")
            })}
          />
        </Div>
        <RadioGroup
          label="Button shape"
          popoverInner="Could be rectangular or rounded (default: rounded)"
          items={getTemplateBodySectionOptions("buttonShape", {
            selectedKey: get(template, "config.button.borderRadius", "rounded"),
            onClick: onPropertyChange("button.borderRadius")
          })}
        />
      </Div>
      <Div width="200px" bottom={28}>
        <RadioGroup
          label="Buttons alignment"
          popoverInner="Could be at the top, in the middle, at the bottom (default: top)"
          items={getTemplateBodySectionOptions("buttonsAlignment", {
            selectedKey: get(template, `config.button.alignment`, "flex-start"),
            onClick: onPropertyChange("button.alignment")
          })}
        />
      </Div>
      <Div width="100%" justify="space-between" bottom={28}>
        <Div direction="column">
          <Div align="center" justify="space-between" bottom={10} width="170px">
            <P>Stroke color</P>
            <ColorPicker
              color={template.config.button.borderColor}
              onChange={template.changeColor("button.borderColor")}
            />
          </Div>
          <Div align="center" justify="space-between">
            <P>Fill color</P>
            <ColorPicker
              color={template.config.button.backgroundColor}
              onChange={template.changeColor("button.backgroundColor")}
            />
          </Div>
        </Div>
      </Div>
      <Div bottom={28}>
        <ImageUpload
          label="Upload icons"
          labelColor={themeStore.theme.mainDarkColor}
          darkIcon
          invert
          fileNames={template.config.button.icons}
          onUpload={template.uploadMany("button.icons")}
          onRemoveFromList={template.removeFromList("button.icons")}
        />
      </Div>
      <RadioGroup
        label="Icon position"
        popoverInner="Could be before title, after title or disabled (default: disabled)"
        items={getTemplateBodySectionOptions("buttonIconPosition", {
          selectedKey: get(template, "config.button.order", "none"),
          onClick: onPropertyChange("button.order")
        })}
      />
    </>
  );

  return (
    <Div direction="column" width="100%">
      <RadioGroup
        items={getTemplateBodySectionOptions("tabs", {
          selectedKey: activeTab,
          onClick: onSwitchTab
        })}
        changeIconColor
      />
      <StyledAccordionPanelWrapper>
        {isTabActive("image") ? imageTab : null}
        {isTabActive("button") ? buttonTab : null}
      </StyledAccordionPanelWrapper>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(TemplateBodySection);
