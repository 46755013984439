export const authForm = {
  emailPlaceholder: "youremailexample@mail.com",
  emailErrorMessage: "invalid email",
  passwordPlaceholder: "password",
  passwordErrorMessage: "incorrect password",
  newPasswordPlaceholder: "new password",
  confirmPasswordPlaceholder: "confirm new password",
  newPasswordErrorMessage: "must be valid new password",
  repeatPasswordErrorMessage: "password doesn't match",
};
