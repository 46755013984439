import React from "react";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "antd";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { message } from "utils/format";
import { StyledSpan } from "./Styled";

const CopyToClipboard = ({ valueToCopy, themeStore, children }) => {
  const showMessage = () => message("Copied!", "success");

  return (
    <Tooltip title="Copy to Clipboard">
      <ReactCopyToClipboard text={valueToCopy} onCopy={showMessage}>
        <StyledSpan theme={themeStore.theme}>{children}</StyledSpan>
      </ReactCopyToClipboard>
    </Tooltip>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(CopyToClipboard);
