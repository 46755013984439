export const tableColumns = [
  {
    title: "Company admin",
    key: "fullName",
    dataIndex: "fullName",
    align: "left",
    width: 250,
    render: (_, user) => user.fullName
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
    align: "center"
  }
];
