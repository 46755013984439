import React from "react";

import Div from "components/Div";
import Icon from "components/Icon";

const LotName = ({ status, children }) => (
  <Div align="center">
    <Icon icon={status} margin="0 10px 0 0" />
    {children}
  </Div>
);

export default LotName;
