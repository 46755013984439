import React from "react";

import Image from "../Image";
import { iconTypes } from "./settings";

const Icon = ({
  icon,
  width = "16px",
  height = "16px",
  position,
  margin,
  right,
  transform,
  cursor,
  onClick = () => null,
}) => (
  <Image
    src={iconTypes[icon]}
    width={width}
    height={height}
    position={position}
    margin={margin}
    right={right}
    transform={transform}
    cursor={cursor}
    onClick={onClick}
  />
);

export default Icon;
