import styled from "styled-components";

export const StyledInnerWrapper = styled.div`
  display: flex;
  padding: 25px 25px 50px 25px;
  flex-direction: column;
  flex: ${({ flex }) => `${flex}`};
`;

export const StyledInnerTitle = styled.div`
  margin-bottom: 32px;
  font-size: 21px;
  color: ${({ theme }) => theme.mainDarkColor};
`;
