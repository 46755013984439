import React from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button, Link } from "../../actions";
import Div from "../../Div";
import SearchForm from "../../forms/Search";
import HistorySearchForm from "../../forms/HistorySearch";
import Filter from "../../Filter";
import {
  StyledHeaderWrapper,
  StyledHeaderTitle,
  StyledInnerContentWrapper
} from "./Styled";

const Header = ({
  title,
  searchPlaceholder,
  backButton,
  backButtonLink,
  backButtonFn,
  backButtonReturn,
  actionButtons = [],
  withSearch,
  search,
  onSearch,
  searchDisabled,
  withFilter,
  filterOptions,
  onFilterChange,
  themeStore,
  searchHistory,
  withHistorySearch,
}) => {
  const history = useHistory();

  return (
    <StyledHeaderWrapper theme={themeStore.theme}>
      <StyledInnerContentWrapper>
        {backButton && (
          <Div left={20}>
            {backButtonReturn ? (
              <Button type="backArrow" onClick={history.goBack} />
            ) : (
              <Link
                type="backArrow"
                to={backButtonLink}
                onClick={backButtonFn}
              />
            )}
          </Div>
        )}
        <StyledHeaderTitle theme={themeStore.theme}>{title}</StyledHeaderTitle>
      </StyledInnerContentWrapper>
      <StyledInnerContentWrapper>
        <Div>{actionButtons.map(b => b)}</Div>
        {withFilter && (
          <Filter options={filterOptions} onChange={onFilterChange} />
        )}
        {withSearch && (
          <SearchForm
            search={search}
            onSearch={onSearch}
            placeholder={searchPlaceholder}
            disabled={searchDisabled}
            theme={themeStore.theme}
          />
        )}
        {withHistorySearch && (
          <HistorySearchForm
            search={search}
            searchHistory={searchHistory}
            onSearch={onSearch}
            placeholder={searchPlaceholder}
            disabled={searchDisabled}
            theme={themeStore.theme}
          />
        )}
      </StyledInnerContentWrapper>
    </StyledHeaderWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Header);
