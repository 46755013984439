import { observable, action, computed } from "mobx";

import Company from "../models/Company";
import User from "../models/User";

class Companies {
  @observable rawCompanies = [];
  @observable rawCompany = {};
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawCompany = new Company({}, rootStore);
  }

  @computed
  get companies() {
    return this.rawCompanies;
  }

  @computed
  get company() {
    return this.rawCompany;
  }

  @computed
  get companiesWithoutCore() {
    return this.rawCompanies.filter(c => !c.isCoreCompany);
  }

  @action.bound mapUser(user) {
    if (!user) return null;
    return new User(user, this.rootStore);
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawCompanies = [];
    this.resetSelectedCompany();
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  @action.bound resetSelectedCompany() {
    return (this.rawCompany = new Company({}, this.rootStore));
  }

  @action.bound async findAll() {
    const { method, url } = this.rootStore.urls.companies.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: this.params
    });

    if (response) {
      this.rawCompanies = response.data.map(
        c => new Company({ ...c, user: this.mapUser(c.user) }, this.rootStore)
      );
    }

    return response;
  }

  @action findById = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.companies.getById;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      const company = response.data;
      this.rawCompany = new Company(
        { ...company, user: this.mapUser(company.user) },
        this.rootStore
      );
    }

    return response;
  };

  // todo: refactoring needed
  @action save = isEdit => async () => {
    if (!isEdit) {
      const { method, url } = this.rootStore.urls.companies.create;

      const body = this.rawCompany.createData;

      const errors = this.rootStore.validator.validateCompany(body);
      if (this.rootStore.hasValidationErrors(errors)) return;

      const { response } = await this.rootStore.makeRequest({
        method,
        url,
        body
      });

      if (response) {
        const company = response.data;
        this.rawCompanies.push(
          new Company(
            { ...company, user: this.mapUser(company.user) },
            this.rootStore
          )
        );
        this.rootStore.routingStore.push(`/companies/edit/${company.id}`);
      }

      return response;
    }

    const {
      method: companyMethod,
      url: companyUrl
    } = this.rootStore.urls.companies.update;
    const companyBody = this.rawCompany.updateData;

    const errors = this.rootStore.validator.validateCompany(companyBody);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const {
      method: usersUpdateMethod,
      url: usersUpdateUrl
    } = this.rootStore.urls.users.update;
    const {
      method: usersCreateMethod,
      url: usersCreateUrl
    } = this.rootStore.urls.users.create;
    const usersData = this.rootStore.usersStore.requestData;
    const usersToCreate = usersData.toCreate;
    const usersToUpdate = usersData.toUpdate;
    const hasUsersToCreate = !!usersToCreate.users.length;
    const hasUsersToUpdate = !!usersToUpdate.users.length;

    await Promise.all([
      this.rootStore.makeRequest({
        method: companyMethod,
        url: companyUrl,
        body: companyBody
      }),
      hasUsersToCreate
        ? this.rootStore.makeRequest({
            method: usersCreateMethod,
            url: usersCreateUrl,
            body: usersToCreate
          })
        : null,
      hasUsersToUpdate
        ? this.rootStore.makeRequest({
            method: usersUpdateMethod,
            url: usersUpdateUrl,
            body: usersToUpdate
          })
        : null
    ]);

    this.rootStore.routingStore.push(`/companies`);
  };

  @action deleteById = id => async () => {
    const { method, url } = this.rootStore.urls.companies.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      this.rawCompanies = this.rawCompanies.filter(c => c.id !== id);
    }

    return response;
  };

  @action enableBranding = (body) => async () => {
    const { method, url } = this.rootStore.urls.companies.update;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}`,
      body
    });

    return response;
  };
}

export default Companies;
