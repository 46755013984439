import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Icon from "../Icon";
import { Switch } from "../inputs";
import AccordionHeader from "./Header";
import { StyledCollapse, StyledPanel, StyledSwitcherWrapper } from "./Styled";

const Accordion = ({
  children,
  defaultActiveKey = ["0"],
  separatorWidth,
  titles = {},
  look,
  themeStore,
  withHeaderSwitch,
}) => {
  const onSwitch = (cb, value) => () => cb(value);

  return (
    <StyledCollapse
      defaultActiveKey={defaultActiveKey}
      bordered={false}
      separatorWidth={separatorWidth}
      expandIcon={() => <Icon icon="dropDownArrow" width="16px" height="9px" />}
      look={look}
      theme={themeStore.theme}
      withHeaderSwitch={withHeaderSwitch}
    >
      {children.filter(Boolean).map((element, i) => (
        <StyledPanel
          key={i}
          header={<AccordionHeader look={look}>{titles[element.props.feature]}</AccordionHeader>}
          isLastChild={children.length === i + 1}
          look={look}
          theme={themeStore.theme}
          extra={
            withHeaderSwitch ? (
              <StyledSwitcherWrapper onClick={(e) => e.stopPropagation()}>
                <Switch
                  size="small"
                  checked={!element.props.disabled}
                  onClick={onSwitch(
                    element.props.onSwitch,
                    !element.props.disabled
                  )}
                />
              </StyledSwitcherWrapper>
            ) : null
          }
        >
          {element}
        </StyledPanel>
      ))}
    </StyledCollapse>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Accordion);
