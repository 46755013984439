import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledUsersAmount } from "./Styled";

const CompanyAdmin = ({ row: { user, users }, themeStore }) => (
  <span>
    {user && user.fullName}
    <StyledUsersAmount theme={themeStore.theme}>
      {users > 1 ? ` +${users - 1}` : ""}
    </StyledUsersAmount>
  </span>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(CompanyAdmin);
