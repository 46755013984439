import React, { useState, useEffect, Children, cloneElement } from "react";
import { inject, observer } from "mobx-react";

import ConstraintModal from "./ConstraintModal";
import { compose } from "utils/hoc";

const Constraint = ({
  constraintsStore,
  constraintGetter,
  capacityGetter,
  amount,
  children
}) => {
  const { canActivate, showActionModal } = constraintsStore;

  const [showChildren, setShowChildren] = useState(false);
  const [showPageModal, setShowPageModal] = useState(false);

  useEffect(() => {
    if (!constraintGetter) return;

    const canActivatePage = canActivate({
      getter: constraintGetter,
      target: "page",
      capacityGetter,
      amount
    });
    const wrapper = document.querySelector("#scrollbars > div");
    wrapper.style.overflow = "scroll";

    if (!canActivatePage) {
      setShowPageModal(true);
      wrapper.style.overflow = "hidden";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraintGetter, capacityGetter, amount]);

  useEffect(() => {
    const timer = setTimeout(() => setShowChildren(true), 300);
    return () => clearTimeout(timer);
  }, []);

  const clonedChildren = Children.map(children, child =>
    cloneElement(child, { constraintGetter })
  );

  return (
    <>
      {
        <ConstraintModal
          showModal={showPageModal || showActionModal}
          zIndex={showPageModal ? 999 : 9999}
          constraint
        />
      }
      {showChildren && clonedChildren}
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    constraintsStore: rootStore.constraintsStore
  })),
  observer
)(Constraint);
