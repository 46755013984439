import React from "react";
import QRCode from "qrcode.react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledWrapper } from "./Styled";

const Qrcode = ({ src = "", size = 238, level = "L", themeStore }) => (
  <StyledWrapper theme={themeStore.theme}>
    <QRCode value={src} size={size} level={level} includeMargin={true} />
  </StyledWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Qrcode);
