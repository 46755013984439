import { toNumber } from "lodash";

export const getLotDetailsConfig = (entity = {}) => [
  {
    title: "NFC",
    icon: "nfc",
    value: toNumber(entity.nfcTags) || 0,
  },
  {
    title: "QRC",
    icon: "qrc",
    value: toNumber(entity.qrcTags) || 0,
  },
  {
    title: "Total",
    icon: "lotDescriptionTotal",
    width: "32px",
    value: toNumber(entity.nfcTags + entity.qrcTags) || 0,
  },
];
