import React from "react";
import { Tooltip } from "antd";

import Div from "components/Div";
import P from "components/P";

const FieldTitle = ({ title }) => (
  <Div justify="flex-end">
    <Label title={title + ":"} />
  </Div>
);

const FieldValue = ({ value }) => (
  <Div styles={{ whiteSpace: "nowrap" }}>
    <Label bold title={value} />
  </Div>
);

const Label = ({ bold = false, title }) => (
  <P color="currentcolor" weight={bold ? "bold" : "normal"} size={12}>
    {title}
  </P>
);

const Grid = ({ children }) => (
  <Div
    display="grid"
    styles={{
      gridTemplateColumns: "1fr 3fr",
      columnGap: "7px"
    }}
  >
    {children}
  </Div>
);

const Row = ({ title, value }) =>
  value ? (
    <>
      <FieldTitle title={title} />
      <FieldValue value={value} />
    </>
  ) : null;

export const ValueWithDetail = ({ value, detailFields, detailValues }) => (
  <Tooltip
    title={
      <Grid>
        {detailFields.map((f, i) => (
          <Row key={f + i} title={f} value={detailValues[i]} />
        ))}
      </Grid>
    }
  >
    {value}
  </Tooltip>
);

export const Value = ({ value }) => (
  <Tooltip title={<FieldValue value={value} />}>{value}</Tooltip>
);
