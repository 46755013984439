import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Select } from "../inputs";
import Form from "../Form";

const AppContext = ({
  rootStore: { validationErrors },
  companiesStore: { companiesWithoutCore },
  selectedCompanyId,
  setSelectedCompanyId
}) => {
  const filterOption = (input, option) => {
    return (option?.props?.name || '').toLowerCase().includes(input.toLowerCase());
  }

  return (
    <Form width="60%">
      <Select
        filterOption={filterOption}
        showSearch
        name="companyId"
        list={companiesWithoutCore}
        selectedValue={selectedCompanyId}
        error={validationErrors.companyId}
        errorMessage="Please, select company to switch"
        onChange={setSelectedCompanyId}
      />
    </Form>
  )
}

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    rootStore
  })),
  observer
)(AppContext);
