export const isFeatureEnabled = (feature) => {
  const featureMapping = {
    aiSearch: process.env.REACT_APP_FEATURE_AISEARCH,
    whiteLabeling: process.env.REACT_APP_FEATURE_WHITELABELING,
    hideLotNotifiers: process.env.REACT_APP_HIDE_LOT_NOTIFIERS,
    hidePoweredBy: process.env.REACT_APP_HIDE_POWERED_BY_BRANDING,
  }

  return featureMapping[feature] === 'yes';
};
