import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledAsterix } from "./styled";

const Asterix = ({ themeStore }) => (
  <StyledAsterix theme={themeStore.theme}>*</StyledAsterix>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Asterix);
