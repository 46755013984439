import { observable, action, computed, toJS } from "mobx";
import { isString } from "lodash";

import ExperienceStep from "./ExperienceStep";
import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "description",
  "steps.length",
  "template.id",
  "isRepeatable",
  "isPreciseLocation"
];

class Experience extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable description = "";
  @observable steps = [];
  @observable template = {};
  @observable isRepeatable = true;
  @observable isPreciseLocation = true;
  @observable preview = "";
  @observable type = {};
  @observable lot = null;
  company = {};

  constructor(e, rootStore) {
    super(PATH_LIST_TO_OBSERVE, e);

    this.rootStore = rootStore;

    Object.assign(this, {
      ...e,
      steps: e && e.steps ? e.steps.map(this.assignSteps) : [],
      template: e && e.template ? e.template : rootStore.templatesStore.default
    });
  }

  @action.bound resetPreview() {
    return (this.preview = "");
  }

  @action.bound assignSteps(s) {
    if (s.experienceId) {
      return new ExperienceStep(
        { ...s, isMenuLink: true },
        this.rootStore,
        this.showPreview,
        this.setPreview
      );
    }

    return new ExperienceStep(
      { ...s },
      this.rootStore,
      this.showPreview,
      this.setPreview
    );
  }

  @computed
  get createMenuData() {
    return toJS({
      name: this.name,
      description: this.description,
      steps: this.steps.map(s => s.createMenuStep),
      typeName: "menu",
      templateId: this.template.id,
      isPreciseLocation: this.isPreciseLocation
    });
  }

  @computed
  get updateMenuData() {
    return toJS({
      experienceId: this.id,
      name: this.name,
      description: this.description,
      steps: this.steps.map(s => s.createMenuStep),
      templateId: this.template.id,
      isPreciseLocation: this.isPreciseLocation,
      type: this.type
    });
  }

  @computed
  get createSequenceData() {
    return toJS({
      name: this.name,
      description: this.description,
      steps: this.steps.map(s => s.createSequenceStep),
      isRepeatable: this.isRepeatable,
      isPreciseLocation: this.isPreciseLocation,
      typeName: "sequence"
    });
  }

  @computed
  get updateSequenceData() {
    return toJS({
      experienceId: this.id,
      name: this.name,
      description: this.description,
      steps: this.steps.map(s => s.createSequenceStep),
      isRepeatable: this.isRepeatable,
      isPreciseLocation: this.isPreciseLocation,
      type: this.type
    });
  }

  @action.bound async showPreview(experienceId) {
    if (!this.steps.length) return;

    let experienceSteps = this.steps;
    let experienceTemplate = this.template;

    if (isString(experienceId)) {
      const experience = this.rootStore.experiencesStore.menuExperiences.find(
        e => e.id === experienceId
      );
      experienceSteps = experience ? experience.steps : [];
      experienceTemplate = experience ? experience.template : {};
    }

    const mapSteps = ({ buttonName, url, buttonIcon }) => ({
      buttonName,
      url,
      buttonIcon
    });

    const getPreviewURL = async ({ id }, steps) => {
      const { method, url } = this.rootStore.urls.experiences.createPreview;
      const { response } = await this.rootStore.makeRequest({
        method,
        url,
        body: { steps: steps.map(mapSteps) }
      });

      return `?templateId=${id}&previewId=${response.data}`;
    };

    const data = await getPreviewURL(experienceTemplate, experienceSteps);

    this.preview = `${process.env.REACT_APP_MENU_PREVIEW_HOST}${data}`;

    return { url: this.preview };
  }

  @action.bound setPreview(data) {
    this.preview = data;
  }

  @action.bound changeName(event) {
    this.name = event.target.value;
  }

  @action.bound changeDescription(event) {
    this.description = event.target.value;
  }

  @action.bound changeTemplate(templateId) {
    const template = this.rootStore.templatesStore.templates.find(
      t => t.id === templateId
    );

    if (template) {
      const { id, name, config } = template;
      this.template = { id, name, config };
      this.steps.forEach(s => s.resetIcon());
    }
  }

  @action.bound addStep() {
    this.steps.push(
      new ExperienceStep({}, this.rootStore, this.showPreview, this.setPreview)
    );
  }

  @action.bound addMenuLink() {
    this.steps.push(
      new ExperienceStep(
        { isMenuLink: true },
        this.rootStore,
        this.showPreview,
        this.setPreview
      )
    );
  }

  @action deleteStep = stepId => () => {
    this.steps = this.steps.filter(s => s.id !== stepId);
  };

  @action.bound changeRepeatable() {
    this.isRepeatable = !this.isRepeatable;
  }

  @action.bound changePreciseLocation() {
    this.isPreciseLocation = !this.isPreciseLocation;
  }
}

export default Experience;
