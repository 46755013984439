import React from "react";

import Div from "components/Div";
import Icon from "components/Icon";
import { NotificationBox } from "./Styled";

const InfoBox = ({ theme, children, width, bottom, maxWidth }) => (
  <NotificationBox theme={theme} width={width} maxWidth={maxWidth} bottom={bottom}>
    <Div>
      <Icon icon="info" width="16px" height="16px" margin="3px 10px 0 0" />
      <Div display="block">
        {children}
      </Div>
    </Div>
  </NotificationBox>
);

export default InfoBox;
