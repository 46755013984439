import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import P from "components/P";
import { Text, TextArea, Checkbox } from "components/inputs";
import Form from "components/Form";

const EditSequenceExperience = ({
  experiencesStore: { experience },
  rootStore: { validationErrors }
}) => (
  <Form width="700px">
    <Div direction="column" width="50%" right={25}>
      <Div bottom={25}>
        <Text
          label="Experience name"
          value={experience.name}
          error={validationErrors.name}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={experience.changeName}
          required
        />
      </Div>
      <Div>
        <TextArea
          label="Description"
          name="description"
          value={experience.description}
          error={validationErrors.description}
          errorMessage="Must be less than 250 symbols"
          onChange={experience.changeDescription}
        />
      </Div>
    </Div>
    <Div width="50%">
      <Div direction="column" width="18vw">
        <P bottom={12}>Experience telemetry</P>
        <Checkbox
          title="Request precise GPS location from device"
          large
          checked={experience.isPreciseLocation}
          onChange={experience.changePreciseLocation}
        />
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    experiencesStore: rootStore.experiencesStore,
    templatesStore: rootStore.templatesStore,
    rootStore
  })),
  observer
)(EditSequenceExperience);
