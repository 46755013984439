import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Table from "components/Table";
import Modal from "components/Modal";
import Div from "components/Div";
import { Button } from "components/actions";
import { useModal } from "components/hooks";
import { useModalWithValidation } from "components/hooks";
import EditUserForm from "features/companies/forms/EditUser";
import { tableColumns } from "./settings.js";

const List = ({ usersStore, match }) => {
  const {
    rootStore,
    users,
    user,
    findById,
    deleteById,
    resetUser,
    save
  } = usersStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel
  ] = useModal();
  const [
    isResendModalShown,
    setResendModalShown,
    onResendModalOk,
    onResendModalCancel
  ] = useModal();
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOk,
    onEditModalCancel
  ] = useModalWithValidation(rootStore);

  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("Create");
  const setUser = id => () => {
    setUserId(id);
    setUserStatus("update");
  };
  const unsetUser = () => {
    setUserId("");
    setUserStatus("create");
    resetUser();
  };
  const { id } = match.params;
  const isEditRoute = !!id;

  useEffect(() => {
    const onMount = async () => {
      if (userId) {
        await findById({ id: userId })();
      }
    };
    onMount();
  }, [findById, userId]);

  const renderActions = row => (
    <>
      <Button
        type="popoverRegular"
        onClick={setEditModalShown(setUser(row.id))}
      >
        Edit
      </Button>
      <Button
        type="popoverRegular"
        onClick={setResendModalShown(setUser(row.id))}
      >
        Resend credentials
      </Button>
      <Button
        type="popoverRedText"
        onClick={setDeleteModalShown(setUser(row.id))}
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title={`Please, ${userStatus} a company admin`}
        showModal={isEditModalShown}
        confirmButtonTitle={userStatus}
        onCancel={onEditModalCancel()}
        onConfirm={onEditModalOk(save(!!userId))}
      >
        <EditUserForm />
      </Modal>
      <Modal
        title="Delete user"
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel(unsetUser)}
        onConfirm={onDeleteModalOk(deleteById(user.id))}
      >
        {`Are you sure you want to delete ${user.fullName}?`}
      </Modal>
      <Modal
        title="Resend credentials"
        showModal={isResendModalShown}
        confirmButtonTitle="Resend"
        onCancel={onResendModalCancel()}
        onConfirm={onResendModalOk(user && user.resendCredentials)}
      >
        {`Resend credentials for ${user.fullName}?`}
      </Modal>
      <Div bottom={25}>
        <Button
          type="purpleUnderlined"
          onClick={setEditModalShown(unsetUser)}
          disabled={!isEditRoute}
        >
          + Create new
        </Button>
      </Div>
      <Table
        columns={tableColumns}
        data={users}
        renderActions={renderActions}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    usersStore: rootStore.usersStore
  })),
  observer
)(List);
