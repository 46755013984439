import styled from "styled-components";

export const StyledForm = styled.form`
  position: relative;
  display: ${({ display }) => `${display || "flex"}`};
  width: ${({ width }) => (width ? `${width}` : "auto")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  flex-direction: ${({ direction }) => `${direction || "row"}`};
  justify-content: ${({ justify }) => `${justify || "normal"}`};
  align-items: ${({ align }) => `${align || "normal"}`};
  flex-grow: ${({ grow }) => `${grow || 0}`};
  margin-top: ${({ top }) => (top ? `${top}px` : 0)};
  margin-right: ${({ right }) => (right ? `${right}px` : 0)};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : 0)};
  margin-left: ${({ left }) => (left ? `${left}px` : 0)};
  padding-top: ${({ paddingTop }) => paddingTop || 0};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};
  padding-right: ${({ paddingRight }) => paddingRight || 0};
  padding-left: ${({ paddingLeft }) => paddingLeft || 0};
  line-height: 1.5;
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
`;