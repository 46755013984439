import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledP } from "./Styled";

const P = ({
  position,
  display,
  width,
  justify,
  align,
  grow,
  direction,
  top,
  right,
  bottom,
  left,
  size,
  weight,
  color,
  uppercase = false,
  textAlign,
  lineHeight,
  flexWrap,
  themeStore,
  children,
}) => (
  <StyledP
    position={position}
    display={display}
    width={width}
    direction={direction}
    justify={justify}
    align={align}
    grow={grow}
    top={top}
    right={right}
    bottom={bottom}
    left={left}
    size={size}
    weight={weight}
    color={color}
    uppercase={uppercase}
    textAlign={textAlign}
    lineHeight={lineHeight}
    flexWrap={flexWrap}
    theme={themeStore.theme}
  >
    {children}
  </StyledP>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(P);
