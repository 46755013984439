import React from "react";

import Icon from "components/Icon";

export const columns = [
  {
    title: "Agent",
    key: "name",
    dataIndex: "name",
    width: "30ch"
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description"
  },
  {
    title: "Active",
    key: "active",
    dataIndex: "active",
    width: "75px",
    align: "center",
    render: (_, row) => <Icon icon={row.active ? "active" : "inactive"} />
  }
];
