import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = ["firstName", "lastName", "email"];

class User extends DirtyState {
  @observable id = "";
  @observable firstName = "";
  @observable lastName = "";
  @observable email = "";
  @observable company = {};
  @observable isFirstLogin = false;
  role = "";
  isNew = false;
  isUpdated = false;

  constructor(user, rootStore) {
    super(PATH_LIST_TO_OBSERVE, user);

    this.rootStore = rootStore;
    Object.assign(this, user);
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get createData() {
    return toJS({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      companyId: this.rootStore.companiesStore.company.id
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    });
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }

  @action.bound async resendCredentials() {
    const { method, url } = this.rootStore.urls.users.credentials;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${this.id}`
    });

    if (response) {
      this.rootStore.message("Credentials sent to the user.", "success");
    }
  }
}

export default User;
