import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "components/P";
import Div from "components/Div";
import ForgotPasswordForm from "features/authorization/forms/ForgotPassword";
import LoginForm from "features/authorization/forms/Login";
import ForgotPasswordResetForm from "features/authorization/forms/ForgotPasswordReset";
import CompanyLogo from "features/miscellany/CompanyLogo";
import LogoWithTagline from "features/miscellany/LogoWithTagline";
import { StyledPageWrapper, StyledTopBlock } from "./Styled";
import {
  loginTitle,
  forgotPasswordTitle,
  forgotPasswordResetTitle
} from "./settings";

const Auth = ({ match, authStore, themeStore }) => {
  const isLogin = match.path === "/login";
  const isForgotPassword = match.path === "/forgot-password";
  const isForgotPasswordReset = match.params.hasOwnProperty("token");
  const { token } = match.params;
  
  useEffect(() => {
    if (token) {
      authStore.forgotPasswordTokenValidation(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPageWrapper theme={themeStore.theme}>
      <StyledTopBlock theme={themeStore.theme}>
        <Div direction="column" align="center">
          <LogoWithTagline
            logoTheme="light"
            poweredByTop={25}
            poweredByBottom={0}
            poweredByLogoWidth={146}
            poweredByLogoHeight={24}
            poweredByTextSize={14}
            poweredByLineHeight={17}
            sloganTop={28}
            sloganBottom={0}
            sloganTextSize={28}
            productLogoWidth={450}
          />
        </Div>
      </StyledTopBlock>
      <P
        top={40}
        size={26}
        color={themeStore.theme.mainDarkColor}
        textAlign="center"
      >
        {isLogin && loginTitle}
        {isForgotPassword && forgotPasswordTitle}
        {isForgotPasswordReset && forgotPasswordResetTitle}
      </P>
      {isLogin && <LoginForm />}
      {isForgotPassword && <ForgotPasswordForm />}
      {isForgotPasswordReset ? <ForgotPasswordResetForm token={token} /> : <br />}
      <CompanyLogo />
    </StyledPageWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(Auth);
