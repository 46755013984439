import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Icon from "../../Icon";
import { StyledSelectionCell, StyledExperienceTypeCell } from "./Styled";

const Cell = ({ cellData, dataKey, rowData, themeStore }) => {
  const data = cellData || null;

  if (!rowData) return data;

  const { selected, isFirstSelected, isLastSelected, type } = rowData;

  switch (dataKey) {
    case "selected": {
      return (
        <StyledSelectionCell
          isSelected={selected}
          isFirstSelected={isFirstSelected}
          isLastSelected={isLastSelected}
          theme={themeStore.theme}
        />
      );
    }
    // todo: refactoring needed
    case "type.name": {
      return (
        <StyledExperienceTypeCell>
          <Icon
            icon={selected ? `${type.name}Selected` : type.name}
            margin="0 12px 0"
          />
          {type.name}
        </StyledExperienceTypeCell>
      );
    }
    default:
      return data;
  }
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Cell);
