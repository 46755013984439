import React, { useState, useRef, useEffect } from "react";
import { AutoSizer, Column } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars";
import { get, isFunction } from "lodash";
import { inject, observer } from "mobx-react";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import Row from "./Row";
import Cell from "./Cell";
import { StyledTable, StyledTableWrapper, StyledVerticalTrack } from "./Styled";

const Table = ({
  themeStore,
  columns,
  data,
  selectedIndex,
  headerHeight = 58,
  rowHeight = 58,
  rowCount = 8,
  onSort,
  onRowSelect = () => null,
  onResetSelectedIndex = () => null,
  onScroll = () => null
}) => {
  const tableScroll = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    if (onScroll) {
      onScroll({ scrollTop, scrollHeight, clientHeight })
    }

    setScrollTop(scrollTop)
  };

  useEffect(() => {
    if (tableScroll.current && selectedIndex) {
      const { current } = tableScroll;
      current.scrollTop(rowHeight * selectedIndex);
    }

    if (selectedIndex) onResetSelectedIndex();
  }, [onResetSelectedIndex, rowHeight, selectedIndex]);

  const numRows = isMobile ? 6 : rowCount;

  return (
    <StyledTableWrapper height={rowHeight * numRows + headerHeight}>
      <AutoSizer>
        {({ height, width }) => (
          <Scrollbars
            ref={tableScroll}
            style={{ height, width }}
            onScroll={handleScroll}
            renderThumbVertical={props => <StyledVerticalTrack theme={themeStore.theme} {...props} />}
            className="table-virtualized__scrollbars-wrapper"
          >
            <StyledTable
              autoHeight
              width={width}
              height={height}
              scrollTop={scrollTop}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              rowCount={numRows > data.length ? numRows : data.length}
              rowGetter={({ index }) => data[index]}
              rowRenderer={props => <Row {...props} onClick={onRowSelect} />}
              theme={themeStore.theme}
            >
              {columns.map(({ key, dataIndex, title, width = 1, align }) => (
                <Column
                  key={key}
                  dataKey={dataIndex}
                  label={isFunction(title) ? title(onSort) : title}
                  cellDataGetter={({ dataKey, rowData }) =>
                    get(rowData, dataKey)
                  }
                  cellRenderer={props => <Cell {...props} />}
                  flexGrow={width}
                  width={width}
                  headerStyle={{ justifyContent: align }}
                  style={{ justifyContent: align }}
                />
              ))}
            </StyledTable>
          </Scrollbars>
        )}
      </AutoSizer>
    </StyledTableWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Table);

export { default as ColumnTitle } from "./ColumnTitle";
export { default as useRowSelect } from "./hooks/useRowSelect";
