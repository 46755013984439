import { observable, reaction } from "mobx";
import { get, isEmpty, isEqual } from "lodash";

class DirtyState {
  initialValues = [];
  @observable isDirty = false;

  constructor(path = [], entity = {}) {
    this.initialValues = path.map(path =>
      get(isEmpty(entity) ? this : entity, path, ""),
    );

    reaction(
      () => path.map(path => get(this, path, "")),
      d =>
        (this.isDirty = this.initialValues.some((v, i) => {
          if (v instanceof Object && d[i] instanceof Object) {
            return !isEqual(
              Object.values(v).sort(),
              Object.values(d[i]).sort(),
            );
          }

          return v !== d[i];
        })),
    );
  }
}

export default DirtyState;
