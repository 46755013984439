import { useState } from "react";

export default ({ onPageChange, pageSize = 20, limit = 20, isScrollPagination = false }) => {
  const [currentPage, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const reset = () => {
    setPage(1);
    setOffset(0);
    setTotal(0);
  };

  const config = {
    current: currentPage,
    offset,
    total,
    setTotal,
    pageSize,
    limit,
    onChange: page => handlePageChange(page)
  };

  const handlePageChange = page => {
    const currentOffset = (page - 1) * pageSize;

    setOffset(currentOffset);
    setPage(page);
    onPageChange({ offset: currentOffset, limit, setTotal, isScrollPagination });
  };

  return [config, reset];
};
