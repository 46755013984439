import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Div from "components/Div";
import withCancelModal from "components/withCancelModal";
import { Header, Inner } from "components/Content";
import Constraint from "components/Constraint";
import IFrame from "features/miscellany/previews/IFrame";
import EditTemplateForm from "features/templates/forms/EditTemplate";
import TemplateStepsForm from "features/templates/forms/TemplateSteps";

const Edit = ({ templatesStore, match, constraintGetter, onLeavePage }) => {
  const { template, findAll, findById, save } = templatesStore;
  const { id } = match.params;
  const isEditRoute = !!id;
  const innerTitle = isEditRoute ? "Edit Template" : "Create Template";

  useEffect(() => {
    const onMount = async () => {
      await findAll();

      if (id) {
        await findById({ id })();
      }
    };

    onMount();
  }, [findAll, findById, id]);

  useEffect(() => {
    if (isEditRoute && template.id) {
      template.showPreview();
    } else if (!isEditRoute) {
      template.showPreview();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.id]);

  return (
    <Constraint
      constraintGetter={constraintGetter}
      capacityGetter="templates.capacity"
      amount={templatesStore.templates.length}
    >
      <Header
        title={innerTitle}
        backButton
        backButtonFn={onLeavePage(template.isDirty)}
        backButtonLink="/templates"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button type="regularFlat" onClick={onLeavePage(template.isDirty)}>
              Cancel
            </Button>
            <Button type="regular" onClick={save(isEditRoute)}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Inner>
        <Div>
          <Div width="26%">
            <EditTemplateForm />
          </Div>
          <Div width="30%" left="50" right="50" justify="center">
            <IFrame src={template.previewUrl} />
          </Div>
          <Div width="40%">
            <TemplateStepsForm isEditRoute={isEditRoute} />
          </Div>
        </Div>
      </Inner>
    </Constraint>
  );
};

export default compose(
  withCancelModal("/templates"),
  inject(({ rootStore }) => ({
    templatesStore: rootStore.templatesStore,
    rootStore
  })),
  observer
)(Edit);
