import active from "assets/images/ic_active.svg";
import inactive from "assets/images/ic_inactive.svg";
import circleStep from "assets/images/ic_bullet.svg";
import dropDownArrow from "assets/images/ic_dropdown.svg";
import uploadedImage from "assets/images/downloaded-image.svg";
import statusSelected from "assets/images/Checkbox.svg";
import sort from "assets/images/ic_sorting.svg";
import filter from "assets/images/filter.svg";
import nfc from "assets/images/nfc.svg";
import qrc from "assets/images/qr-code.svg";
import previewSwitchIframe from "assets/images/ic_phone_black.svg";
import previewSwitchQrc from "assets/images/ic_eye_black.svg";
import previewSwitchIframeSelected from "assets/images/ic_phone_white.svg";
import previewSwitchQrcSelected from "assets/images/ic_eye_white.svg";
import previewIcon from "assets/images/ic_eye.svg";
import menu from "assets/images/ic_menu.svg";
import sequence from "assets/images/ic_sequence.svg";
import menuSelected from "assets/images/ic_menu_selected.svg";
import sequenceSelected from "assets/images/ic_sequence_selected.svg";
import menuPurple from "assets/images/ic_menu_purple.svg";
import sequencePurple from "assets/images/ic_sequence_purple.svg";
import iconDefaultSearch from "assets/images/ic_default_search.svg";
import iconDescriptionSearch from "assets/images/ic_description_search.svg";
import lotDescriptionTotal from "assets/images/sigma.svg";
import dots from "assets/images/ic_actions.svg";
import profile from "assets/images/user.svg";
import info from "assets/images/ic_info.svg";
import templateFont from "assets/images/template-font.png";
import templateImage from "assets/images/template-image.png";
import templateText from "assets/images/template-text.png";
import templateFontSelected from "assets/images/template-font-selected.svg";
import templateImageSelected from "assets/images/template-image-selected.svg";
import templateTextSelected from "assets/images/template-text-selected.svg";
import templateAlignmentTop from "assets/images/template-alignment-top.png";
import templateAlignmentCenter from "assets/images/template-alignment-center.png";
import templateAlignmentBottom from "assets/images/template-alignment-bottom.png";
import templateTextLeft from "assets/images/template-text-left.png";
import templateTextCenter from "assets/images/template-text-center.png";
import templateTextRight from "assets/images/template-text-right.png";
import templateButton from "assets/images/template-button.svg";
import templateButtonSelected from "assets/images/template-button-selected.svg";
import templateButtonIconAlignmentLeft from "assets/images/template-button-icon-alignment-left.png";
import templateButtonIconAlignmentRight from "assets/images/template-button-icon-alignment-right.png";
import templateButtonIconDisabled from "assets/images/template-button-icon-disabled.png";
import templateOneColumn from "assets/images/template-one-column.png";
import templateTwoColumn from "assets/images/template-two-column.png";
import uploadIconLight from "assets/images/upload-image.png";
import uploadIconDark from "assets/images/upload-image-dark.png";
import templateButtonRectangular from "assets/images/template-button-rectangular.png";
import templateButtonRounded from "assets/images/template-button-rounded.png";
import question from "assets/images/ic_question.svg";

export const iconTypes = {
  active,
  inactive,
  circleStep,
  dropDownArrow,
  uploadedImage,
  statusSelected,
  sort,
  filter,
  nfc,
  qrc,
  previewSwitchIframe,
  previewSwitchQrc,
  previewSwitchIframeSelected,
  previewSwitchQrcSelected,
  previewIcon,
  menu,
  menuSelected,
  menuPurple,
  sequence,
  sequenceSelected,
  sequencePurple,
  iconDefaultSearch,
  iconDescriptionSearch,
  lotDescriptionTotal,
  dots,
  profile,
  info,
  templateFont,
  templateImage,
  templateText,
  templateFontSelected,
  templateImageSelected,
  templateTextSelected,
  templateAlignmentTop,
  templateAlignmentCenter,
  templateAlignmentBottom,
  templateTextLeft,
  templateTextCenter,
  templateTextRight,
  templateButton,
  templateButtonSelected,
  templateButtonIconAlignmentLeft,
  templateButtonIconAlignmentRight,
  templateButtonIconDisabled,
  templateOneColumn,
  templateTwoColumn,
  uploadIconLight,
  uploadIconDark,
  templateButtonRectangular,
  templateButtonRounded,
  question,
};
