import styled from "styled-components";
import { Input } from "antd";

export const StyledInput = styled(Input)`
  width: 100% !important;
  height: 44px !important;
  color: ${({ theme }) => theme.mainDarkColor} !important;
  border: 1px solid
    ${({ error, theme }) =>
      `${
        error === "true" ? theme.errorColor : theme.secondaryLightColor
      }`} !important;
  border-radius: ${({ theme }) => theme.borderRadius} !important;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .ant-input[disabled] {
    background-color: transparent !important;
    border-color: transparent !important;
    color: ${({ theme }) => theme.mainDarkColor} !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
