import { observable } from "mobx";

class Sidebar {
  @observable state = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}

export default Sidebar;
