import React from "react";

import { SearchWithSelect } from "components/inputs";
import Form from "components/Form";
import Div from "components/Div";
import Filter from "components/Filter";

const FirstRangeStep = ({
  title,
  data = [],
  filterOptions = [],
  searchPopupOptions = [],
  onFilterChange,
  onSearchSelect,
  onSearch
}) => (
  <Form
    width="100%"
    align="center"
    justify="space-between"
    paddingLeft={15}
    paddingRight={15}
    bottom={15}
  >
    <Div>{title}</Div>
    <Div align="center">
      <Div right={15}>
        <Filter options={filterOptions} onChange={onFilterChange} />
      </Div>
      <SearchWithSelect
        onSearch={onSearch}
        data={data}
        dataKeys={searchPopupOptions.map(({ key }) => key)}
        resultsPopupOptions={searchPopupOptions}
        placeholder="Search by roll id or description"
        onSelect={onSearchSelect}
      />
    </Div>
  </Form>
);

export default FirstRangeStep;
