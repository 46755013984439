import styled from "styled-components";
import { Input, Icon } from "antd";

export const StyledSearch = styled(Input)`
  width: 100% !important;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  input {
    width: 100%;
    height: 44px !important;
    padding-left: 40px !important;
    color: ${({ theme }) => theme.mainDarkColor} !important;
    border: 1px solid ${({ theme }) => theme.secondaryLightColor} !important;
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }

  input::placeholder {
    color: ${({ theme }) => theme.mainDarkColor} !important;
  }
`;

export const StyledSearchIcon = styled(Icon)`
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;
