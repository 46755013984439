import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text, TextArea, Select } from "components/inputs";
import P from "components/P";
import Form from "components/Form";
import AssignExperience from "../AssignExperience";
import {Button} from "../../../components/actions";

const EditLot = ({
  lot,
  rootStore: { validationErrors },
  lotStatuses,
  showExperienceModal,
  deleteExperience,
  onLeavePage
}) => (
  <Form width="85vmin" gap={25}>
    <Div direction="column" width="50%">
      <Div bottom={25}>
        <Text
          label="Lot name"
          name="name"
          value={lot.name}
          error={validationErrors.name}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={lot.change}
          required
        />
      </Div>
      <Div>
        <TextArea
          label="Description"
          name="description"
          value={lot.description}
          error={validationErrors.description}
          errorMessage="Must be less than 250 symbols"
          onChange={lot.change}
        />
      </Div>
    </Div>
    <Div direction="column" width="50%">
      <Div bottom={25}>
        <Select
          label="Lot status"
          list={lotStatuses}
          selectedValue={lot.status}
          valueKey={"status"}
          iconKey="status"
          optionName={"status"}
          error={validationErrors.status}
          errorMessage="Choose LOT status"
          onChange={lot.changeLotStatus}
          required
        />
      </Div>
      <Div direction="column">
        <P bottom={12}>Lot experience</P>
        <AssignExperience
          experience={lot.experience}
          onSet={showExperienceModal}
          onDelete={deleteExperience}
        />
      </Div>
    </Div>
    <Div direction="column">
      <Div top={30}>
        <Button type="regularFlat" onClick={onLeavePage(lot.isDirty, `/lots/optics/${lot.id}`)} styles={{height: '44px', lineHeight: '22px'}}>
          Optics
        </Button>
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(EditLot);
