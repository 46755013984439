import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text } from "components/inputs";
import Form from "components/Form";

const EditUser = ({
  usersStore: { user },
  rootStore: { validationErrors }
}) => (
  <Form direction="column" width="60%">
    <Div bottom={25}>
      <Text
        label="First name"
        value={user.firstName}
        name="firstName"
        error={validationErrors.firstName}
        errorMessage="Must be more than 1 and less than 50 symbols"
        onChange={user.change}
        required
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Last name"
        value={user.lastName}
        name="lastName"
        error={validationErrors.lastName}
        errorMessage="Must be more than 1 and less than 50 symbols"
        onChange={user.change}
        required
      />
    </Div>
    <Div>
      <Text
        label="Email"
        value={user.email}
        name="email"
        disabled={!!user.createdAt}
        error={validationErrors.email}
        errorMessage="Email not valid or not unique"
        onChange={user.change}
        required
      />
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    usersStore: rootStore.usersStore,
    rootStore
  })),
  observer
)(EditUser);
