import React from "react";

import { SelectButton } from "../../actions";
import Div from "../../Div";
import P from "../../P";
import Popover from "../../Popover";

const RadioGroup = ({
  items,
  label = "",
  labelTextSize = 14,
  popoverInner = null,
  changeIconColor = false,
  disabled = false,
}) => {
  const getSelectedType = ({ key, selectedKey, ...item }, type) =>
    key === selectedKey ? `${item[type]}Selected` : item[type];

  return (
    <Div direction="column">
      <Div align="center" bottom={label || popoverInner ? 10 : 0}>
        {label ? <P size={labelTextSize}>{label}</P> : null}
        {popoverInner ? (
          <Popover.InfoPopover>{popoverInner}</Popover.InfoPopover>
        ) : null}
      </Div>
      <Div width="100%">
        {items.map((item) => (
          <SelectButton
            key={item.key}
            disabled={disabled}
            icon={changeIconColor ? getSelectedType(item, "icon") : item.icon}
            iconWidth={item.iconWidth}
            iconHeight={item.iconHeight}
            title={item.title}
            type={getSelectedType(item, "type")}
            onClick={item.onClick(item.key)}
          />
        ))}
      </Div>
    </Div>
  );
};

export default RadioGroup;
