import styled from "styled-components";
import { Table } from "react-virtualized";

export const StyledTableWrapper = styled.div`
  height: ${({ height }) => `${height}px`};
  width: 100%;
`;

export const StyledTable = styled(Table)`
  position: relative;
  width: 100%;
  overflow-y: visible !important;

  .ReactVirtualized__Table__sortableHeaderIcon {
    display: none;
  }

  .ReactVirtualized__Grid {
    padding-top: 53px;
    overflow: visible !important;
  }

  .ReactVirtualized__Table__Grid:focus {
    outline: none !important;
  }

  .ReactVirtualized__Table__headerRow {
    position: fixed;
    display: flex;
    padding-left: 32px;
    padding-right: 0 !important;
    background-color: #ffffff;
    border-top: 1px solid ${({ theme }) => theme.secondaryLightColor};
    border-bottom: 1px solid ${({ theme }) => theme.secondaryLightColor};
    overflow: visible !important;
    z-index: 1;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    height: 100%;
    align-items: center;

    &:last-child {
      justify-content: center;
      background-color: ${({ theme }) => theme.mainLightColor};
    }
  }

  .ReactVirtualized__Table__row {
    display: flex;
    padding-left: 32px;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryLightColor};
    cursor: pointer;

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.mainLightColor};
    }
  }

  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${({ theme }) => theme.mainDarkColor};

    &:last-child {
      background-color: ${({ theme }) => theme.mainLightColor};
    }
  }
`;

export const StyledVerticalTrack = styled.div`
  position: relative;
  background: ${({ theme }) => theme.secondaryLightColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  z-index: 1;
`;
