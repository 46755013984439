import React from "react";
import { get, capitalize, upperFirst } from "lodash";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { removeNonNumericCharacters } from "utils/format";
import Div from "components/Div";
import {
  ColorPicker,
  TextArea,
  ImageUpload,
  Select,
  RadioGroup,
  Checkbox,
} from "components/inputs";
import {
  fontWeightValues,
  fontFamilyValues,
  headerFooterHeightValues,
  fontSizeDimensionValues,
  getTemplateHeaderFooterSectionOptions,
  generateSizesList,
} from "./settings";
import { useTemplateTab } from "components/hooks";
import { StyledAccordionPanelWrapper } from "./Styled";

const TemplateHeaderFooterSection = ({ template, target, themeStore }) => {
  const { activeTab, onSwitchTab, isTabActive } = useTemplateTab("general");

  const onPropertyChange = path => data => () =>
    template.changeUsingData("config", `${target}.${path}`)(data);

  const onChangeTextLocation = data => () => {
    template.changeUsingData("config", `${target}.text.order`)(data);
    template.changeUsingData(
      "config",
      `${target}.image.order`,
    )(data === 1 ? 2 : 1);
    template.changeUsingData(
      "config",
      `${target}.gridTemplateRows`,
    )(data === 1 ? "auto 1fr" : "1fr auto");
  };

  const generalTab = (
    <>
      <Div bottom={20}>
        <Div right={26}>
          <Select
            label={`${upperFirst(target)} height`}
            labelTextSize={14}
            list={headerFooterHeightValues}
            valueKey="value"
            optionName="name"
            selectedValue={template.config[target].height}
            look="underlined"
            onChange={template.changeUsingData("config", `${target}.height`)}
          />
        </Div>
      </Div>
      <Div direction="column">
        <Div bottom={28}>
          <ImageUpload
            label={`Upload ${target} background image`}
            labelColor={themeStore.theme.mainDarkColor}
            darkIcon
            fileName={template.config[target].background.image.name}
            fileSrc={template.config[target].background.image.src}
            onUpload={template.upload(`${target}.background.image`)}
            onRemove={template.removeImage(`${target}.background.image`)}
          />
        </Div>
        <ColorPicker
          label={`Choose ${target} background color`}
          labelTextSize={14}
          horizontalLabel
          color={template.config[target].background.color}
          onChange={template.changeColor(`${target}.background.color`)}
        />
      </Div>
    </>
  );

  const fontTab = (
    <>
      <Div bottom={20}>
        <TextArea
          label={`${capitalize(target)} text`}
          labelTextSize={14}
          value={template.config[target].text.value}
          onChange={template.changeUsingEvent("config", `${target}.text.value`)}
        />
      </Div>
      <Div bottom={28}>
        <Div grow={1} right={26}>
          <Select
            label="Font family"
            look="underlined"
            list={fontFamilyValues}
            valueKey="name"
            optionName="name"
            selectedValue={template.config[target].text.fontFamily}
            onChange={template.changeUsingData(
              "config",
              `${target}.text.fontFamily`,
            )}
          />
        </Div>
        <Div width="100px" right={26}>
          <Select
            label="Font weight"
            look="underlined"
            list={fontWeightValues}
            valueKey="value"
            optionName="name"
            selectedValue={template.config[target].text.fontWeight}
            onChange={template.changeUsingData(
              "config",
              `${target}.text.fontWeight`,
            )}
          />
        </Div>
      </Div>
      <Div width="200px" bottom={28}>
        <Div align="flex-end" right={50}>
          <Div right={26} width="60px">
            <Select
              look="underlined"
              label="Font size"
              list={generateSizesList(32)}
              valueKey="value"
              optionName="value"
              selectedValue={removeNonNumericCharacters(
                template.config[target].text.fontSize,
              )}
              lowercase
              onChange={template.changeUsingData(
                "config",
                `${target}.text.fontSize`,
              )}
            />
          </Div>
          <Div width="60px">
            <Select
              look="underlined"
              list={fontSizeDimensionValues}
              valueKey="value"
              optionName="value"
              selectedValue={template.config[target].text.fontSizeDimension}
              lowercase
              onChange={template.changeUsingData(
                "config",
                `${target}.text.fontSizeDimension`,
              )}
            />
          </Div>
        </Div>
        <Select
          look="underlined"
          label="Letter spacing"
          list={generateSizesList(12)}
          valueKey="value"
          optionName="value"
          selectedValue={removeNonNumericCharacters(
            template.config[target].text.letterSpacing,
          )}
          inputWidth="60px"
          lowercase
          onChange={template.changeUsingData(
            "config",
            `${target}.text.letterSpacing`,
          )}
        />
      </Div>
      <Div bottom={28} align="flex-start">
        <Div right={42}>
          <ColorPicker
            label="Text color"
            labelTextSize={14}
            horizontalLabel
            color={template.config[target].text.color}
            onChange={template.changeColor(`${target}.text.color`)}
          />
        </Div>
        <RadioGroup
          label="Text location"
          disabled={
            !get(template, `config.${target}.image.src`, "") ||
            !get(template, `config.${target}.text.value`, "")
          }
          popoverInner="Could be above or below the image (default: below)"
          items={getTemplateHeaderFooterSectionOptions("textLocation", {
            selectedKey: get(template, `config.${target}.text.order`, 1),
            onClick: onChangeTextLocation,
          })}
        />
      </Div>
      <Div bottom={28} align="center">
        <Div right={26}>
          <RadioGroup
            label="Text horizontal alignment"
            disabled={!get(template, `config.${target}.text.value`, "")}
            popoverInner="Could be left, center, right (default: left)"
            items={getTemplateHeaderFooterSectionOptions("textAlignment", {
              selectedKey: get(
                template,
                `config.${target}.text.alignment`,
                "center",
              ),
              onClick: onPropertyChange("text.alignment"),
            })}
          />
        </Div>
        <RadioGroup
          label="Text vertical alignment"
          disabled={
            !get(template, `config.${target}.text.value`, "") ||
            get(template, `config.${target}.image.src`, "")
          }
          popoverInner="Could be at the top, in the middle, at the bottom (default: top)"
          items={getTemplateHeaderFooterSectionOptions(
            "verticalTextAlignment",
            {
              selectedKey: get(
                template,
                `config.${target}.text.verticalAlignment`,
                "start",
              ),
              onClick: onPropertyChange("text.verticalAlignment"),
            },
          )}
        />
      </Div>
    </>
  );

  const imageTab = (
    <>
      <Div bottom={28}>
        <ImageUpload
          labelColor={themeStore.theme.mainDarkColor}
          darkIcon
          fileName={template.config[target].image.name}
          fileSrc={template.config[target].image.src}
          onUpload={template.upload(`${target}.image`)}
          onRemove={template.removeImage(`${target}.image`, target)}
        />
      </Div>
      <Div bottom={28}>
        <Div right={26}>
          <RadioGroup
            label="Image horizontal alignment"
            disabled={!get(template, `config.${target}.image.src`, "")}
            popoverInner="Could be left, center, right (default: center)"
            items={getTemplateHeaderFooterSectionOptions(
              "imageHorizontalAlignment",
              {
                selectedKey: get(
                  template,
                  `config.${target}.image.backgroundHorizontal`,
                  "50%",
                ),
                onClick: onPropertyChange("image.backgroundHorizontal"),
              },
            )}
          />
        </Div>
        <RadioGroup
          label="Image vertical alignment"
          disabled={!get(template, `config.${target}.image.src`, "")}
          popoverInner="Could be top, center, bottom (default: center)"
          items={getTemplateHeaderFooterSectionOptions(
            "imageVerticalAlignment",
            {
              selectedKey: get(
                template,
                `config.${target}.image.backgroundVertical`,
                "50%",
              ),
              onClick: onPropertyChange("image.backgroundVertical"),
            },
          )}
        />
      </Div>
      <Checkbox
        title={`Fit ${target} size`}
        disabled={!get(template, `config.${target}.image.src`, "")}
        large
        checked={template.config[target].image.backgroundSize !== "auto"}
        onChange={template.setImageSize(target)}
      />
    </>
  );

  return (
    <Div direction="column" width="100%">
      <RadioGroup
        items={getTemplateHeaderFooterSectionOptions("tabs", {
          selectedKey: activeTab,
          onClick: onSwitchTab,
        })}
        changeIconColor
      />
      <StyledAccordionPanelWrapper>
        {isTabActive("general") ? generalTab : null}
        {isTabActive("font") ? fontTab : null}
        {isTabActive("image") ? imageTab : null}
      </StyledAccordionPanelWrapper>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer,
)(TemplateHeaderFooterSection);
