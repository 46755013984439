import styled from "styled-components";
import { Icon } from "antd";

export const StyledSearchIcon = styled(Icon)`
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const StyledResultsWrapper = styled.div`
  position: absolute;
  width: ${({ width }) => `${width}px` || "100%"};
  top: 100%;
  padding: 20px 34px 5px;
  background-color: #ffffff;
  box-shadow: 0 0 13px -7px rgba(0, 0, 0, 0.75);
  animation: fadeIn 0.3s ease-in both;
  z-index: 100;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const StyledResultWrapper = styled.div`
  padding-bottom: 15px;
`;

export const StyledResultTitle = styled.div`
  padding-bottom: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.mainDarkColor};
  opacity: 0.5;
`;

export const StyledResult = styled.div`
  display: flex;
  width: 294px;
  padding-bottom: 10px;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.mainDarkColor};
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const StyledResultText = styled.div`
  padding-bottom: 2px;
  line-height: 1.2;
`;
