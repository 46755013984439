import React from "react";
import { observer } from "mobx-react";

import Div from "components/Div";
import { Button } from "components/actions";
import { Checkbox } from "components/inputs";
import ErrorMessage from "components/ErrorMessage";

const Actions = ({ experience, errors }) => (
  <Div direction="column" left={10} right={50}>
    <Div top={10} bottom={25}>
      <Div display="block" right={25}>
        <Button type="purpleUnderlined" onClick={experience.addStep}>
          + URL
        </Button>
      </Div>
      <Button type="purpleUnderlined" onClick={experience.addMenuLink}>
        + Menu
      </Button>
    </Div>
    {experience.steps.length >= 2 && (
      <Div>
        <Checkbox
          title="Repeat"
          large
          checked={experience.isRepeatable}
          onChange={experience.changeRepeatable}
        />
      </Div>
    )}
    <Div height="15px">
      {!!errors && !errors.length && (
        <ErrorMessage
          bottom={0}
          left={0}
          text="At least one step should exist"
        />
      )}
    </Div>
  </Div>
);

export default observer(Actions);
