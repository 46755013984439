import React from "react";
import { inject, observer } from "mobx-react";
import { get } from "lodash";

import { compose } from "utils/hoc";
import { Checkbox, ImageUpload, Text } from "components/inputs";
import { StyledAccordionPanelWrapper } from "./Styled";
import { useModal } from "components/hooks";
import Modal from "components/Modal";
import Div from "components/Div";
import P from "components/P";
import { checkThatImageHeightCorrect } from "./helpers";

const TemplateBannerSection = ({ template, themeStore }) => {
  const [isImageModalShown, setImageModalShown, onConfirmModalOk] = useModal();

  const onUpload = async e => {
    e.persist();
    const result = await checkThatImageHeightCorrect(e.target.files[0], 250);

    if (!result) {
      e.target.value = null;
      return setImageModalShown()();
    }

    await template.upload("banner.image")(e);
  };

  return (
    <>
      <Modal
        title="Image upload validation error"
        showModal={isImageModalShown}
        onConfirm={onConfirmModalOk(template.removeImage("banner.image"))}
      >
        <P>Recommended banner height no more than 250 pixels.</P>
      </Modal>
      <StyledAccordionPanelWrapper>
        <ImageUpload
          labelColor={themeStore.theme.mainDarkColor}
          darkIcon
          popoverInner="Recommended banner size is 350 by 50 pixels uploadable picture should meet these dimensions"
          fileName={template.config.banner.image.name}
          fileSrc={template.config.banner.image.src}
          onUpload={onUpload}
          onRemove={template.removeImage("banner.image")}
        />
        <Div top={15}>
          <Checkbox
            title="Fit banner size"
            disabled={!get(template, "config.banner.image.src", "")}
            large
            checked={template.config.banner.image.backgroundSize !== "auto"}
            onChange={template.setImageSize("banner")}
          />
        </Div>
        <Div top={15}>
          <Text
            label="Url"
            value={template.config.banner.url}
            name="bannerUrl"
            onChange={template.changeUsingEvent("config", "banner.url")}
          />
        </Div>
      </StyledAccordionPanelWrapper>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer,
)(TemplateBannerSection);
