import React from "react";

import { SearchWithSelect } from "components/inputs";
import Form from "components/Form";

const FromLot = ({ data, searchPopupOptions, onSearchSelect, onSearch }) => (
  <Form
    width="100%"
    align="center"
    justify="flex-start"
    paddingLeft={15}
    paddingRight={15}
    bottom={15}
    gap={10}
  >
    <SearchWithSelect
      onSearch={onSearch}
      data={data}
      dataKeys={searchPopupOptions.map(({ key }) => key)}
      resultsPopupOptions={searchPopupOptions}
      placeholder="Search by lot name or description"
      onSelect={onSearchSelect}
    />
  </Form>
);

export default FromLot;
