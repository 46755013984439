import styled from "styled-components";

export const StyledSwitchItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 44px;
  border-top: 1px solid ${({ theme }) => theme.accentColor};
  border-bottom: 1px solid ${({ theme }) => theme.accentColor};
  background-color: ${({ active, theme }) =>
    active ? theme.accentColor : "#ffffff"};
  cursor: pointer;

  &:first-child {
    border: 1px solid ${({ theme }) => theme.accentColor};
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  &:last-child {
    border: 1px solid ${({ theme }) => theme.accentColor};
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
`;
