import styled from "styled-components";

export const StyledSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.mainDarkColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.accentColor};
    text-decoration: underline;
  }
`;
