import React, {useEffect} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { message } from "utils/format";
import Div from "components/Div";
import Form from "components/Form";
import {ImageUpload, Select} from "components/inputs";
import { HEADER_LOGO, SIDEBAR_EXPANDED_LOGO, SIDEBAR_COLLAPSED_LOGO } from "../../../constants"
import P from "../../../components/P";

const getErrorMessage = (type, data) => {
  const fileNameMapping = {
    [HEADER_LOGO]: 'header logo',
    [SIDEBAR_EXPANDED_LOGO]: 'sidebar expanded logo',
    [SIDEBAR_COLLAPSED_LOGO]: 'sidebar collapsed logo',
  }

  const errorMessagesMap = {
    size: "The file size is too big",
    quantity: "The asset bundle has a maximum quantity of files allowed.",
    width: (data) => {
      return `The ${fileNameMapping[data.name]} width should not be more than ${data.dimension} pixels.`
    },
    height: (data) => {
      return `The ${fileNameMapping[data.name]} height should not be more than ${data.dimension} pixels.`
    }
  };

  if (typeof errorMessagesMap[type] === 'function') {
    return errorMessagesMap[type](data)
  } else {
    return errorMessagesMap[type]
  }
}

const CompanyPrimaryContact = ({
  companiesStore: { company, findById, enableBranding },
  themeStore,
  assetBundlesStore,
  rootStore: { validationErrors }
}) => {
  const {
    deleteAssetById,
    addAsset,
  } = assetBundlesStore;
  const [assetBundle] = company.assetBundles || []
  const assets = assetBundle?.assets || []
  const onUpload = async (e, fileName) => {
    e.persist();
    const file = e.target;
    const fileMapping = {
      [HEADER_LOGO]: headerAsset,
      [SIDEBAR_EXPANDED_LOGO]: sidebarExpandedAsset,
      [SIDEBAR_COLLAPSED_LOGO]: sidebarCollapsedAsset,
    }
    const dimensionMapping = {
      [HEADER_LOGO]: {
        width: 128,
        height: 28
      },
      [SIDEBAR_EXPANDED_LOGO]: {
        width: 200,
        height: 74
      },
      [SIDEBAR_COLLAPSED_LOGO]: {
        width: 32,
        height: 74
      },
    }
    const asset = fileMapping[fileName]
    const dimension = dimensionMapping[fileName]

    if (file) {
      const resp = await addAsset({
        target: file,
        name: fileName,
        bundleId: assetBundle && assetBundle.id,
        dimension,
        relation: {
          companyId: company.id,
          relationEntity: 'company',
          relationId: company.id,
        }
      })();
      if (!resp?.validationError && asset) {
        await deleteAssetById({id: asset.id})()
      }
      if (!resp?.validationError) {
        await findById({id: company.id})()
      }
    }
  };

  const onDelete = async (fileName) => {
    const asset = assets.find((file) => file.name === fileName)
    await deleteAssetById({id: asset.id})()
    await findById({id: company.id})()
  };

  useEffect(() => {
    if (validationErrors.size) {
       message(getErrorMessage('size'), "error");
    } else if (validationErrors.quantity) {
       message(getErrorMessage('quantity'), "error");
    } else if (validationErrors.width) {
       message(getErrorMessage('width', validationErrors.width), "error");
    } else if (validationErrors.height) {
       message(getErrorMessage('height', validationErrors.height), "error");
    }
  }, [validationErrors]);

  const headerAsset = assets.find((item) => item.name === HEADER_LOGO)
  const sidebarCollapsedAsset = assets.find((item) => item.name === SIDEBAR_COLLAPSED_LOGO)
  const sidebarExpandedAsset = assets.find((item) => item.name === SIDEBAR_EXPANDED_LOGO)

  return (
    <Form width="100%">
      <Div width="30%" direction="column">
        <Select
          list={[
            { name: "Enabled", value: "true", icon: "active" },
            { name: "Disabled", value: "false", icon: "inactive" }
          ]}
          valueKey="value"
          iconKey="icon"
          selectedValue={String(company.isBrandingEnabled)}
          onChange={async (value) => {
            await enableBranding({
              id: company.id,
              isBrandingEnabled: value === 'true',
              constraintId: company.constraint.id,
            })()
            await company.toggleBranding(value)
          }}
        />
        <Div bottom={25} top={15}>
          <ImageUpload
            label="Upload company header logo"
            labelColor={themeStore.theme.mainDarkColor}
            disabled={!company?.id || !company.isBrandingEnabled}
            darkIcon
            fileName={headerAsset && 'Header logo'}
            fileSrc={headerAsset?.file}
            popoverInner={(
              <>
                <P bottom={10}>
                  Images must be .png or .svg and may not be larger than 128 pixels width and 28 pixels height
                </P>
              </>
            )}
            onUpload={(e) => {
               onUpload(e, HEADER_LOGO)
            }}
            onRemove={(e) => {
              onDelete(HEADER_LOGO)
            }}
          />
        </Div>
        <Div bottom={25}>
          <ImageUpload
            label="Upload company sidebar expanded logo"
            labelColor={themeStore.theme.mainDarkColor}
            disabled={!company?.id || !company.isBrandingEnabled}
            darkIcon
            fileName={sidebarExpandedAsset && 'Sidebar expanded logo'}
            fileSrc={sidebarExpandedAsset?.file}
            popoverInner={(
                <>
                  <P bottom={10}>
                    Images must be .png or .svg and may not be larger than 200 pixels width and 74 pixels height
                  </P>
                </>
            )}
            onUpload={(e) => {
              onUpload(e, SIDEBAR_EXPANDED_LOGO)
            }}
            onRemove={(e) => {
              onDelete(SIDEBAR_EXPANDED_LOGO)
            }}
          />
        </Div>
          <Div bottom={25}>
            <ImageUpload
              label="Upload company sidebar collapsed logo"
              labelColor={themeStore.theme.mainDarkColor}
              disabled={!company?.id || !company.isBrandingEnabled}
              darkIcon
              fileName={sidebarCollapsedAsset && 'Sidebar collapsed logo'}
              fileSrc={sidebarCollapsedAsset?.file}
              popoverInner={(
                <>
                  <P bottom={10}>
                    Images must be .png or .svg and may not be larger than 32 pixels width and 74 pixels height
                  </P>
                </>
              )}
              onUpload={(e) => {
                onUpload(e, SIDEBAR_COLLAPSED_LOGO)
              }}
              onRemove={(e) => {
                onDelete(SIDEBAR_COLLAPSED_LOGO)
              }}
            />
          </Div>
      </Div>
    </Form>
  );
}

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore,
    themeStore: rootStore.themeStore,
    assetBundlesStore: rootStore.assetBundlesStore,
    rootStore
  })),
  observer
)(CompanyPrimaryContact);
