import { action } from "mobx";

class Tableau {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound async getDashboardUrls() {
    const { method, url } = this.rootStore.urls.tableau;

    const { response } = await this.rootStore.makeRequest({
      method,
      url
    });

    if (response) {
      return response.data;
    }

    return "";
  }
}

export default Tableau;
