import React from "react";
import { capitalize } from "lodash";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "../../Div";
import Icon from "../../Icon";
import { StyledSwitchItem } from "./Styled";

const PreviewSwitch = ({
  themeStore,
  types,
  currentType,
  disabled = false,
  onClick = () => null,
  ...restProps
}) => {
  const onSwitch = t => () => (disabled ? null : onClick(t));
  const isActive = t => t === currentType;

  return (
    <Div styles={{ opacity: disabled ? 0.5 : 1 }} {...restProps}>
      {types.map(type => (
        <StyledSwitchItem
          key={type}
          active={isActive(type)}
          theme={themeStore.theme}
          onClick={onSwitch(type)}
        >
          <Icon
            icon={
              isActive(type)
                ? `previewSwitch${capitalize(type)}Selected`
                : `previewSwitch${capitalize(type)}`
            }
            width="26px"
            height="26px"
          />
        </StyledSwitchItem>
      ))}
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer,
)(PreviewSwitch);
