import { useState } from "react";
import { get } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

// todo: add preloader
export default ({ data = [], options = [], dataKey = "" }) => {
  const [state, setState] = useState(options);
  const [filteredData, setFilteredData] = useState(data);

  useDeepCompareEffect(() => {
    const keys = state
      .filter(({ isSelected }) => isSelected)
      .map(({ key }) => key);

    setFilteredData(data.filter(item => keys.includes(get(item, dataKey))));
  }, [data, state]);

  const onChange = e => {
    const { target } = e;
    const newState = state.map(item => {
      if (item.key === target.name) {
        return { ...item, isSelected: !item.isSelected };
      }

      return item;
    });
    setState(newState);
  };

  return [filteredData, state, onChange];
};
