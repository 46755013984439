import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import Icon from "components/Icon";
import P from "components/P";
import { StyledItemWrapper } from "./Styled";

const LotDetails = ({ options, themeStore }) => (
  <Div>
    {options.map(({ icon, title, value, width, height }) => (
      <StyledItemWrapper key={title} theme={themeStore.theme}>
        <Icon width={width || "40px"} height={height || "32px"} icon={icon} />
        <Div direction="column" left={8}>
          <P size={19} weight="bold" color={themeStore.theme.mainDarkColor}>
            {value}
          </P>
          <P size={12} color={themeStore.theme.mainDarkColor}>
            {title}
          </P>
        </Div>
      </StyledItemWrapper>
    ))}
  </Div>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(LotDetails);

export { getLotDetailsConfig } from "./helpers";
