import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Button } from "components/actions";
import { Text } from "components/inputs";
import Form from "components/Form";

const ChangePassword = ({
  authStore: {
    oldPassword,
    newPassword,
    repeatPassword,
    change,
    changePassword
  },
  rootStore: { validationErrors }
}) => (
  <Form width="100%">
    <Div direction="column" width="30%">
      <Div bottom={25}>
        <Text
          label="Old password"
          value={oldPassword}
          name="oldPassword"
          type="password"
          error={validationErrors.oldPassword}
          errorMessage="Must be valid old password"
          onChange={change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="New password"
          value={newPassword}
          name="newPassword"
          type="password"
          error={validationErrors.newPassword}
          errorMessage="Must be valid new password"
          onChange={change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Confirm new password"
          value={repeatPassword}
          name="repeatPassword"
          type="password"
          error={validationErrors.repeatPassword}
          errorMessage="Password doesn't match"
          onChange={change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Button type="regular" onClick={changePassword}>
          Change password
        </Button>
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(ChangePassword);
