import React from "react";
import { isEmpty, get } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";

import Icon from "../../Icon";
import {
  StyledResultsWrapper,
  StyledResultWrapper,
  StyledResultTitle,
  StyledResult,
  StyledResultText,
} from "./Styled";

const ResultsPopup = ({
  width,
  height = 300,
  results,
  options,
  theme,
  onSelect,
  setSearchValue,
}) => {
  const onItemSelect = (item) => () => {
    onSelect && onSelect(item, true)();
    setSearchValue("");
  };

  return (
    <StyledResultsWrapper width={width}>
      <Scrollbars style={{ width: +width - 40, height }}>
        {options.map(({ key, title, iconByType, iconByProperty }) => (
          <StyledResultWrapper key={key}>
            <StyledResultTitle theme={theme}>{title}</StyledResultTitle>
            {isEmpty(results[key]) && "no results"}
            {results[key].map((r, i) => (
              <StyledResult
                key={`${key}-${i}`}
                theme={theme}
                onClick={onItemSelect(r)}
              >
                <Icon
                  width="12px"
                  height="12px"
                  margin="0 8px 0 0"
                  icon={iconByType || get(r, iconByProperty)}
                />
                <StyledResultText>{r[key]}</StyledResultText>
              </StyledResult>
            ))}
          </StyledResultWrapper>
        ))}
      </Scrollbars>
    </StyledResultsWrapper>
  );
};

export default ResultsPopup;
