import styled from "styled-components";
import { Link } from "react-router-dom";

import backArrowIcon from "assets/images/backward_arrow.svg";
import deleteIcon from "assets/images/ic_trash.svg";
import previewIcon from "assets/images/ic_eye.svg";
import sort from "assets/images/ic_sorting.svg";
import sortSelected from "assets/images/ic_sorting_selected.svg";

const stylesReset = `
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  text-decoration: none;
  background: transparent;
  border: none;
  white-space: nowrap;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

const regularCommon = `
  padding: 10px 32px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  white-space: nowrap
  `;

const popoverCommon = `
  padding: 10px 30px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  white-space: nowrap

  &:last-child {
    border-bottom: none;
  }
`;

const disabledStyle = `
  opacity: 0.5;
  pointer-events: none;
`;

const animationStyle = `
  transition: 0.3s;
`;

const crossTypeCommon = `
  position: absolute;
  top: 7px;
  left: -2px;
  width: 21px;
  height: 2px;
  content: "";
  transition: background-color 0.3s;
`;

const largeSelect = `
  display: flex;
  padding: 8px 0;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

const smallSelect = `
  width: 54px;
  height: 54px;
  margin-right: 10px;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

const typeList = theme => ({
  regular: `
    ${regularCommon}
    text-transform: uppercase;
    color: #fff;
    background-color: ${theme.accentColor};
    border: 1px solid ${theme.accentColor};
    opacity: 0.8;
    border-radius: ${theme.borderRadius}
    min-width: 18ch;

    &:hover {
      color: #fff;
      border: 1px solid #0000000f;
      opacity: 1;
    }
  `,
  regularFlat: `
    ${regularCommon}
    text-transform: uppercase;
    color: ${theme.mainDarkColor};
    background-color: #00000005;
    border: 1px solid #0000000f;
    opacity: 0.8;
    border-radius: ${theme.borderRadius};
    min-width: 18ch;
    margin-right: 20px;

    &:hover {
      color: ${theme.mainDarkColor};
      opacity: 1;
    }
  `,
  regularRound: `
    ${regularCommon}
    border: 0 !important;
    background-color: ${theme.mainDarkColor};
    color: #fff;
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    align-items: center;
    font-weight: bold;
    font-size: large;

    &:hover {
      background-color: ${theme.accentColor};
    }
  `,
  popoverRegular: `
    ${popoverCommon}
    justify-content: center;
    color: ${theme.mainDarkColor};
    border-bottom: 1px solid ${theme.mainLightColor};
    min-width: 18ch;

    &:hover {
      color: ${theme.accentColor};
    }
  `,
  popoverRedText: `
    ${popoverCommon}
    color: ${theme.errorColor};
    border-bottom: 1px solid ${theme.mainLightColor};

    &:hover {
      color: ${theme.accentColor};
    }
  `,
  backArrow: `
    display: inline-block;
    width: 16px;
    height: 16px;
    background: transparent url(${backArrowIcon}) 50% no-repeat;
  `,
  purpleUnderlined: `
    font-weight: bold;
    font-size: 16px;
    color: ${theme.mainDarkColor};
    text-decoration: underline;

    &:hover {
      color: ${theme.accentColor};
      text-decoration: underline;
    }
  `,
  greenUnderlined: `
    font-size: 14px;
    color: ${theme.accentColor};
    text-decoration: underline;

    &:hover {
      color: ${theme.mainDarkColor};
      text-decoration: underline;
    }
  `,
  blackFlat: `
    justify-content: flex-start;
    font-size: 15;
    font-weight: 400;
    color: ${theme.mainDarkColor};
    text-decoration: none;
    line-height: 1;

    &:hover {
      color: ${theme.accentColor};
      text-decoration: none;
    }
  `,
  tagManagement: `
    display: flex;
    min-width: 24%;
    padding: 12px;
    background-color: ${theme.mainLighterColor};
    border: 1px solid ${theme.secondaryLightColor};
    border-radius: ${theme.borderRadius} !important;

    &:hover {
      background-color: #fff;
      border: 1px solid ${theme.accentColor};

      & div p:first-child {
        color: ${theme.accentColor};
      }
    }
  `,
  preview: `
    width: 25px;
    height: 15px;
    background: transparent url(${previewIcon}) 50% no-repeat;
  `,
  bin: `
    display: inline-block;
    width: 14px;
    height: 18px;
    background: transparent url(${deleteIcon}) 50% no-repeat;
  `,
  tableLink: `
    font-weight: 400;
    font-size: 14px;
    color: ${theme.mainDarkColor};
    text-decoration: none;

    &:hover {
      color: ${theme.accentColor};
      text-decoration: underline;
    }
  `,
  auth: `
    ${regularCommon}
    display: inline-block;
    width: 100%;
    padding: 16px;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid ${theme.accentColor};
    border-radius: ${theme.borderRadius} !important;
    background-color: ${theme.accentColor};
    opacity: 1;

    &:hover {
      color: #ffffff;
      opacity: 0.8;
    }

  `,
  authLink: `
    color: ${theme.mainDarkColor};
    font-size: 16px;
    font-weight: 400;

    &:hover {
      color: ${theme.accentColor};
    }
  `,
  sortAction: `
    position: relative;
    display: flex;
    width: 100%
    margin-bottom: 14px;
    padding-left: 25px;
    align-items: center;
    justify-content: flex-start;
    color: ${theme.mainDarkColor};
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: ${theme.accentColor};

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 14px;
      height: 14px;
      transition: 0.3s;
      content: "";
    }

    &:before {
      background: transparent url(${sort}) 50% no-repeat;
      background-size: 14px;
      opacity: 1;
    }

    &:after {
      background: transparent url(${sortSelected}) 50% no-repeat;
      background-size: 14px;
      opacity: 0;
    }
  `,
  setExperience: `
    color: ${theme.mainDarkColor};
    margin-right: 15px;
    font-size: 16px;
  `,
  cross: `
    position: absolute;
    width: 16px;
    height: 16px;

    &::before {
      ${crossTypeCommon}
      background-color: ${theme.mainDarkColor};
      transform: rotate(45deg);
    }

    &::after {
      ${crossTypeCommon}
      background-color: ${theme.mainDarkColor};
      transform: rotate(-45deg);
    }

    &:hover {
      &::after,
      &::before {
        background-color: ${theme.accentColor};
      }
    }
  `,
  tapWowLogo: `
    flex-direction: column;
  `,
  tableAction: `
    width: 100%
  `,
  largeSelect: `
    ${largeSelect}
    border: 1px solid ${theme.secondaryLightColor};
  `,
  largeSelectSelected: `
    ${largeSelect}
    border: 1px solid ${theme.accentColor};
  `,
  smallSelect: `
    ${smallSelect}
    border-radius: ${theme.borderRadius} !important;
    border: 1px solid ${theme.mainDarkColor};
  `,
  smallSelectSelected: `
    ${smallSelect}
    border-radius: ${theme.borderRadius} !important;
    border: 1px solid ${theme.accentColor};
    background-color: ${theme.mainLightColor};
  `
});

const applyStyles = ({ type, disabled, theme }) => {
  const style = `
    ${stylesReset}
    ${typeList(theme)[type]}
    ${animationStyle}
  `;

  if (disabled) {
    return `${style}${disabledStyle}`;
  }

  return style;
};

export const StyledButton = styled.button(applyStyles);

export const StyledLink = styled(Link)(
  ({ type, theme }) => typeList(theme)[type]
);

export const StyledExternalLink = styled.a.attrs(
  ({ to, target = "_blank" }) => ({
    href: to,
    target
  })
)(applyStyles);

export const StyledSelectButtonTitle = styled.span`
  display: inline-block;
  margin-top: 10px;
  color: ${({ theme }) => theme.mainDarkColor};
  font-size: 12px;
`;
