import React from "react";
import { Icon } from "antd";

import { StyledSpin } from "./Styled";

const Spin = ({ loading }) => {
  if (!loading) return null;

  return (
    <StyledSpin>
      <Icon type="loading-3-quarters" size="default" spin />
    </StyledSpin>
  );
};

export default Spin;
