import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import {
  useModal,
  useModalWithValidation, useServerOrdering, useServerSorting,
  useSorting, useTablePaginator
} from "components/hooks";
import { Button } from "components/actions";
import Div from "components/Div";
import P from "components/P";
import Modal from "components/Modal";
import Table, { useRowSelect } from "components/TableVirtualized";
import Summary from "features/miscellany/Summary";
import TagManagement from "../TagManagement";
import WithAffectedModal from "../WithAffectedModal";
import FirstRangeStepForm from "../../forms/FirstRangeStep";
import SecondRangeStepForm from "../../forms/SecondRangeStep";
import {
  rollsSearchOptions,
  rollsTableColumns,
  tagsTableColumns,
  tagsSearchOptions,
  summaryOptions
} from "./settings";
import {debounce} from "lodash";
import {useFilterOptions} from "../FullRoll/settings";
import {useSortOptions} from "../SeparateTags/settings";

const AddRange = ({
  rootStore,
  tagsStore,
  subRollsStore,
  rollsStore,
  authStore,
  themeStore: { theme },
  isEditRoute,
  lotId,
  createSubRoll
}) => {
  const { contextCompanyId } = authStore;
  const { validateForBeenUsedTags, affectedLots } = subRollsStore;
  const { tagsByRoll: tagsData, findAll: findAllTags } = tagsStore;
  const {
    findAll: findAllRolls,
    rolls: rollsData,
    findById: findRollById,
    roll
  } = rollsStore;
  const [ rolls, setRolls ] = useState([])
  const {
    dataWithSelectedRows: dataWithSelectedRolls,
    selectedIndex: selectedRollIndex,
    onRowSelect: onRollRowSelect,
    hasSelected: hasSelectedRolls,
    getSelectedIds: getSelectedRollIds,
    resetAllSelected: resetAllSelectedRolls,
    resetSelectedIndex: resetSelectedRollIndex
  } = useRowSelect(rolls, "single");
  const [sortedRollsData, onRollsSort] = useSorting(dataWithSelectedRolls);
  const [rollsSearch, setRollsSearch] = useState('')
  const [tagsSearch, setTagsSearch] = useState('')
  const [rollsPagination, rollsPaginationReset] = useTablePaginator({
    isScrollPagination: true,
    onPageChange: (paginationConfig) => {
      fetchRolls(paginationConfig)
    }
  });
  const [tagsPagination, tagsPaginationReset] = useTablePaginator({
    isScrollPagination: true,
    onPageChange: (paginationConfig) => {
      const rollId = getSelectedRollIds()[0];
      fetchTags(paginationConfig, rollId)
    }
  });
  const [rollsFilterOptions, onRollsFilter, getRollsFilters, resetRollsFilter] = useServerSorting(useFilterOptions.items, rollsPaginationReset)
  const [rollsOrder, onRollsOrder, resetRollsOrder] = useServerOrdering(useSortOptions, rollsPaginationReset, onRollsSort)
  const [ tags, setTags ] = useState([])
  const {
    dataWithSelectedRows: dataWithSelectedTags,
    selectedIndex: selectedTagIndex,
    onRowSelect: onTagsRowSelect,
    hasSelected: hasSelectedTags,
    getFirstAndLast,
    resetAllSelected: resetAllSelectedTags,
    resetSelectedIndex: resetSelectedTagIndex
  } = useRowSelect(tags, "range");
  const [isModalWasOpened, setModalWasOpened] = useState(false)
  const [
    isAffectedModalShown,
    setAffectedModalShown,
    onAffectedModalOk,
    onAffectedModalCancel
  ] = useModal();

  const [
    isFirstStepModalShown,
    setFirstStepModalShown,
    onFirstStepModalOk,
    onFirstStepModalCancel
  ] = useModal(rootStore);

  const [
    isSecondStepModalShown,
    setSecondStepModalShown,
    onSecondStepModalOk,
    onSecondStepModalCancel
  ] = useModalWithValidation(rootStore);

  useEffect(() => {
    let items = rollsData
    if (dataWithSelectedRolls.length) {
      items = items.map((roll) => {
        const selectedRoll = dataWithSelectedRolls.find((selectedRoll) => roll.id === selectedRoll.id)
        return {
          ...roll,
          selected: selectedRoll?.selected
        }
      })
    }

    setRolls([...items])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rollsData])

  useEffect(() => {
    let items = tagsData
    if (dataWithSelectedTags.length) {
      items = items.map((tag) => {
        const selectedTag = dataWithSelectedTags.find((selectedTag) => tag.id === selectedTag.id)
        return {
          ...tag,
          selected: selectedTag?.selected
        }
      })
    }

    setTags([...items])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData])

  useEffect(() => {
    if (isFirstStepModalShown) {
      setModalWasOpened(true)
      fetchRolls(rollsPagination)
    }

    if (!isFirstStepModalShown && isModalWasOpened) {
      resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstStepModalShown, rollsSearch, rollsFilterOptions, rollsOrder]);

  const fetchRolls = (paginationOptions) => {
    const filter = getRollsFilters()

    return findAllRolls({
      params: { companyId: contextCompanyId, withTags: true, search: rollsSearch, order: rollsOrder, filter},
      paginationOptions,
    });
  }

  useEffect(() => {
    const rollId = getSelectedRollIds()[0];
    if (isSecondStepModalShown && rollId) {
      setModalWasOpened(true)
      findRollById({ id: rollId })();
      fetchTags(tagsPagination, rollId)
    }

    if (!isSecondStepModalShown && isModalWasOpened) {
      resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondStepModalShown, tagsSearch]);

  const resetData = () => {
    setModalWasOpened(false)
    rollsPaginationReset()
    tagsPaginationReset()
    setRollsSearch('')
    setTagsSearch('')
    resetRollsOrder()
    resetRollsFilter()
  }

  const fetchTags = (paginationOptions, rollId) => {
    return findAllTags({ rollId }, paginationOptions, {
      search: tagsSearch,
    });
  }

  const resetAllSelected = () => {
    resetData()
    resetAllSelectedRolls();
    resetAllSelectedTags();
  };

  const onBackToFirstStep = () => {
    resetAllSelected();
    onSecondStepModalCancel()();
    setFirstStepModalShown()();
  };

  const onAffectedCancel = () => {
    resetAllSelected();
    onAffectedModalCancel()();
    setSecondStepModalShown()();
  };

  const onSubRollCreate = async () => {
    const items = getFirstAndLast();
    await createSubRoll({
      data: {
        lotId,
        start: items.first.id,
        end: items.last.id,
        sequenceStart: items.first.sequence,
        sequenceEnd: items.last.sequence
      }
    });
    subRollsStore.resetAffectedLots();
    onAffectedModalCancel()();
    onSecondStepModalCancel()();
    resetAllSelected();
  };

  const onSave = async () => {
    const items = getFirstAndLast();
    const affectedLots = await validateForBeenUsedTags(lotId, {
      start: items.first.id,
      end: items.last.id
    });

    if (affectedLots.length) {
      onSecondStepModalCancel()();
      return setAffectedModalShown()();
    }

    await onSubRollCreate();
  };

  const scrollRollsTable = async ({ scrollTop, scrollHeight, clientHeight }) => {
    let maxScroll = scrollHeight - clientHeight
    if (scrollTop === maxScroll && rolls.length < rollsPagination.total) {
      rollsPagination.onChange(rollsPagination.current + 1)
    }
  };

  const onRollsSearch = useRef(debounce((val) => {
    rollsPaginationReset()
    setRollsSearch(val)
  }, 500)).current;

  const scrollTagsTable = async ({ scrollTop, scrollHeight, clientHeight }) => {
    let maxScroll = scrollHeight - clientHeight
    if (scrollTop === maxScroll && rolls.length < tagsPagination.total) {
      tagsPagination.onChange(tagsPagination.current + 1)
    }
  };

  const onTagsSearch = useRef(debounce((val) => {
    tagsPaginationReset()
    setTagsSearch(val)
  }, 500)).current;

  const StepTitle = ({ title }) => (
    <P color={theme.accentColor} size={20} weight="bold">
      {title}
    </P>
  );

  return (
    <WithAffectedModal
      isModalShown={isAffectedModalShown}
      affectedItems={affectedLots}
      onCross={onAffectedModalCancel()}
      onCancel={onAffectedCancel}
      onConfirm={onAffectedModalOk(onSubRollCreate)}
    >
      <Button
        type="tagManagement"
        disabled={!isEditRoute}
        onClick={setFirstStepModalShown()}
      >
        <TagManagement
          title="Add Range of Tags"
          description="Add range of tags to lot"
          imageType="addRange"
        />
      </Button>
      <Modal
        title="Add Range of Tags (1 of 2)"
        showModal={isFirstStepModalShown}
        onCancel={onFirstStepModalCancel(resetAllSelected)}
        onConfirm={onFirstStepModalOk(setSecondStepModalShown())}
        confirmButtonProps={{ disabled: !hasSelectedRolls() }}
        confirmButtonTitle="Next Step"
        width="1190px"
      >
        <Div direction="column" width="100%">
          <FirstRangeStepForm
            onSearch={onRollsSearch}
            title={<StepTitle title="First, select a roll." />}
            data={sortedRollsData}
            searchPopupOptions={rollsSearchOptions}
            filterOptions={rollsFilterOptions}
            onFilterChange={onRollsFilter}
            onSearchSelect={onRollRowSelect}
          />
          <Table
            columns={rollsTableColumns}
            data={sortedRollsData}
            selectedIndex={selectedRollIndex}
            onSort={onRollsOrder}
            onRowSelect={onRollRowSelect}
            onResetSelectedIndex={resetSelectedRollIndex}
            onScroll={scrollRollsTable}
          />
        </Div>
      </Modal>
      <Modal
        title="Add range of Tags (2 of 2)"
        showModal={isSecondStepModalShown}
        onCross={onSecondStepModalCancel(resetAllSelected)}
        onCancel={onBackToFirstStep}
        onConfirm={onSecondStepModalOk(onSave)}
        confirmButtonProps={{ disabled: !hasSelectedTags() }}
        confirmButtonTitle="Finish"
        cancelButtonTitle="Back"
        width="1190px"
      >
        <Div direction="column" width="100%">
          <Div styles={{ position: "absolute", left: "355px", top: "-45px" }}>
            <Summary
              rows={summaryOptions.map(({ key, title }) => ({
                title,
                value:
                  key === "tagType"
                    ? roll[key] && roll[key].toUpperCase()
                    : roll[key]
              }))}
            />
          </Div>
          <SecondRangeStepForm
            onSearch={onTagsSearch}
            title={<StepTitle title="Now, select first and last tags." />}
            data={dataWithSelectedTags}
            searchPopupOptions={tagsSearchOptions}
            onSearchSelect={onTagsRowSelect}
          />
          <Table
            columns={tagsTableColumns}
            data={dataWithSelectedTags}
            selectedIndex={selectedTagIndex}
            onRowSelect={onTagsRowSelect}
            onResetSelectedIndex={resetSelectedTagIndex}
            onScroll={scrollTagsTable}
          />
        </Div>
      </Modal>
    </WithAffectedModal>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore,
    rollsStore: rootStore.rollsStore,
    subRollsStore: rootStore.subRollsStore,
    themeStore: rootStore.themeStore
  })),
  observer
)(AddRange);
