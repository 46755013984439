import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Popover from "components/Popover";
import Div from "components/Div";
import { StyledLotListWrapper, StyledPopoverIcon } from "./Styled";

const LotList = ({ lots = [], themeStore }) => {
  if (!lots.length) return null;

  const [firstLot, ...restLots] = lots;

  return (
    <StyledLotListWrapper>
      <Div>{firstLot.name}</Div>
      {restLots.length ? (
        <Popover.InfoPopover
          icon={
            <StyledPopoverIcon
              theme={themeStore.theme}
            >{`+${restLots.length}`}</StyledPopoverIcon>
          }
          width="auto"
          offset={5}
        >
          {restLots.map((l) => (
            <span key={l.name}>{l.name}</span>
          ))}
        </Popover.InfoPopover>
      ) : null}
    </StyledLotListWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(LotList);
