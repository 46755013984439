import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Text } from "components/inputs";
import Form from "components/Form";

const Clone = ({ storeName, rootStore }) => {
  const store = rootStore[`${storeName}Store`];

  return (
    <Form width="50%">
      <Text
        name="name"
        value={store.cloneName}
        error={rootStore.validationErrors.name}
        errorMessage="Must be more than 1 and less than 50 symbols"
        onChange={store.changeCloneName}
      />
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Clone);
