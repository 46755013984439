import Auth from "pages/authorization/Auth";
import LotsList from "pages/lots/List";
import LotsEdit from "pages/lots/Edit";
import LotsOptics from "pages/lots/Optics";
import TagsOptics from "pages/tags/Optics";
import MenuExperienceList from "pages/experiences/Menu/List";
import MenuExperienceEdit from "pages/experiences/Menu/Edit";
import SequenceExperienceList from "pages/experiences/Sequence/List";
import SequenceExperienceEdit from "pages/experiences/Sequence/Edit";
import Analytics from "pages/analytics/Analytics";
import Search from "pages/search/Search";
import RollsList from "pages/rolls/List";
import RollsEdit from "pages/rolls/Edit";
import Companies from "pages/companies/List";
import CompaniesEdit from "pages/companies/Edit";
import TemplatesList from "pages/templates/List";
import TemplatesEdit from "pages/templates/Edit";
import ProfileEdit from "pages/profile/Edit";

export const authRoutes = {
  path: ["/login", "/forgot-password", "/forgot-password/:token"],
  exact: true,
  component: Auth
};

export const routes = [
  {
    path: "/search",
    exact: true,
    component: Search,
    roles: ["super_admin", "company_admin"],
  },
  {
    path: "/analytics",
    exact: true,
    component: Analytics,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "analytics.read"
  },
  {
    path: "/rolls",
    exact: true,
    component: RollsList,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "rolls.read"
  },
  {
    path: "/rolls/edit/:rollId",
    exact: true,
    component: RollsEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "rolls.update"
  },
  {
    path: "/lots",
    exact: true,
    component: LotsList,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "lots.read"
  },
  {
    path: "/lots/create",
    exact: true,
    component: LotsEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "lots.create"
  },
  {
    path: "/lots/edit/:lotId",
    exact: true,
    component: LotsEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "lots.update"
  },
  {
    path: "/lots/optics/:lotId",
    exact: true,
    component: LotsOptics,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "lots.read"
  },
  {
    path: "/tags/optics/:tagId",
    exact: true,
    component: TagsOptics,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "lots.read"
  },
  {
    path: "/experiences/sequence",
    exact: true,
    component: SequenceExperienceList,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.sequence.read",
  },
  {
    path: "/experiences/sequence/create",
    exact: true,
    component: SequenceExperienceEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.sequence.create"
  },
  {
    path: "/experiences/sequence/edit/:id",
    exact: true,
    component: SequenceExperienceEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.sequence.update",
  },
  {
    path: "/experiences/menu",
    exact: true,
    component: MenuExperienceList,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.menu.read"
  },
  {
    path: "/experiences/menu/create",
    exact: true,
    component: MenuExperienceEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.menu.create"
  },
  {
    path: "/experiences/menu/edit/:id",
    exact: true,
    component: MenuExperienceEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "experiences.menu.update"
  },
  {
    path: "/templates",
    exact: true,
    component: TemplatesList,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "templates.read"
  },
  {
    path: "/templates/create",
    exact: true,
    component: TemplatesEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "templates.create"
  },
  {
    path: "/templates/edit/:id",
    exact: true,
    component: TemplatesEdit,
    roles: ["super_admin", "company_admin"],
    constraintGetter: "templates.update"
  },
  {
    path: "/companies",
    exact: true,
    component: Companies,
    roles: ["super_admin"]
  },
  {
    path: "/companies/edit/:id",
    exact: true,
    component: CompaniesEdit,
    roles: ["super_admin"]
  },
  {
    path: "/companies/create",
    exact: true,
    component: CompaniesEdit,
    roles: ["super_admin"]
  },
  {
    path: "/profile",
    exact: true,
    component: ProfileEdit,
    roles: ["super_admin", "company_admin"]
  }
];
