import { useState } from "react";

export default (orderOptions, paginationReset, onClientSort) => {
  const [order, setOrder] = useState({})

  const resetOrder = () => {
    setOrder({})
  }
  const onOrder = (sortField, value) => {
    if (orderOptions[sortField]) {
      setOrder({
        key: orderOptions[sortField],
        value: value.toUpperCase()
      })
      paginationReset()
      return
    }

    paginationReset()
    onClientSort(sortField, value)
  }

  return [order, onOrder, resetOrder];
};
