import React from "react";
import { Select as AntSelect } from "antd";
import { isEmpty } from "lodash";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledSelect, StyledOptionTitle } from "./Styled";
import { StyledInputWrapper } from "../common/Styled";
import Label from "../Label";
import ErrorMessage from "../../ErrorMessage";
import Icon from "../../Icon";
import Div from "../../Div";

const { Option } = AntSelect;

const Select = ({
  label = "",
  labelTextSize,
  themeStore,
  list = [],
  optionKey = "id",
  valueKey = "id",
  optionName = "name",
  selectedValue = "",
  defaultValue = "",
  showSearch = false,
  error = false,
  errorMessage = "",
  horizontalLabel = false,
  iconKey = "",
  required = false,
  look = "",
  lowercase = false,
  inputWidth = "auto",
  disabled = false,
  onChange = () => null,
  tooltip = null,
  filterOption = null
}) => (
  <StyledInputWrapper horizontalLabel={horizontalLabel}>
    <Div display="flex" align="center">
      {label && (
        <Label
          text={label}
          horizontalLabel={horizontalLabel}
          textSize={labelTextSize}
          required={required}
        />
      )}
      {tooltip && (
        <Div
          bottom={horizontalLabel ? 0 : 12}
          right={horizontalLabel ? 12 : 0}
          left={horizontalLabel ? -12 : 0}
        >
          {tooltip}
        </Div>
      )}
    </Div>
    <Div display="block" width={inputWidth}>
      <StyledSelect
        filterOption={filterOption}
        showSearch={showSearch}
        optionFilterProp="children"
        value={isEmpty(selectedValue) ? defaultValue : selectedValue}
        error={String(error)}
        look={look}
        theme={themeStore.theme}
        disabled={disabled}
        onChange={onChange}
      >
        {list.map((o, i) => (
          <Option key={`${o[optionKey]}-${i}`} value={o[valueKey]} name={o[optionName]}>
            {iconKey && <Icon margin="-4px 0 0 0" icon={o[iconKey]} />}
            <StyledOptionTitle iconKey={iconKey} lowercase={lowercase}>
              {o[optionName]}
            </StyledOptionTitle>
          </Option>
        ))}
      </StyledSelect>
      {error && <ErrorMessage text={errorMessage} />}
    </Div>
  </StyledInputWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Select);
