import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;

export const StyledTextAreaWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.secondaryLightColor};
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

export const StyledTextArea = styled(TextArea)`
  color: ${({ theme }) => theme.mainDarkColor} !important;

  &.ant-input {
    padding: 0 !important;
    border: none;
  }

  &.ant-input:focus {
    border: none !important;
    box-shadow: none !important;
  }
`;
