import React from "react";
import { Icon } from "antd";

import Div from "components/Div";
import Button from "components/actions/Button";
import { DownloadButton } from "./Styled";

const Actions = ({ asset, deleteAsset, isSuperAdmin }) => {
  const status = asset.status;
  const isUploaded = status === "done";
  const isUploading = status === "uploading";

  return (
    <Div justify="center">
      <DownloadButton
        download
        href={asset.file}
        target="_blank"
        disabled={!isUploaded || !asset.file}
      >
        <Icon
          type="download"
          style={{
            color: "#383838",
            fontSize: "19px",
            opacity: isUploaded ? "1" : "0.5"
          }}
        />
      </DownloadButton>
      <Button
        type="bin"
        onClick={deleteAsset}
        // Disable if uploading or if asset was added by super 
        // admin but current user isn't a super admin
        disabled={
          isUploading ||
          (asset.access === "super_admin" && !isSuperAdmin)
        }
      />
    </Div>
  );
};

export default Actions;
