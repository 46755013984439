import React from "react";
import { Popover } from "antd";

import { dateFormatter } from "utils/format";
import {Link} from "../../components/actions";

export const tagListTableColumns = [
  {
    title: "Tag ID",
    key: "tag",
    dataIndex: "id",
    render: (_, { id }) => (
      <Link type="tableLink" to={`/tags/optics/${id}`}>
        {id}
      </Link>
    )
  },
  {
    title: "Manufacturer ID",
    key: "manufacturerId",
    dataIndex: "manufacturerId"
  },
  {
    title: "Lot name",
    key: "lot",
    dataIndex: "lot.name",
    render: (_, { lot }) => {
      if (lot) {
        return (
          <Link type="tableLink" to={`/lots/edit/${lot.id}`}>
            {lot.name}
          </Link>
        )
      }
    }
  },
  {
    title: "Roll",
    key: "roll",
    dataIndex: "roll.id",
    render: (_, { roll }) => {
      if (roll) {
        const content = (
          <div>
            <div>Description: {roll.description || '-'}</div>
            <div>Tag manufacturer: {roll.tagManufacturer || '-'}</div>
            <div>Tag model: {roll.tagModel || '-'}</div>
          </div>
        );

        return (
          <Link type="tableLink" to={`/rolls/edit/${roll.id}`}>
            <Popover
              content={content}
              width="auto"
              offset={0}
              justify="center"
            >
              {roll.id}
            </Popover>
          </Link>
        )
      }
    }
  },
  {
    title: "Created",
    key: "created-at",
    dataIndex: "createdAt",
    align: "center",
    render: (_, row) => dateFormatter(row.createdAt),
    width: "24ch"
  }
];
