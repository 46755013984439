import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import whiteLabel, { defaultWhiteLabel } from "domain/services/white-label";
import P from "components/P";
import Div from "components/Div";
import Image from "components/Image";

const themeConfigMap = {
  white: {
    logoName: "whiteLogo",
    textColor: "#ffffff"
  },
  dark: {
    logoName: "blackLogo",
    textColor: "#6d6e71"
  },
  light: {
    logoName: "lightLogo",
    textColor: "#ffffffbf"
  }
};

const PoweredByLogo = ({
  rootStore: {
    themeStore: { theme }
  },
  textSize,
  lineHeight,
  logoWidth,
  logoHeight,
  logoTheme
}) => {
  const selectedTheme = themeConfigMap[logoTheme];

  return (
    <Div height={logoHeight} align="flex-end">
      {whiteLabel !== defaultWhiteLabel ? (
        <P
          size={textSize}
          lineHeight={`${lineHeight}px`}
          color={selectedTheme.textColor}
          weight="600"
        >
          Powered by
        </P>
      ) : null}
      <Image
        src={theme[selectedTheme.logoName]}
        width={`${logoWidth}px`}
        height={`${logoHeight}px`}
      />
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(PoweredByLogo);
