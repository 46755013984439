import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledInnerWrapper, StyledInnerTitle } from "./Styled";

const Inner = ({ title, flex = 1, themeStore, children }) => (
  <StyledInnerWrapper flex={flex}>
    {title && (
      <StyledInnerTitle theme={themeStore.theme}>{title}</StyledInnerTitle>
    )}
    {children}
  </StyledInnerWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Inner);
