import analytics from "assets/images/ic_analytics.svg";
import search from "assets/images/ic_search.svg";
import rolls from "assets/images/icrolls.svg";
import lots from "assets/images/ic_lot.svg";
import experience from "assets/images/ic_experience.svg";
import templates from "assets/images/ictemplates.svg";
import companies from "assets/images/iccompanies.svg";
import menu from "assets/images/ic_menu_purple.svg";
import sequence from "assets/images/ic_sequence_purple.svg";

export const sidebarMenu = [
  {
    key: "search",
    title: "Search",
    href: "/search",
    icon: search,
    roles: ["super_admin", "company_admin"]
  },
  {
    key: "analytics",
    title: "Analytics",
    href: "/analytics",
    icon: analytics,
    roles: ["super_admin", "company_admin"]
  },
  {
    key: "rolls",
    title: "Rolls",
    href: "/rolls",
    icon: rolls,
    roles: ["super_admin", "company_admin"]
  },
  {
    key: "lots",
    title: "Lots",
    href: "/lots",
    icon: lots,
    roles: ["super_admin", "company_admin"]
  },
  {
    key: "experiences",
    title: "Experiences",
    icon: experience,
    roles: ["super_admin", "company_admin"],
    children: [
      {
        key: "experiences-sequence",
        title: "Sequence",
        href: "/experiences/sequence",
        icon: sequence
      },
      {
        key: "experiences-menu",
        title: "Menu",
        href: "/experiences/menu",
        icon: menu
      }
    ]
  },
  {
    key: "templates",
    title: "Templates",
    href: "/templates",
    icon: templates,
    roles: ["super_admin", "company_admin"]
  },
  {
    key: "companies",
    title: "Companies",
    href: "/companies",
    icon: companies,
    roles: ["super_admin"]
  }
];

export const trigger = null;
export const siderWidth = 250;
export const theme = "light";
export const mode = "inline";
export const siderCollapsedWidth = 80;
