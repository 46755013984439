import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Form from "components/Form";
import P from "components/P";
import SelectCloudEndpoints from "../SelectCloudEndpoints";

const EditLotNotifications = ({ lot }) => {
  const onChange = keys => (lot.notifierIds = [...keys]);
  return (
    <Form direction="column" justify="space-between">
      <P bottom={10}>Lot agents</P>
      <SelectCloudEndpoints selectedIds={lot.notifierIds} onChange={onChange} />
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(EditLotNotifications);
