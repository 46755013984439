import React from "react";

import Div from "../Div";
import P from "../P";
import Icon from "../Icon";
import { Button } from "../actions";

const UploadedFile = ({ fileName, invert = false, src = "", onClick }) => (
  <Div align="center" justify="space-between">
    <Div align="center">
      {!src ? (
        <Icon icon="uploadedImage" width="26px" height="26px" />
      ) : (
        <img
          src={src}
          width="26px"
          height="26px"
          alt="icon"
          style={{ filter: invert ? "invert(20%)" : "" }}
        />
      )}
      <P left={10} right={10}>
        {fileName}
      </P>
    </Div>
    <Button type="bin" onClick={onClick} />
  </Div>
);

export default UploadedFile;
