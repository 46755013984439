import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Popover from "../Popover";
import { Button } from "../actions";
import Icon from "../Icon";
import { StyledTable } from "./Styled";
import { tableRowKey, actionsColumn } from "./settings";

const Table = ({
  themeStore,
  columns,
  data,
  loading,
  renderActions,
  paginationConfig = {},
  wrapActionsInPopover = true
}) => {
  const renderColumns = () => {
    if (!renderActions) return columns;

    return [
      ...columns,
      {
        ...actionsColumn,
        render: (_, row) =>
          wrapActionsInPopover ? (
            <Popover
              button={
                <Button type="tableAction">
                  <Icon icon="dots" width={32} height={8} />
                </Button>
              }
              placement="bottom"
            >
              {renderActions(row)}
            </Popover>
          ) : (
            renderActions(row)
          )
      }
    ];
  };

  return (
    <StyledTable
      rowKey={tableRowKey}
      columns={renderColumns()}
      dataSource={data}
      loading={loading}
      pagination={paginationConfig}
      theme={themeStore.theme}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(Table);
