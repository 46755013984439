import styled, { css } from "styled-components";

const lookList = {
  template: css`
    & p {
      margin: 0;
    }

    & img {
      display: none;
    }
  `,
};

export const StyledAccordionHeader = styled.div`
  display: flex;
  align-items: center;

  ${({ look }) => lookList[look]}
`;
