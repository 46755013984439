import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Button, Link } from "components/actions";
import Form from "components/Form";
import { authForm } from "./settings";
import {
  StyledInput,
  StyledPasswordPrefixIcon,
  StyledPostfixIcon,
  StyledWarningText
} from "./Styled";

const ForgotPasswordReset = ({ token, authStore, rootStore, themeStore }) => {
  useEffect(() => {
    authStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = e =>
    e.key === "Enter" && authStore.forgotPasswordReset(token);
  const onResetPassword = () => authStore.forgotPasswordReset(token);

  return (
    <Form direction="column" align="center" width="20%">
      <Div width="100%" bottom={60} top={20}>
        <StyledPasswordPrefixIcon
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        />
        <StyledInput
          name="password"
          type="password"
          value={authStore.password}
          placeholder={authForm.newPasswordPlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.password ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        >
          {authForm.newPasswordErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={60}>
        <StyledPasswordPrefixIcon
          isValid={!rootStore.validationErrors.confirmPassword}
          theme={themeStore.theme}
        />
        <StyledInput
          name="confirmPassword"
          type="password"
          value={authStore.confirmPassword}
          placeholder={authForm.confirmPasswordPlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.confirmPassword}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.confirmPassword ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.confirmPassword}
          theme={themeStore.theme}
        >
          {authForm.repeatPasswordErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={50}>
        <Button type="auth" onClick={onResetPassword}>
          Reset password
        </Button>
      </Div>
      <Div width="100%" justify="center" bottom={25}>
        <Link type="authLink" to="/forgot-password">
          Forgot password
        </Link>
      </Div>
      <Div width="100%" justify="center">
        <Link type="authLink" to="/login">
          Login
        </Link>
      </Div>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(ForgotPasswordReset);
