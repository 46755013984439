import styled from "styled-components";
import { CSSTransitionGroup } from "react-transition-group";

export const StyledCSSTransitionGroup = styled(CSSTransitionGroup)`
  .modal-animation-appear {
    opacity: 0.01;
  }

  .modal-animation-appear.modal-animation-appear-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
`;

export const StyledModalWrapper = styled.div`
  position: ${({ constraint }) => (constraint ? "absolute" : "fixed")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex || 1100};
`;

export const StyledModalInner = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width || "620px"};
  height: ${({ height }) => height || "auto"};
  max-height: calc(100vh - 50px);
  max-width: calc(100vw - 50px);
  padding: 15px 25px 25px 25px;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 8px #0000004d;
  border-radius: 9px !important;

  & > button[type="cross"] {
    top: 20px;
    right: 30px;
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.mainDarkColor};
`;

export const StyledModalBody = styled.div`
  display: flex;
  overflow: auto;
  margin-bottom: ${({ constraint }) => (constraint ? 0 : "30px")};
  justify-content: center;
  flex-grow: 1;
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;
