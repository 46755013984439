import React from "react";

import { StyledImg } from "./Styled";

const Image = ({
  src,
  width,
  height,
  position,
  margin,
  right,
  transform,
  cursor,
  onClick = () => null,
  styles
}) => (
  <StyledImg
    src={src}
    alt="#"
    width={width}
    height={height}
    position={position}
    margin={margin}
    right={right}
    transform={transform}
    cursor={cursor}
    onClick={onClick}
    styles={styles}
  />
);

export default Image;
