import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "job",
  "country"
];

class AuthUser extends DirtyState {
  @observable id = "";
  @observable firstName = "";
  @observable lastName = "";
  @observable email = "";
  @observable phone = "";
  @observable job = "";
  @observable country = "";
  @observable isFirstLogin = false;

  constructor(user, rootStore) {
    super(PATH_LIST_TO_OBSERVE, user);

    this.rootStore = rootStore;
    Object.assign(this, user);
  }

  @computed
  get createData() {
    return toJS({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      job: this.job,
      country: this.country
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      job: this.job,
      country: this.country
    });
  }

  @action.bound change(event) {
    this[event.target.name] = event.target.value;
  }

  @action.bound async toggleFirstLogin() {
    const { method, url } = this.rootStore.urls.users.update;
    await this.rootStore.makeRequest({
      method,
      url,
      body: { id: this.id, isFirstLogin: false }
    });
  }
}

export default AuthUser;
