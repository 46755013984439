import React, {useEffect} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import {Text, Select, ImageUpload} from "components/inputs";
import Icon from "components/Icon";
import Div from "components/Div";
import P from "components/P";
import Form from "components/Form";
import Popover from "components/Popover";
import { AUTHENTICATION_VALID_PAGE_LOGO, AUTHENTICATION_INVALID_PAGE_LOGO } from "../../../constants";
import { message } from "../../../utils/format";

const defaultPopoverInner = (
  <>
    <P bottom={10}>
      This choice determines what action is taken when a tag is considered counterfeit.
    </P>
    <P bottom={10}>
      <b>Use Lot Experience</b> - the user will follow the experience that is assigned to the Lot
    </P>
    <P>
      <b>Use Alternate URL</b> – If this option is selected, the user will be redirected to the URL
      specified  or get a default counterfeit page for the system if no URL is specified
    </P>
  </>
);

const getErrorMessage = (type, data) => {
  const fileNameMapping = {
    [AUTHENTICATION_VALID_PAGE_LOGO]: 'valid page logo',
    [AUTHENTICATION_INVALID_PAGE_LOGO]: 'invalid page logo',
  }

  const errorMessagesMap = {
    size: "The file size is too big",
    quantity: "The asset bundle has a maximum quantity of files allowed.",
    width: (data) => {
      return `The ${fileNameMapping[data.name]} width should not be more than ${data.dimension} pixels.`
    },
    height: (data) => {
      return `The ${fileNameMapping[data.name]} height should not be more than ${data.dimension} pixels.`
    }
  };

  if (typeof errorMessagesMap[type] === 'function') {
    return errorMessagesMap[type](data)
  } else {
    return errorMessagesMap[type]
  }
}

const EditLotCounterfeit = ({
  lot,
  assetBundlesStore,
  lotsStore,
  themeStore: { theme },
  rootStore: { validationErrors }
}) => {
  const {
    deleteAssetById,
    addAsset,
    rawAssetBundleAssets
  } = assetBundlesStore;
  const [assetBundle] = lot.assetBundles || []
  const assets = rawAssetBundleAssets['lot'] || []

  const onUpload = async (e, fileName) => {
    e.persist();
    const file = e.target;
    const fileMapping = {
      [AUTHENTICATION_VALID_PAGE_LOGO]: validLogoAsset,
      [AUTHENTICATION_INVALID_PAGE_LOGO]: invalidLogoAsset,
    }
    const dimensionMapping = {
      [AUTHENTICATION_VALID_PAGE_LOGO]: {
        width: 128,
        height: 28
      },
      [AUTHENTICATION_INVALID_PAGE_LOGO]: {
        width: 128,
        height: 28
      },
    }
    const asset = fileMapping[fileName]
    const dimension = dimensionMapping[fileName]

    if (file) {
      const resp = await addAsset({
        onBundleCreated: (relation, bundleId) => {
          lotsStore.setLot({...lot, assetBundles: [{id: bundleId}]})
        },
        target: file,
        name: fileName,
        bundleId: assetBundle && assetBundle.id,
        // dimension,
        relation: {
          companyId: lot.company.id,
          relationEntity: 'lot',
          relationId: lot.id,
        }
      })();
      if (!resp?.validationError && asset) {
        await deleteAssetById({id: asset.id})()
      }
    }
  };

  const onDelete = async (fileName) => {
    const asset = assets.find((file) => file.name === fileName)
    await deleteAssetById({id: asset.id, relationEntity: 'lot'})()
  };

  useEffect(() => {
    if (validationErrors.size) {
      message(getErrorMessage('size'), "error");
    } else if (validationErrors.quantity) {
      message(getErrorMessage('quantity'), "error");
    } else if (validationErrors.width) {
      message(getErrorMessage('width', validationErrors.width), "error");
    } else if (validationErrors.height) {
      message(getErrorMessage('height', validationErrors.height), "error");
    }
  }, [validationErrors]);


  const validLogoAsset = assets.find((item) => item.name === AUTHENTICATION_VALID_PAGE_LOGO)
  const invalidLogoAsset = assets.find((item) => item.name === AUTHENTICATION_INVALID_PAGE_LOGO)

  return (
    <Form>
      <Div width="100%" direction="column">
        <Div bottom={25}>
          <Select
            label="Lot anti-counterfeiting"
            list={[
              { name: "Enabled", value: "true", icon: "active" },
              { name: "Disabled", value: "false", icon: "inactive" }
            ]}
            inputWidth={"205px"}
            valueKey="value"
            iconKey="icon"
            selectedValue={String(lot.interceptCounterfeit)}
            onChange={v => {
              const isEnabled = v === "true"
              lot.interceptCounterfeit = isEnabled
              if (isEnabled) {
                lot.configCounterfeit.matchTagManufacturerID = true
              }
            }}
          />
        </Div>
        <Div direction="row" bottom={25}>
          <Div right={40}>
            <Select
              label={
                <>
                  <strong>Alerts</strong>
                  <br />
                  Receive alert of rejections?
                </>
              }
              list={[
                { name: "Email", value: "true" },
                { name: "Silent", value: "false" }
              ]}
              inputWidth={"205px"}
              valueKey="value"
              selectedValue={String(lot.configCounterfeit.notifyEmail)}
              disabled={!lot.interceptCounterfeit}
              onChange={v => (lot.configCounterfeit.notifyEmail = v === "true")}
            />
          </Div>
          <Div width="40ch" left={8}>
            <Text
              label={
                <>
                  <br />
                  Email address of recipient.
                </>
              }
              value={lot.configCounterfeit.notifyEmailAddress}
              placeholder="username@email.com"
              name="configCounterfeit.notifyEmailAddress"
              disabled={
                !lot.interceptCounterfeit || !lot.configCounterfeit.notifyEmail
              }
              error={validationErrors.email}
              errorMessage="Email not valid or not unique"
              onChange={lot.change}
              required
            />
          </Div>
        </Div>
        <Div direction="column" bottom={25}>
          <Div direction="row">
            <Div right={40}>
              <Select
                label={
                  <>
                    <strong>Authentication page</strong>
                    <br />
                    Page type
                  </>
                }
                list={[
                  { name: "Don't show page", value: "skip" },
                  { name: "Show default page", value: "default" },
                  { name: "Show custom page", value: "custom" }
                ]}
                inputWidth={"205px"}
                valueKey="value"
                selectedValue={lot.configCounterfeit?.authenticationPage || "skip"}
                disabled={!lot.interceptCounterfeit}
                onChange={v => (lot.configCounterfeit.authenticationPage = v)}
              />
            </Div>

            <Div direction="column" left={8}>
              <Div width="40ch" bottom={25}>
                <Text
                  maxLength={3}
                  disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}
                  label={<><br />Redirect counter (seconds)</>}
                  value={lot.configCounterfeit.authenticationPageRedirectCounter}
                  placeholder="3"
                  name="configCounterfeit.authenticationPageRedirectCounter"
                  onChange={lot.change}
                />
              </Div>
              <Div width="40ch" bottom={25}>
                <Text
                  disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}
                  label={<>Valid Tag Label</>}
                  placeholder="Authenticated"
                  value={lot.configCounterfeit.authenticationPageValidLabel}
                  name="configCounterfeit.authenticationPageValidLabel"
                  onChange={lot.change}
                />
              </Div>
              <Div direction="row" width="40ch" bottom={25}>
                <ImageUpload
                  disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}
                  label="Upload Valid Tag Graphic"
                  labelColor={theme.mainDarkColor}
                  darkIcon
                  fileName={validLogoAsset && 'Valid Tag Graphic'}
                  fileSrc={validLogoAsset?.file}
                  popoverInner={(
                    <>
                      <P bottom={10}>
                        Images must be .png or .svg
                      </P>
                    </>
                  )}
                  onUpload={(e) => {
                    onUpload(e, AUTHENTICATION_VALID_PAGE_LOGO)
                  }}
                  onRemove={(e) => {
                    onDelete(AUTHENTICATION_VALID_PAGE_LOGO)
                  }}
                />
              </Div>
              <Div width="40ch" bottom={25}>
                <Text
                  disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}
                  label={<>Invalid Tag Label</>}
                  placeholder="Not authenticated"
                  value={lot.configCounterfeit.authenticationPageInvalidLabel}
                  name="configCounterfeit.authenticationPageInvalidLabel"
                  onChange={lot.change}
                />
              </Div>
              <Div direction="row" width="40ch">
                <ImageUpload
                  disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}
                  label="Upload Invalid Tag Graphic"
                  labelColor={theme.mainDarkColor}
                  darkIcon
                  fileName={invalidLogoAsset && 'Invalid Tag Graphic'}
                  fileSrc={invalidLogoAsset?.file}
                  popoverInner={(
                    <>
                      <P bottom={10}>
                        Images must be .png or .svg
                      </P>
                    </>
                  )}
                  onUpload={(e) => {
                    onUpload(e, AUTHENTICATION_INVALID_PAGE_LOGO)
                  }}
                  onRemove={(e) => {
                    onDelete(AUTHENTICATION_INVALID_PAGE_LOGO)
                  }}
                />
              </Div>
            </Div>
          </Div>

          {/*<Div direction="row" bottom={25}>*/}
          {/*  <Div width="40ch" right={40}>*/}
          {/*    <Text*/}
          {/*      disabled={!lot.configCounterfeit?.authenticationPage || lot.configCounterfeit?.authenticationPage === 'skip'}*/}
          {/*      label={<>Valid Tag Label</>}*/}
          {/*      placeholder="Authenticated"*/}
          {/*      value={lot.configCounterfeit.authenticationPageValidLabel}*/}
          {/*      name="configCounterfeit.authenticationPageValidLabel"*/}
          {/*      onChange={lot.change}*/}
          {/*    />*/}
          {/*  </Div>*/}

          {/*  <Div width="40ch" left={8}>*/}
          {/*    <Text*/}
          {/*      disabled={!lot.configCounterfeit?.authenticationPage || lot.configCounterfeit?.authenticationPage === 'skip'}*/}
          {/*      label={<>Invalid Tag Label</>}*/}
          {/*      placeholder="Not authenticated"*/}
          {/*      value={lot.configCounterfeit.authenticationPageInvalidLabel}*/}
          {/*      name="configCounterfeit.authenticationPageInvalidLabel"*/}
          {/*      onChange={lot.change}*/}
          {/*    />*/}
          {/*  </Div>*/}
          {/*</Div>*/}

          {/*<>*/}
          {/*  <Div direction="row" width="45ch" bottom={25}>*/}
          {/*    <ImageUpload*/}
          {/*      disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}*/}
          {/*      label="Upload Valid Tag Graphic"*/}
          {/*      labelColor={theme.mainDarkColor}*/}
          {/*      darkIcon*/}
          {/*      fileName={validLogoAsset && 'Valid Tag Graphic'}*/}
          {/*      fileSrc={validLogoAsset?.file}*/}
          {/*      popoverInner={(*/}
          {/*        <>*/}
          {/*          <P bottom={10}>*/}
          {/*            Images must be .png or .svg*/}
          {/*          </P>*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*      onUpload={(e) => {*/}
          {/*        onUpload(e, AUTHENTICATION_VALID_PAGE_LOGO)*/}
          {/*      }}*/}
          {/*      onRemove={(e) => {*/}
          {/*        onDelete(AUTHENTICATION_VALID_PAGE_LOGO)*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Div>*/}
          {/*  <Div direction="row" width="45ch">*/}
          {/*    <ImageUpload*/}
          {/*      disabled={lot.configCounterfeit?.authenticationPage !== 'custom'}*/}
          {/*      label="Upload Invalid Tag Graphic"*/}
          {/*      labelColor={theme.mainDarkColor}*/}
          {/*      darkIcon*/}
          {/*      fileName={invalidLogoAsset && 'Invalid Tag Graphic'}*/}
          {/*      fileSrc={invalidLogoAsset?.file}*/}
          {/*      popoverInner={(*/}
          {/*        <>*/}
          {/*          <P bottom={10}>*/}
          {/*            Images must be .png or .svg*/}
          {/*          </P>*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*      onUpload={(e) => {*/}
          {/*        onUpload(e, AUTHENTICATION_INVALID_PAGE_LOGO)*/}
          {/*      }}*/}
          {/*      onRemove={(e) => {*/}
          {/*        onDelete(AUTHENTICATION_INVALID_PAGE_LOGO)*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Div>*/}
          {/*</>*/}
        </Div>

        <Div direction="row" bottom={25}>
          <Div right={40}>
            <Select
              label={
                <>
                  <strong>Counterfeit behavior</strong>
                  <br />
                  Alternate URL
                </>
              }
              tooltip={
                <Popover.InfoPopover placement="right">
                  {defaultPopoverInner}
                </Popover.InfoPopover>
              }
              list={[
                { name: "Use Alternate URL", value: "failure" },
                { name: "Use Lot Experience", value: "warning" }
              ]}
              inputWidth={"205px"}
              valueKey="value"
              selectedValue={lot.configCounterfeit?.behavior || "warning"}
              disabled={!lot.interceptCounterfeit}
              onChange={v => (lot.configCounterfeit.behavior = v)}
            />
          </Div>
          <Div width="40ch" left={8}>
            <Text
              label={
                <>
                  <br />
                  Alternate Content
                </>
              }
              value={lot.configCounterfeit.alternateUrl}
              placeholder="http://example-alternate-url.com"
              name="configCounterfeit.alternateUrl"
              disabled={
                !lot.interceptCounterfeit ||
                lot.configCounterfeit.behavior !== "failure"
              }
              onChange={lot.change}
            />
          </Div>
        </Div>


        <Div bottom={25}>
          <Div direction="column">
            <Div bottom={25}>
              <Div>
                <Select
                  label={
                    <>
                      <strong>Manufacturer Identifier Mismatch</strong>
                      <br />
                      Reject activations where manufacturer ID mismatches the tag
                      manufacturer's ID?
                    </>
                  }
                  list={[
                    { name: "Disabled", value: "false", icon: "inactive" },
                    { name: "Enabled", value: "true", icon: "active" }
                  ]}
                  inputWidth={"205px"}
                  valueKey="value"
                  iconKey="icon"
                  selectedValue={String(
                    lot.configCounterfeit.matchTagManufacturerID
                  )}
                  disabled={!lot.interceptCounterfeit}
                  onChange={v =>
                    (lot.configCounterfeit.matchTagManufacturerID = v === "true")
                  }
                />
              </Div>
            </Div>
            <Div bottom={25}>
              <Select
                label={
                  <>
                    <strong>Tag Counter Mismatch</strong>
                    <br />
                    Reject activations where tag counter (number of activations)
                    mismatches
                    <br />
                    the system's calculation of activation count.
                  </>
                }
                list={[
                  { name: "Disabled", value: "false", icon: "inactive" },
                  { name: "Enabled", value: "true", icon: "active" }
                ]}
                inputWidth={"205px"}
                valueKey="value"
                iconKey="icon"
                selectedValue={String(lot.configCounterfeit.matchTagCounter)}
                disabled={!lot.interceptCounterfeit}
                onChange={v =>
                  (lot.configCounterfeit.matchTagCounter = v === "true")
                }
              />
            </Div>
            <Div bottom={25}>
              <Select
                label={
                  <>
                    <strong>Tag Tampered Failed</strong>
                    <br />
                    Reject activations when tag is in a tampered state
                    <br />
                  </>
                }
                list={[
                  { name: "Disabled", value: "false", icon: "inactive" },
                  { name: "Enabled", value: "true", icon: "active" }
                ]}
                inputWidth={"205px"}
                valueKey="value"
                iconKey="icon"
                selectedValue={String(lot.configCounterfeit.matchTagTampered || false)}
                disabled={!lot.interceptCounterfeit}
                onChange={v =>
                  (lot.configCounterfeit.matchTagTampered = v === "true")
                }
              />
            </Div>
            <Div bottom={25}>
              <Select
                label={
                  <>
                    <strong>Tag Authorization Failed</strong>
                    <br />
                    Reject activations where tag authorization failed
                    <br />
                  </>
                }
                list={[
                  { name: "Disabled", value: "false", icon: "inactive" },
                  { name: "Enabled", value: "true", icon: "active" }
                ]}
                inputWidth={"205px"}
                valueKey="value"
                iconKey="icon"
                selectedValue={String(lot.configCounterfeit.matchTagAuthorization || false)}
                disabled={!lot.interceptCounterfeit}
                onChange={v =>
                  (lot.configCounterfeit.matchTagAuthorization = v === "true")
                }
              />
            </Div>

          </Div>
        </Div>
      </Div>
      <Div right={5} direction="column">
        <Div
          width="50ch"
          align="flex-start"
          styles={{
            padding: "15px",
            border: "1px solid",
            borderColor: theme.secondaryLightColor,
            borderRadius: theme.borderRadius,
            background: theme.mainLighterColor
          }}
        >
          <Icon width="24px" height="auto" icon="qrc" />
          <Div left={15} gap={10} direction="column">
            <P size={12}>
              <strong>Anti-counterfeiting employs native NFC features.</strong>
            </P>
            <P size={12}>
              NFC tags must be programmed with ASCII mirroring enabled for the{" "}
              <strong>manufacturer ID</strong> and <strong>counter</strong>.
            </P>
            <P size={12}>
              We recommend locking NFC tags as read-only for further protection.
            </P>
            <P size={12}>
              Finally, tag management must include the{" "}
              <strong>manufacturer's manifest</strong> when tags are ingested into
              DIRX2.
            </P>
          </Div>
        </Div>
      </Div>
    </Form>
  )
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    assetBundlesStore: rootStore.assetBundlesStore,
    lotsStore: rootStore.lotsStore,
    rootStore
  })),
  observer
)(EditLotCounterfeit);
