import styled from "styled-components";
import { Switch as AntdSwitch } from "antd";

const sizesMap = {
  large: {
    width: "64px",
    height: "30px",
    afterWidth: "25px",
    afterHeight: "25px",
  },
  small: {
    width: "36px",
    height: "18px",
    afterWidth: "14px",
    afterHeight: "14px",
  },
};

export const StyledSwitch = styled(AntdSwitch)`
  height: ${({ size }) => sizesMap[size].height}!important;
  width: ${({ size }) => sizesMap[size].width};
  background-color: ${({ checked, theme }) =>
    checked ? theme.accentColor : theme.secondaryLightColor} !important;

  &::after {
    width: ${({ size }) => sizesMap[size].afterWidth} !important;
    height: ${({ size }) => sizesMap[size].afterHeight} !important;
  }
`;
