import React from "react";

import { SearchWithSelect } from "components/inputs";
import Form from "components/Form";
import Div from "components/Div";

const Range = ({
  title,
  data = [],
  searchPopupOptions = [],
  onSearchSelect,
  onSearch
}) => (
  <Form
    width="100%"
    align="center"
    justify="space-between"
    paddingLeft={15}
    paddingRight={15}
    bottom={15}
  >
    <Div>{title}</Div>
    <SearchWithSelect
      onSearch={onSearch}
      data={data}
      dataKeys={searchPopupOptions.map(({ key }) => key)}
      resultsPopupOptions={searchPopupOptions}
      placeholder="Search by tag id"
      onSelect={onSearchSelect}
    />
  </Form>
);

export default Range;
