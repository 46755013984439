import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Header } from "components/Content";
import { Tabs, Tab } from "components/Tabs";
import { useTabs } from "components/hooks";
import LotDetailCard from "features/lots/LotDetailCard";
import ActivationDashboard from "features/analytics/ActivationDashboard";
import InterceptorDashboard from "features/analytics/InterceptorDashboard";

const Optics = ({
  rootStore: {
    activationsStore,
    lotsStore,
    diversionsStore,
    counterfeitsStore
  },
  match
}) => {
  const { lotId } = match.params;
  const { lot } = lotsStore;
  const [tab, setTab] = useTabs("#activations");
  
  useEffect(() => {
    const fetchLot = async () => await lotsStore.findById({ id: lotId })();

    fetchLot();
  }, [lotsStore, lotId]);

  return (
    <>
      <Header title="Lot Optics" backButton backButtonLink="/lots" />
      <Tabs activeKey={tab} onChange={setTab}>
        <Tab tab="Activations" key="#activations">
          <ActivationDashboard
            lot={lot}
            detailCard={<LotDetailCard lot={lot} />}
            store={activationsStore}
          />
        </Tab>
        <Tab tab="Diversions" key="#diversions">
          <InterceptorDashboard
            type="diversion"
            lot={lot}
            detailCard={<LotDetailCard lot={lot} />}
            interceptsStore={diversionsStore}
          />
        </Tab>
        <Tab tab="Counterfeits" key="#counterfeits">
          <InterceptorDashboard
            type="counterfeit"
            lot={lot}
            detailCard={<LotDetailCard lot={lot} />}
            interceptsStore={counterfeitsStore}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Optics);
