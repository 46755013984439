import styled from "styled-components";
import { Tag } from "antd";

export const StyledTag = styled(Tag)`
  margin-top: 8px !important;
  padding: 0 8px !important;
  background-color: ${({ bgcolor }) =>
    bgcolor || "rgba(255, 88, 88, 0.15)"} !important;
  border-color: ${({ bordercolor, theme }) =>
    bordercolor || theme.mainLightColor} !important;
  color: ${({ color, theme }) => color || theme.mainDarkColor} !important;
`;
