export const oceanColors = {
  gray: "#f6f5f3",
  blue: "#8cd5ff",
  white: "#ffffff"
};

export const tilesProviders = {
  CartoDB: {},
  HEREv3: { apiKey: process.env.REACT_APP_HERE_API_KEY }
};

export const templateContainer = {
  borderRadius: "7px",
  zIndex: 0,
  display: "flex",
  overflow: "hidden"
};

export const templateMapStyle = {
  fill: true,
  color: "#ffffff",
  weight: 0.5,
  fillColor: "#ffffff",
  fillOpacity: 0.6
};

export const templateMapData = {
  type: "FeatureCollection",
  crs: {
    type: "name",
    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" }
  },
  features: []
};
