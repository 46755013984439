import { useState } from "react";
import { inRange, sortBy, head, last } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

export default (data = [], strategy = "multiple") => {
  const [dataWithSelectedRows, setDataWithSelectedRows] = useState(data);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useDeepCompareEffect(() => {
    setDataWithSelectedRows(data);
  }, [data]);

  const mapDataAndResetSelected = (d) =>
    d.map((i) => ({
      ...i,
      selected: false,
      isFirstSelected: false,
      isLastSelected: false,
    }));

  const onRowSelect = (item, setIndex) => () => {
    if (setIndex) {
      const index = dataWithSelectedRows.findIndex(({ id }) => id === item.id);
      setSelectedIndex(index);
    }

    if (setIndex && item.selected) return;
    processSelected(item);
  };

  const hasSelected = () =>
    dataWithSelectedRows.some(({ selected }) => selected);

  const getSelectedIds = (options = {}) => {
    const { stringify } = options;

    const ids = dataWithSelectedRows
      .filter(({ selected }) => selected)
      .map(({ id }) => id);

    return stringify ? JSON.stringify(ids) : ids;
  };

  const getFirstAndLast = () => {
    const ids = getSelectedIds();
    return {
      first: dataWithSelectedRows.find((d) => d.id === head(ids)),
      last: dataWithSelectedRows.find((d) => d.id === last(ids)),
    };
  };

  const getSelectedItem = () => {
    return dataWithSelectedRows.find(({ selected }) => selected);
  };

  const setSelected = (ids = []) => {
    const items = data.map((i) =>
      ids.includes(i.id) ? { ...i, selected: true } : i
    );
    setDataWithSelectedRows(items);
  };

  const resetAllSelected = () => {
    const resetedData = mapDataAndResetSelected(dataWithSelectedRows);
    setDataWithSelectedRows(resetedData);
  };

  const resetSelectedIndex = () => setSelectedIndex(null);

  const processSelected = (item) => {
    let items = [];

    if (strategy === "multiple" || strategy === "single") {
      items = dataWithSelectedRows.map((i) => {
        if (i.id === item.id) return { ...i, selected: !i.selected };

        if (strategy === "single") {
          i.selected = false;
        }

        return i;
      });
    }

    if (strategy === "range") {
      const ids = getSelectedIds();

      if (ids.length === 0) {
        items = dataWithSelectedRows.map((i) =>
          i.id === item.id ? { ...i, selected: true, isFirstSelected: true } : i
        );
      }

      if (ids.length === 1) {
        const firstIndex = dataWithSelectedRows.findIndex(
          ({ id }) => id === ids[0]
        );
        const lastIndex = dataWithSelectedRows.findIndex(
          ({ id }) => id === item.id
        );
        const indexes = sortBy([firstIndex, lastIndex]);

        items = dataWithSelectedRows.map((i, index) => {
          const first = indexes[0];
          const last = indexes[1];

          return inRange(index, first, last + 1)
            ? {
                ...i,
                selected: true,
                isFirstSelected: index === first,
                isLastSelected: index === last,
              }
            : i;
        });
      }

      if (ids.length > 1) {
        items = mapDataAndResetSelected(dataWithSelectedRows);
      }
    }

    setDataWithSelectedRows(items);
  };

  return {
    dataWithSelectedRows,
    selectedIndex,
    onRowSelect,
    hasSelected,
    getSelectedIds,
    getFirstAndLast,
    getSelectedItem,
    setSelected,
    resetAllSelected,
    resetSelectedIndex,
  };
};
