import { observable, action, computed } from "mobx";
import { get } from "lodash";

import { message } from "utils/format";

class Constraints {
  @observable showActionModal = false;
  @observable rawConstraints = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    rootStore.routingStore.history.listen((_, action) => {
      if (action === "PUSH" && this.showActionModal) {
        this.showActionModal = false;
      }
    });
  }

  @computed
  get constraints() {
    return this.rawConstraints;
  }

  getConstraint(getter) {
    return get(this.rootStore.authStore.constraints, getter, false);
  }

  @action.bound async findAll() {
    const { method, url } = this.rootStore.urls.constraints.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
    });

    if (response) {
      this.rawConstraints = response.data;
    }

    return response.data;
  }

  @action setActionModalState = state => () => {
    this.showActionModal = state;
  };

  @action.bound canActivate({ getter, capacityGetter, target, amount }) {
    if (target === "page") {
      const isCreate = getter.includes("create");

      if (isCreate && capacityGetter) {
        const capacity = +this.getConstraint(capacityGetter);

        return capacity !== -1
          ? amount < capacity && this.getConstraint(getter)
          : true;
      }

      return this.getConstraint(getter);
    } else if (target === "action") {
      const canActivate = this.getConstraint(getter);

      if (!canActivate) {
        this.setActionModalState(true)();
      }

      return canActivate;
    } else {
      return false;
    }
  }

  @action onDismiss = () => {
    const urlSegments = window.location.pathname.split("/").filter(i => i);

    // todo: do it in a better way
    if (urlSegments[0] === "experiences") {
      this.rootStore.routingStore.push(`/${urlSegments[0]}/${urlSegments[1]}`);
    } else {
      this.rootStore.routingStore.push(`/${urlSegments[0]}`);
    }

    this.setActionModalState(false)();
  };

  @action onContactMe = async () => {
    const {
      company,
      firstName,
      lastName,
      email,
      phone,
    } = this.rootStore.authStore.user;

    const body = {
      companyName: company.name || "",
      companyContactName: company.contactName || "",
      companyContactEmail: company.contactEmail || "",
      companyContactPhone: company.contactPhone || "",
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      phone: phone || "",
    };

    const { method, url } = this.rootStore.urls.constraints.contactMe;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body,
    });

    if (response) {
      message("Your request were successfully sent.", "success");
    } else {
      message(
        "There was an error with your request. Please try again.",
        "error",
      );
    }

    return response;
  };
}

export default Constraints;
