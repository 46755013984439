import styled from "styled-components";
import { Radio } from "antd";

export const StyledRadio = styled(Radio)`
  width: 100%;
  display: flex !important;
  padding: 2px 0 !important;
  align-items: center !important;
  font-size: 18px !important;
  margin-right: 0 !important;

  .ant-radio-inner {
    width: 27px !important;
    height: 27px !important;
    border: 1px solid ${({ theme }) => theme.accentColor} !important;

    &::after {
      top: 7px !important;
      left: 7px !important;
      width: 11px !important;
      height: 11px !important;
      background-color: ${({ theme }) => theme.accentColor} !important;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    -webkit-box-shadow: 0 0 0 3px rgba(127, 221, 136, 0.08);
    box-shadow: 0 0 0 3px rgba(127, 221, 136, 0.08);
  }

  span.ant-radio + * {
    width: 100%;
    padding-right: 0 !important;
  }
`;
