import styled from "styled-components";

export const StyledThumbnailWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.mainLightColor};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const StyledThumbnail = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background: transparent url(${({ src }) => src}) 50% no-repeat;
  background-size: contain;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
