import React from "react";

import Div from "components/Div";
import P from "components/P";
import Tag from "components/Tag";
import Modal from "components/Modal";

const WithAffectedModal = ({
  isModalShown,
  onConfirm = () => null,
  onCancel = () => null,
  onCross = () => null,
  affectedItems = [],
  children,
}) => (
  <>
    <Modal
      showModal={isModalShown}
      onCancel={onCancel}
      onCross={onCross}
      onConfirm={onConfirm}
      confirmButtonTitle={"Yes, Add Tags"}
      width="720px"
    >
      <Div direction="column" width="90%" align="center">
        <P size={18} bottom={5}>
          The tags that you want to assign are already belong to another lot.
        </P>
        <P size={18} bottom={24}>
          Are you sure you want to move this tags?
        </P>
        <P size={18} bottom={8}>
          List of affected lots:
        </P>
        <P display="flex" flexWrap="wrap" justify="center" width="500px">
          {affectedItems.map(lot => (
            <Tag key={lot}>{lot}</Tag>
          ))}
        </P>
      </Div>
    </Modal>
    {children}
  </>
);

export default WithAffectedModal;
