import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Select } from "components/inputs";
import Form from "components/Form";

const AssignCompany = ({
  companiesStore: { companies, company },
  rootStore: { validationErrors },
  rollsStore: { roll },
  onCompanyChange
}) => (
  <Form width="60%">
    <Select
      name="company"
      list={companies}
      selectedValue={company.id}
      defaultValue={roll.company.name}
      error={validationErrors.company}
      errorMessage="Please, choose company to assign"
      showSearch
      onChange={onCompanyChange}
    />
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    companiesStore: rootStore.companiesStore,
    rootStore
  })),
  observer
)(AssignCompany);
