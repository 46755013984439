import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Select, Checkbox } from "components/inputs";
import P from "components/P";
import Form from "components/Form";
import InfoBox from "components/InfoBox";

const maxTagsQty = 100;

const Export = ({ rollsStore: { roll }, themeStore }) => (
  <Form width="70%">
    <Div direction="column" justify="space-between" width="100%">
      <InfoBox theme={themeStore.theme} bottom={16}>
        Generate and Export is quite a long process so it may take some time to
        be done. Please, don't close the page until export is finished.
      </InfoBox>
      {roll.tags > maxTagsQty && (
        <InfoBox theme={themeStore.theme} bottom={16}>
          Auto generation of QR Codes is unavailable. Current roll has{" "}
          {roll.tags} tags that exceeds the maximum of 100 tags allowed for auto
          generation. If you want to generate QR Codes for this roll, please
          contact the admin.
        </InfoBox>
      )}
      <Div direction="column" bottom={13} top={9}>
        <P size={16} weight="bold">
          Export Tags
        </P>
        <P bottom={12}>Tags data will be added to generated file</P>
        <Checkbox
          title="Include tags"
          large
          checked={roll.includeTags}
          onChange={roll.onChangeIncludeTags}
        />
      </Div>
      {roll.tags < maxTagsQty && (
        <>
          <Div direction="column" bottom={13}>
            <P size={16} weight="bold">
              Export QR Codes {roll.tags}
            </P>
            <P bottom={12}>QR codes will be added to generated file</P>
            <Checkbox
              title="Include QR codes"
              large
              checked={roll.includeQRCodes}
              onChange={roll.onIncludeQRCodes}
            />
          </Div>
          <Div direction="column" bottom={25}>
            <Select
              label="Select the type of QR code to include"
              look="underlined"
              list={[
                { id: 1, name: "Simple QR Codes", key: "simple" },
                { id: 2, name: "Branded QR Codes", key: "branded" }
              ]}
              valueKey="key"
              optionName="name"
              selectedValue={roll.qrCodeType}
              onChange={roll.onQRCodeTypeSelect}
            />
          </Div>
        </>
      )}
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rollsStore: rootStore.rollsStore
  })),
  observer
)(Export);
