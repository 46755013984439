import React, {useState} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Header, Inner } from "components/Content";
import { Button } from "components/actions";
import SearchForm from "components/forms/Search";

const AISearch = ({ tagsStore, authStore, themeStore }) => {
  const [search, setSearch] = useState('')
  const [searchResult, setSearchResult] = useState('')
  const {
    aiSearch,
  } = tagsStore;

  const onSearch = async () => {
    const resp = await aiSearch({ text: search })
    if (resp?.result) {
      setSearchResult(resp.result)
    }
  };

  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <SearchForm
          search={search}
          onSearch={({value}) => {
            setSearch(value)
          }}
          width="100%"
          placeholder="Type here"
          theme={themeStore.theme}
        />
        <Button style={{
          marginLeft: "24px",
          display: 'flex',
          alignItems: 'center'
        }} type="regular" onClick={() => {
          onSearch()
        }}>
          Search
        </Button>
      </div>
      {searchResult && (
        <Inner>
          <Header title="Result"/>
          <div
            style={{
              fontSize: '16px',
              marginTop: '24px'
            }}
            dangerouslySetInnerHTML={{__html: searchResult}}
          >
          </div>
        </Inner>
      )}
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore
  })),
  observer
)(AISearch);
