import { observable, action } from "mobx";

class CloudEndpoints {
  @observable cloudEndpoints = [];
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.cloudEndpoints = [];
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  @action.bound async findAll(params) {
    params = params || this.params;
    const { method, url } = this.rootStore.urls.cloudEndpoints.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params
    });
    if (response) this.cloudEndpoints = response.data;
    return response;
  }
}

export default CloudEndpoints;
