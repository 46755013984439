import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledTab } from "./Styled";

const Tab = ({ constraintGetter, constraintsStore, ...restProps }) => {
  const { canActivate } = constraintsStore;

  useEffect(() => {
    if (constraintGetter && restProps.active) {
      canActivate({ getter: constraintGetter, target: "action" });
    }
  });

  return <StyledTab {...restProps} />;
};

export default compose(
  inject(({ rootStore }) => ({
    constraintsStore: rootStore.constraintsStore,
  })),
  observer,
)(Tab);
