import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { Header, Inner } from "components/Content";
import Table from "components/Table";
import { Button, Link } from "components/actions";
import { compose } from "utils/hoc";
import { tableColumns } from "./settings";
import Modal from "components/Modal";
import { useModal } from "components/hooks";

const List = ({ companiesStore }) => {
  const {
    companies,
    company,
    findAll,
    reset,
    findById,
    deleteById,
    changeParams,
    params: { search }
  } = companiesStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel
  ] = useModal();

  const delayedQuery = useRef(debounce(findAll, 500)).current;

  useEffect(() => {
    findAll();
    return reset;
  }, [findAll, reset]);

  const onSearch = ({ key, value }) => {
    changeParams({ key, value });
    delayedQuery();
  };

  const renderActions = row => (
    <>
      <Link type="popoverRegular" to={`/companies/edit/${row.id}`}>
        Edit
      </Link>
      <Button
        type="popoverRedText"
        onClick={setDeleteModalShown(findById({ id: row.id }))}
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title="Delete company"
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel()}
        onConfirm={onDeleteModalOk(deleteById(company.id))}
      >
        {`Are you sure you want to delete ${company.name} company?`}
      </Modal>
      <Header
        title="All Companies"
        searchPlaceholder="Search by name"
        actionButtons={[
          <Link type="regular" key="create-btn" to="/companies/create">
            Create Company
          </Link>
        ]}
        withSearch
        searchDisabled={false} //todo: remake when backend send count of items
        search={search}
        onSearch={onSearch}
      />
      <Inner>
        <Table
          columns={tableColumns}
          data={companies}
          renderActions={renderActions}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    companiesStore: rootStore.companiesStore
  })),
  observer
)(List);
