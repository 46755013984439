import { useState } from "react";

export default rootStore => {
  if (!rootStore)
    throw new Error(
      "'useModalWithValidation' accepts the 'rootStore' as a required argument."
    );

  const [isShown, setIsShown] = useState(false);

  const callCb = async ({ cb, state, useResult = true }) => {
    let result;

    if (cb && useResult) {
      result = await cb();
    }

    if (cb && !useResult) {
      await cb();
    }

    if (useResult && result) {
      rootStore.resetValidationErrors();
      setIsShown(state);
    }

    if (!useResult) setIsShown(state);
  };

  const onSetIsShown = cb => () =>
    callCb({ cb, state: true, useResult: false });

  const onOkAction = cb => () => callCb({ cb, state: false });

  const onCancelAction = cb => async () => {
    rootStore.resetValidationErrors();
    await callCb({ cb, state: false, useResult: false });
  };

  return [isShown, onSetIsShown, onOkAction, onCancelAction];
};
