import { observable, action } from "mobx";

class Intercepts {
  @observable intercepts = [];
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.intercepts = [];
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  getPaginationParams(paginationConfig) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return paginationParams || {};
  }

  @action.bound onNextPage = params => paginationOptions =>
      this.findAll(params, paginationOptions);

  @action.bound async findAll(params, paginationOptions) {
    params = params || this.params;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const { method, url } = this.rootStore.urls.intercepts.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {...params, ...paginationParams}
    });

    if (response) {
      this.intercepts = response.data.rows;
      if (paginationOptions) {
        paginationOptions.setTotal(response.data.count);
      }
    }
    return response;
  }
}

export default Intercepts;
