import styled from "styled-components";

export const StyledSpin = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;

  .anticon {
    font-size: 48px;
    color: #ffffff;
  }
`;
