import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text } from "components/inputs";
import Form from "components/Form";

const EditProfile = ({
  authStore: { user },
  rootStore: { validationErrors }
}) => (
  <Form direction="row" width="100%">
    <Div direction="column" width="30%" right={30}>
      <Div bottom={25}>
        <Text
          label="First name"
          value={user.firstName}
          name="firstName"
          error={validationErrors.firstName}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Last name"
          value={user.lastName}
          name="lastName"
          error={validationErrors.lastName}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Job title"
          value={user.job}
          name="job"
          error={validationErrors.job}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
        />
      </Div>
    </Div>
    <Div direction="column" width="30%" right={25}>
      <Div bottom={25}>
        <Text
          label="Email"
          value={user.email}
          name="email"
          error={validationErrors.email}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Phone"
          value={user.phone}
          name="phone"
          error={validationErrors.phone}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
        />
      </Div>
      <Div>
        <Text
          label="Country"
          value={user.country}
          name="country"
          error={validationErrors.country}
          errorMessage="Must be more than 1 and less than 50 symbols"
          onChange={user.change}
        />
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(EditProfile);
