import addRoll from "assets/images/Illustration_1_full_roll.svg";
import moveFromLot from "assets/images/illustration_5_move.svg";
import addOneEach from "assets/images/Illustration_4_separatetags.svg";
import addRange from "assets/images/Illustration_3_range.svg";

export const IMAGES = {
  addRoll,
  moveFromLot,
  addOneEach,
  addRange
};
