import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { StyledThumbnailWrapper, StyledThumbnail } from "./Styled";

const Thumbnail = ({ src, width = 375, height = 810, themeStore }) => (
  <StyledThumbnailWrapper theme={themeStore.theme}>
    {src && <StyledThumbnail src={src} width={width} height={height} />}
  </StyledThumbnailWrapper>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(Thumbnail);
