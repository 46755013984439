import styled from "styled-components";

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainDarkColor};
  text-transform: uppercase;
`;

export const StyledSortingTitle = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.mainDarkColor};
  white-space: nowrap;
`;
