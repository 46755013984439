import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import P from "components/P";
import Div from "components/Div";
import Icon from "components/Icon";

const InnerContent = ({
  experience,
  hasExperience,
  defaultTitle,
  themeStore
}) => (
  <Div width="100%" align="center">
    {hasExperience && (
      <Icon margin="0 7px 0 0" icon={`${experience.type.name}Purple`} />
    )}
    <P weight="bold" size="16" color={themeStore.theme.accentColor}>
      {hasExperience ? experience.name : defaultTitle}
    </P>
    <Icon icon="previewIcon" width="22px" height="12px" margin="0 0 0 10px" />
  </Div>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(InnerContent);
