import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { Header } from "components/Content";
import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Div from "components/Div";
import { rollInfoOptions } from "./settings";
import Summary from "features/miscellany/Summary";
import AssetBundle from "features/miscellany/AssetBundle";
import Metadata from "features/miscellany/Metadata";
import EditRollForm from "features/rolls/forms/EditRoll";
import withCancelModal from "components/withCancelModal";

const Edit = ({ rollsStore, match, onLeavePage }) => {
  const { roll, save, findById } = rollsStore;
  const { rollId } = match.params;
  const onSave = () => save(rollId);

  useEffect(() => {
    const onMount = async () => {
      if (rollId) {
        await findById({ id: rollId })();
      }
    };

    onMount();

    return () => {
      rollsStore.reset();
    };
  }, [rollsStore, findById, rollId]);

  return (
    <>
      <Header
        title="Edit Roll"
        backButton
        backButtonFn={onLeavePage(roll.isDirty)}
        backButtonLink="/rolls"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button
              type="regularFlat"
              onClick={onLeavePage(roll.isDirty)}
            >
              Cancel
            </Button>
            <Button type="regular" onClick={onSave}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Div
        paddingTop="25px"
        paddingLeft="25px"
        paddingBottom="100px"
        direction="column"
        gap={25}
      >
        <Div direction="column">
          <Summary
            rows={rollInfoOptions.map(({ key, title }) => ({
              title,
              value: roll[key]
            }))}
          />
          <EditRollForm />
        </Div>
        <Div direction="column" gap={25}>
          <Div>
            <AssetBundle
              companyId={roll.company && roll.company.id}
              assetBundles={roll.assetBundles}
              relationEntity="roll"
              relationId={roll.id}
              parentText="roll"
            />
          </Div>
          <Metadata parentStore={roll} parentName="roll" />
        </Div>
      </Div>
    </>
  );
};

export default compose(
  withCancelModal("/rolls"),
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    rootStore
  })),
  observer
)(Edit);
