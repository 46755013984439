import React from "react";

import { Button } from "../actions";
import Icon from "../Icon";
import Div from "../Div";
import { Checkbox } from "../inputs";
import Popover from "../Popover";

const Filter = ({ options, onChange }) => (
  <Popover
    button={
      <Button>
        <Icon icon="filter" width={22} height={22} />
      </Button>
    }
    placement="bottom"
  >
    <Div direction="column">
      {options.map(({ title, key, isSelected, icon }, i) => (
        <Div key={key} bottom={options.length - 1 !== i ? 10 : 0}>
          <Checkbox
            name={key}
            title={title}
            checked={isSelected}
            onChange={onChange}
            icon={icon}
          />
        </Div>
      ))}
    </Div>
  </Popover>
);

export default Filter;
