import styled, { css } from "styled-components";
import { Select } from "antd";

const getLook = (theme) => ({
  underlined: css`
    .ant-select-selection {
      font-weight: bold !important;
      border: none !important;
      border-bottom: 1px solid ${theme.accentColor} !important;
      border-radius: 0 !important;
    }

    .ant-select-selection__rendered {
      margin: 0 !important;
    }

    .ant-select-arrow {
      right: 2px !important;
      color: #000 !important;
    }

    .ant-select-selection .ant-select-arrow i {
      font-size: 14px !important;
    }
  `,
});

export const StyledSelect = styled(Select)`
  width: 100% !important;
  text-transform: capitalize !important;

  .ant-select-selection {
    height: 44px !important;
    color: ${({ theme }) => theme.mainDarkColor} !important;
    border: 1px solid
      ${({ error, theme }) =>
        `${
          error === "true" ? theme.errorColor : theme.secondaryLightColor
        }`} !important;
    border-radius: ${({ theme }) => theme.borderRadius} !important;
  }

  .ant-select-selection__rendered {
    line-height: 44px !important;
  }

  ${({ look, theme }) => getLook(theme)[look]}
`;

export const StyledOptionTitle = styled.span`
  display: inline-block;
  margin-left: ${({ iconKey }) => (iconKey ? "15px" : 0)};
  text-transform: ${({ lowercase }) =>
    lowercase ? "lowercase" : "capitalize"};
`;
